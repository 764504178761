export const statisticConstants = {
    GET_TOTALS_BY_CAMPAIGN_REQUEST: 'STATS_GET_TOTALS_BY_CAMPAIGN_REQUEST',
    GET_TOTALS_BY_CAMPAIGN_SUCCESS: 'STATS_GET_TOTALS_BY_CAMPAIGN_SUCCESS',
    GET_TOTALS_BY_CAMPAIGN_FAILURE: 'STATS_GET_TOTALS_BY_CAMPAIGN_FAILURE',
    
    GET_TOTALS_BY_ADGROUP_REQUEST: 'STATS_GET_TOTALS_BY_ADGROUP_REQUEST',
    GET_TOTALS_BY_ADGROUP_SUCCESS: 'STATS_GET_TOTALS_BY_ADGROUP_SUCCESS',
    GET_TOTALS_BY_ADGROUP_FAILURE: 'STATS_GET_TOTALS_BY_ADGROUP_FAILURE',

    GET_TOTAL_SPEND_REQUEST: 'STATS_GET_TOTAL_SPEND_REQUEST',
    GET_TOTAL_SPEND_SUCCESS: 'STATS_GET_TOTAL_SPEND_SUCCESS',
    GET_TOTAL_SPEND_FAILURE: 'STATS_GET_TOTAL_SPEND_FAILURE',
    
    GET_DAILY_REQUEST: 'STATS_GET_DAILY_REQUEST',
    GET_DAILY_SUCCESS: 'STATS_GET_DAILY_SUCCESS',
    GET_DAILY_FAILURE: 'STATS_GET_DAILY_FAILURE',
    
    GET_LIFETIME_REQUEST: 'STATS_GET_LIFETIME_REQUEST',
    GET_LIFETIME_SUCCESS: 'STATS_GET_LIFETIME_SUCCESS',
    GET_LIFETIME_FAILURE: 'STATS_GET_LIFETIME_FAILURE',
    
    GET_MONTHLY_REQUEST: 'STATS_GET_MONTHLY_REQUEST',
    GET_MONTHLY_SUCCESS: 'STATS_GET_MONTHLY_SUCCESS',
    GET_MONTHLY_FAILURE: 'STATS_GET_MONTHLY_FAILURE',
    
    GET_CAMPAIGN_REQUEST: 'STATS_GET_CAMPAIGN_REQUEST',
    GET_CAMPAIGN_SUCCESS: 'STATS_GET_CAMPAIGN_SUCCESS',
    GET_CAMPAIGN_FAILURE: 'STATS_GET_CAMPAIGN_FAILURE',
    
    GET_ADGROUP_REQUEST: 'STATS_GET_ADGROUP_REQUEST',
    GET_ADGROUP_SUCCESS: 'STATS_GET_ADGROUP_SUCCESS',
    GET_ADGROUP_FAILURE: 'STATS_GET_ADGROUP_FAILURE',
    
    GET_CREATIVE_REQUEST: 'STATS_GET_CREATIVE_REQUEST',
    GET_CREATIVE_SUCCESS: 'STATS_GET_CREATIVE_SUCCESS',
    GET_CREATIVE_FAILURE: 'STATS_GET_CREATIVE_FAILURE',

    GET_ADS_REQUEST: 'STATS_GET_ADS_REQUEST',
    GET_ADS_SUCCESS: 'STATS_GET_ADS_SUCCESS',
    GET_ADS_FAILURE: 'STATS_GET_ADS_FAILURE',
    
    GET_TOP_DMA_BY_CTR_REQUEST: 'STATS_GET_TOP_DMA_BY_CTR_REQUEST',
    GET_TOP_DMA_BY_CTR_SUCCESS: 'STATS_GET_TOP_DMA_BY_CTR_SUCCESS',
    GET_TOP_DMA_BY_CTR_FAILURE: 'STATS_GET_TOP_DMA_BY_CTR_FAILURE',
    
    GET_TOP_COUNTRIES_BY_CTR_REQUEST: 'STATS_GET_TOP_COUNTRIES_BY_CTR_REQUEST',
    GET_TOP_COUNTRIES_BY_CTR_SUCCESS: 'STATS_GET_TOP_COUNTRIES_BY_CTR_SUCCESS',
    GET_TOP_COUNTRIES_BY_CTR_FAILURE: 'STATS_GET_TOP_COUNTRIES_BY_CTR_FAILURE',
    
    GET_TOP_STATES_BY_CTR_REQUEST: 'STATS_GET_TOP_STATES_BY_CTR_REQUEST',
    GET_TOP_STATES_BY_CTR_SUCCESS: 'STATS_GET_TOP_STATES_BY_CTR_SUCCESS',
    GET_TOP_STATES_BY_CTR_FAILURE: 'STATS_GET_TOP_STATES_BY_CTR_FAILURE',
    

    /**********************************
     * BREAKDOWNS
    **********************************/
    GET_COUNTRIES_REQUEST: 'STATS_GET_COUNTRIES_REQUEST',
    GET_COUNTRIES_SUCCESS: 'STATS_GET_COUNTRIES_SUCCESS',
    GET_COUNTRIES_FAILURE: 'STATS_GET_COUNTRIES_FAILURE',

    GET_ZONE_REQUEST: 'STATS_GET_ZONE_REQUEST',
    GET_ZONE_SUCCESS: 'STATS_GET_ZONE_SUCCESS',
    GET_ZONE_FAILURE: 'STATS_GET_ZONE_FAILURE',

    GET_DEVICE_REQUEST: 'STATS_GET_DEVICE_REQUEST',
    GET_DEVICE_SUCCESS: 'STATS_GET_DEVICE_SUCCESS',
    GET_DEVICE_FAILURE: 'STATS_GET_DEVICE_FAILURE',
    
    GET_OS_REQUEST: 'STATS_GET_OS_REQUEST',
    GET_OS_SUCCESS: 'STATS_GET_OS_SUCCESS',
    GET_OS_FAILURE: 'STATS_GET_OS_FAILURE',
    
    GET_STATE_REQUEST: 'STATS_GET_STATE_REQUEST',
    GET_STATE_SUCCESS: 'STATS_GET_STATE_SUCCESS',
    GET_STATE_FAILURE: 'STATS_GET_STATE_FAILURE',
    
    GET_DMA_REQUEST: 'STATS_GET_DMA_REQUEST',
    GET_DMA_SUCCESS: 'STATS_GET_DMA_SUCCESS',
    GET_DMA_FAILURE: 'STATS_GET_DMA_FAILURE',

    GET_ENVIRONMENT_REQUEST: 'STATS_GET_ENVIRONMENT_REQUEST',
    GET_ENVIRONMENT_SUCCESS: 'STATS_GET_ENVIRONMENT_SUCCESS',
    GET_ENVIRONMENT_FAILURE: 'STATS_GET_ENVIRONMENT_FAILURE',
    
    GET_MEDIASOURCE_REQUEST: 'STATS_GET_MEDIASOURCE_REQUEST',
    GET_MEDIASOURCE_SUCCESS: 'STATS_GET_MEDIASOURCE_SUCCESS',
    GET_MEDIASOURCE_FAILURE: 'STATS_GET_MEDIASOURCE_FAILURE',
    
};
