import React, {useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    // sideDrawerActions 
} from '../_actions';

//Moments JS
import Moment from 'react-moment';
import moment from 'moment';

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { users } from '../_reducers/users.reducer';

const LeftNavigation = (props) => {

    const { user, role } = props;

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    useEffect(() => {
        
    },[]);

    const isOnDashboard         = window.location.hash == "#/";
    const isOnCreativeLibrary   = window.location.hash.startsWith("#/creative-library");
    const isOnCampaign          = window.location.hash.startsWith("#/campaign");
    const isOnAdGroup           = window.location.hash.startsWith("#/adgroup");
    const isOnCreative          = window.location.hash.startsWith("#/creative");
    const isOnInventory         = window.location.hash.startsWith("#/publisher-management");
    const isOnPixels            = window.location.hash.startsWith("#/pixels");
    const isOnConversion            = window.location.hash.startsWith("#/conversion");
    const isOnMarketResearch    = window.location.hash.startsWith("#/market-research");
    const isOnUserManagement    = window.location.hash.startsWith("#/user-management");
    const isOnBillingSection    = window.location.hash.startsWith("#/billing");
    const isOnMarginControl     = window.location.hash.startsWith("#/margin-control");
    const isOnBrandManagement   = window.location.hash.startsWith("#/brand-management");

    return (
        <div className="left-navigation">
            <img className="logo" src="/public/images/logo.png" />
            {
                (role != "platform_admin" && role != "admin" && role != "DSP") &&
                <>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Main</b></Tooltip>}>
                        <Link to="/" className={`link-icons ${isOnDashboard ? "active" : ""}`} ><i className="fas fa-home"></i></Link>
                    </OverlayTrigger>
                    <hr/>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Market Research</b></Tooltip>}>
                        <Link to="/market-research" className={`link-icons ${user.hasIF ? "" : "disabled" } ${isOnMarketResearch ? "active" : ""}`}><i className="fa fa-flask"></i></Link>
                    </OverlayTrigger>
                    <hr/>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Campaigns</b></Tooltip>}>
                        <Link to="/campaign" className={`link-icons ${isOnCampaign ? "active" : ""}`} ><i className="fas fa-bullhorn"></i></Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Ad Groups</b></Tooltip>}>
                        <Link to="/adgroup" className={`link-icons ${isOnAdGroup ? "active" : ""}`} ><i className="fas fa-bullseye"></i></Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Creatives</b></Tooltip>}>
                        <Link to="/creative" className={`link-icons ${isOnCreative ? "active" : ""}`} ><i className="fas fa-ad"></i></Link>
                    </OverlayTrigger>
                    <hr/>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Creative Library</b></Tooltip>}>
                        <Link to="/creative-library" className={`link-icons creatives ${isOnCreativeLibrary ? "active" : ""}`} ><i className="fas fa-photo-video"></i></Link>
                    </OverlayTrigger>
                    {/* <OverlayTrigger placement="right" overlay={<Tooltip><b>Inventory Management</b></Tooltip>}>
                        <Link to="/publisher-management" className={`link-icons ${isOnInventory ? "active" : ""}`}><i className="fas fa-boxes"></i></Link>
                    </OverlayTrigger> */}
                    {/* <OverlayTrigger placement="right" overlay={<Tooltip><b>Conversion Definition and Audiences</b></Tooltip>}>
                        <Link to="/pixels" className={`link-icons ${isOnPixels ? "active" : ""}`}><i className="fas fa-file-code"></i></Link>
                    </OverlayTrigger> */}
                    {/* <OverlayTrigger placement="right" overlay={<Tooltip><b>Conversion</b></Tooltip>}>
                        <Link to="/conversion" className={`link-icons ${isOnConversion ? "active" : ""}`}><i className="fa fa-cube"></i></Link>
                    </OverlayTrigger> */}
                </>
            }
            {
                role == "platform_admin" &&
                <>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>User List</b></Tooltip>}>
                        <Link to="/platform-admin/user-management" className={`link-icons ${isOnUserManagement ? "active" : ""}`}><i className="fa fa-address-book"></i></Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Billing Section</b></Tooltip>}>
                        <Link to="/platform-admin/billing" className={`link-icons ${isOnBillingSection ? "active" : ""}`}><i className="fa fa-money"></i></Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Margin Control</b></Tooltip>}>
                        <Link to="/platform-admin/margin-control" className={`link-icons ${isOnMarginControl ? "active" : ""}`}><i className="fa fa-check-circle"></i></Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>IPC Pricing</b></Tooltip>}>
                        <Link to="/platform-admin/ipc" className={`link-icons ${isOnMarginControl ? "active" : ""}`}><i className="fa fa-diamond"></i></Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Brand Management</b></Tooltip>}>
                        <Link to="/brand-management" className={`link-icons ${isOnBrandManagement ? "active" : ""}`}><i className="fa fa-suitcase"></i></Link>
                    </OverlayTrigger>

                </>
            }
            {
                role == "admin" &&
                <>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>User List</b></Tooltip>}>
                        <Link to="/admin/user-management" className={`link-icons ${isOnUserManagement ? "active" : ""}`}><i className="fa fa-address-book"></i></Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Billing Section</b></Tooltip>}>
                        <Link to="/admin/billing" className={`link-icons ${isOnBillingSection ? "active" : ""}`}><i className="fa fa-money"></i></Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="right" overlay={<Tooltip><b>Margin Control</b></Tooltip>}>
                        <Link to="/admin/margin-control" className={`link-icons ${isOnMarginControl ? "active" : ""}`}><i className="fa fa-suitcase"></i></Link>
                    </OverlayTrigger>
                </>
            }
            <hr/>
            <OverlayTrigger placement="right" overlay={<Tooltip><b>Log Out</b></Tooltip>}>
                <Link to="/login" className={`link-icons ${isOnMarketResearch ? "active" : ""}`}><i className="fa fa-sign-out"></i></Link>
            </OverlayTrigger>
        </div>
    )
}

const mapState = state => ({
    user: state.users.current,
})

const actionCreators = {
}

const connectedLeftNavigation = connect(mapState, actionCreators)(LeftNavigation);

export { connectedLeftNavigation as LeftNavigation };