import config from 'config';
import { authHeader } from '../_helpers';

export const duplicatesService = {
    getDuplicatedCampaignsBySupernovaAccountId,
    addDuplicateReference,
};

function getDuplicatedCampaignsBySupernovaAccountId(supernovaAccountId, campaignId, adgroupId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/duplicates/${supernovaAccountId}`, requestOptions).then(handleResponse);
}

////////////////////////////////////
// account      - is the supernovaAccountId where this duplicated campaign belongs to
// campaignId   - is the campaignId of the duplicated Campaign
// originId     - is the campaignId of the source (where we copied this)
////////////////////////////////////
function addDuplicateReference(supernovaAccountId, campaignId, originId) {
    const requestOptions = {
        method: 'POST',
    };

    return fetch(`${config.apiURL}/duplicates/${supernovaAccountId}/${campaignId}/${originId}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

