import React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    campaignActions, measurementActions, publisherGroupActions,
    sideDrawerActions,
    statisticActions,
    brandsActions,
    brandRefSupernova,
    duplicatesActions,
    ipcActions
} from '../../_actions';
import { listCampaignGoal, listCampaignType, listIABCategory, listLanguage } from '../../_constants/data';
import { styleSelectBasic, styleSelectGoal, styleSelectMultiple } from '../../_constants/settings';

//React Select
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

//Daterange picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Moment
import moment from 'moment';

//Helpers
import '../../_helpers/sort-by.js';

//Animated
import { Animated } from "react-animated-css";

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//Toggle
import Toggle from 'react-toggle'
import '../../_stylesheets/css/react-toggle.css'
import { brands } from '../../_reducers/brands.reducer';

class CampaignForm extends React.Component {
    constructor(props) {
        super(props);

        const { duplicates, newCampaignType, campaigns, sideDrawer, publisherGroups, credits, user, measurement } = this.props
        this.state = {
            isSubmitAttempted:false,
            activeCampaignNavButton: 0,
            isNameFieldVisible: false,
            submitted: false,
            campaign: sideDrawer.purpose == "edit"
                ? campaigns.items && campaigns.items.filter(item => item.supernovaCampaignId == campaigns.selected).reduce((acc, cur) => cur)
                : {
                    name:"",
                    type:newCampaignType,
                    automation:"AI",
                    modified:false,
                    accountId:user.accountId,
                    supernovaAccountId:user.supernovaAccountId,
                    goals: [{
                        deleted:false,
                        conversionGoal:null,    //Default Goal when creating campaign is CTR 0.5%
                        modified:true,
                        primary:true,
                        symbol:"%",
                        type:"CTR",
                        value:"0.5"
                    }],
                    budgets: [
                        {
                            modified:true,
                            creditId: "6958",
                            amount: "",
                            comment: "-",
                            margin: "0",
                            startDate: moment().format('YYYY-MM-DD'),
                            endDate: null,
                        }
                    ],
                    targeting: {
                        publisherGroups: {
                            included: [],
                            excluded: []
                        }
                    }
                }
        };


        this.campaignRef = [];
        this.handleToggleEditName = this.handleToggleEditName.bind(this);
        this.handleCloseDrawer = this.handleCloseDrawer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.deleteCampaign = this.deleteCampaign.bind(this);
        this.handleChangeInputText = this.handleChangeInputText.bind(this);

        //Bind Goal handlers
        this.handleAddNewGoal = this.handleAddNewGoal.bind(this);
        this.handleRemoveGoal = this.handleRemoveGoal.bind(this);
        this.handleChangeGoalValue = this.handleChangeGoalValue.bind(this);

        //Bind Budget handlers
        this.handleAddNewBudget = this.handleAddNewBudget.bind(this);
        this.handleRemoveBudget = this.handleRemoveBudget.bind(this);
        this.handleChangeBudgetInput = this.handleChangeBudgetInput.bind(this);

        this.handleChangeConversionGoal = this.handleChangeConversionGoal.bind(this);

        this.saveNewBrand = this.saveNewBrand.bind(this);
    }

    componentDidMount() {
        let originId = this.getOriginId(this.state.campaign.id)
        this.props.getPublisherGroups(this.props.user.supernovaAccountId)
        this.state.campaign && this.props.getTotalSpend(this.state.campaign.id, this.props.user.supernovaAccountId);
        this.props.getConversionDefinitionBySupernovaAccountId(this.props.user.supernovaAccountId)
        this.props.getBrands()
        this.props.getBrandRefSupernovaBySupernovaAccountId(this.props.user.supernovaAccountId)
        this.props.getDuplicatedCampaignsBySupernovaAccountId(this.props.user.supernovaAccountId)
        this.state.campaign && this.props.getLifetimeByEntity(this.props.user.supernovaAccountId, "campaignId", this.getOriginId(this.state.campaign.id), this.props.newCampaignType)
        this.state.campaign && this.props.getLifetimeByEntity(this.props.user.supernovaAccountId, "campaignId", this.state.campaign.id, this.props.newCampaignType)
    }
    
    componentDidUpdate(prevProps, prevState) {
        
        if (this.props.duplicates !== prevProps.duplicates) {
            //Get the IPC for this campaign when duplicate is ready
            let originId = this.getOriginId(this.state.campaign.id)
            if(originId != null ){
                this.props.getIPCByCampaignId(this.props.user.accountId, originId)
            }
        }
    }

    /*
    |----------------------------------------------------------------------------------------------------------------------------------------
    | General handlers
    | - handleToggleEditName        - Toggles the Name field from input text to static text (for editing)
    | - handleChangeInputText       - Sets the campaign details initiated by input textbox
    | - handleDropdownChange        - Sets the campaign details initiated by dropdowns
    | - handleSubmit                - Handles the action (Create or Update) 
    | - handleFocusRef              - Scroll and focus on the desired section of the form
    |----------------------------------------------------------------------------------------------------------------------------------------
    */
    handleToggleEditName() {
        this.setState(prevState => ({
            isNameFieldVisible: !prevState.isNameFieldVisible
        }));
    }
    handleCloseDrawer() {
        this.props.closeDrawer()
    }
    handleChangeInputText(event) {
        let { name, value, type } = event.target;
        const { campaign } = this.state;
        if(event.target.type == "number" || event.target.getAttribute('data-type') == "number"){
            //Allow only numeric and dot
            let t = event.target.value.replace('-', '').replace(/[^\d.-]/g, '');

            // Allow only 2 decimal place
            value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t; //Old 
            // value = parseFloat(t).toFixed(2)
        }

        //Make sure true and false are not passed as string
        if(value == "true"){value = true}
        if(value == "false"){value = false}

        this.setState({
            campaign: {
                ...campaign,
                modified: true,
                [name]: value
            }
        });
    }
    handleDropdownChange(name, dropdown) {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown.value
        const { campaign } = this.state;
        this.setState({
            campaign: {
                ...campaign,
                modified:true,
                [name]: value
            }
        });
    }
    handleFocusRef(ref) {
        this.setState({ activeCampaignNavButton: ref });
        this.campaignRef[ref].scrollIntoView({ behavior: "smooth" });
    }
    saveNewBrand(newValue, actionMeta) {
        const { campaign } = this.state;

        //ADD NEW BRAND TO BRAND LIST
        if (actionMeta.action === 'create-option') {
            this.props.addBrand(newValue.label)
            this.setState({
                campaign: {
                    ...campaign,
                    ["brand"]:newValue.label,
                }
            });
        }

        //REMOVE SELECTED BRAND FROM DROPDOWN LIST
        if (actionMeta.action === 'clear') {
            this.setState({
                campaign: {
                    ...campaign,
                    ["brand"]:"",
                }
            });
        }

        //CHANGE SELECTED BRAND FROM THE DROPDOWN LIST
        if (actionMeta.action === 'select-option') {
            this.setState({
                campaign: {
                    ...campaign,
                    ["brand"]:newValue.label,
                }
            });
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        const { campaign } = this.state;
        let isValid = true;

        this.setState({
            submitted:true,
        })

        //Name is required
        if( campaign.name === undefined || campaign.name == ""){
            isValid = false;
        }

        //Type is required
        if( campaign.type === undefined){
            isValid = false;
        }
        
        //Category is required
        if( campaign.iabCategory === undefined){
            isValid = false;
        }
        
        //Language is required
        if( campaign.language === undefined){
            isValid = false;
        }

        //If GOALS is specified then value is required.
        if(campaign.goals.length > 0 && campaign.goals.filter(item => item.value == "" || item.value == null || item.value === undefined || item.type == "").length > 0){
            isValid = false;
        }
        
        //If BUDGET is specified then amount is required
        if(campaign.budgets.length > 0 && campaign.budgets.filter(item => item != null).filter(item => item.amount == "" || item.amount == null || item.amount === undefined || item.amount <= 0).length > 0){
            isValid = false;
        }

        //If BUDGET is specified then start date is required
        if(campaign.budgets.length > 0 && campaign.budgets.filter(item => item != null).filter(item => item.startDate == "" || item.startDate == null || item.startDate === undefined || !moment(item.startDate).isValid()).length > 0){
            isValid = false;
        }
        
        //If BUDGET is specified then end date is required
        if(campaign.budgets.length > 0 && campaign.budgets.filter(item => item != null).filter(item => item.endDate == "" || item.endDate == null || item.endDate === undefined || !moment(item.endDate).isValid()).length > 0){
            isValid = false;
        }

        //Budget end date cannot be earlier than start date
        if(campaign.budgets.length > 0 && campaign.budgets.filter(item => item != null).filter(item => moment(item.endDate) < moment(item.startDate)).length > 0){
            isValid = false;
        }

        //At least one goal must be defined
        if(campaign.goals.filter(item => item.deleted != true).length == 0){
            isValid = false;
        }
        
        //Frequency cap cannot less than 0
        if(campaign.frequencyCapping < 0){
            isValid = false;
        }

        //New Budget Item amount is greater than the Available Credit
        if(this.getTotalAvailableCredit() < 0){
            isValid = false;
        }

        //Continue submitting form if it's valid
        if(isValid){
            // console.log("2. isValid: " + isValid)
            if (campaign.id == undefined) {
                //Creating new Campaign
                this.props.createCampaign(campaign)
                this.props.closeDrawer()
            }
            else {
                //Editing an Campaign
                this.props.updateCampaign(campaign, this.props.user.supernovaAccountId)
                this.props.closeDrawer()
            }
            this.setState({
                submitted:false
            })
        }
    }

    deleteCampaign(e) {
        e.preventDefault();

    }


    /*
    |----------------------------------------------------------------------------------------------------------------------------------------
    | Goal Handlers
    | - handleAddNewGoal                        - Adds a new goal (Note: A campaign can have multiple goals)
    | - handleRemoveGoal(index)                 - Removes a specific goal by index
    | - handleChangeGoalKPI(index, dropdown)    - Sets "type" of a specific goal
    | - handleChangeGoalValue(index, dropdown)  - Sets "value" of a specific goal
    |----------------------------------------------------------------------------------------------------------------------------------------
    */
    handleAddNewGoal() {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                goals: [
                    ...prevState.campaign.goals,
                    {
                        symbol: "",
                        type: "",
                        primary: false,
                        value: "",
                        conversionGoal: null
                    }
                ]
            }
        }))
    }
    handleRemoveGoal(index) {
        let goals = [...this.state.campaign.goals];

        // Note:
        // IF a goal does NOT have an id (which means it hasn't been added yet) 
        // THEN remove it from the goals[]
        // ELSE mark it as {deleted:true} so it can be deleted in the database when form is submitted/saved
        if (goals[index].id === undefined) {
            goals.splice(index, 1);
            this.setState(prevState => ({
                campaign: {
                    ...prevState.campaign,
                    goals: goals
                }
            }))
        }
        else {
            let item = { ...goals[index] };
            item.deleted = true;
            goals[index] = item;
            this.setState(prevState => ({
                campaign: {
                    ...prevState.campaign,
                    goals: goals
                }
            }))
        }
    }
    handleChangeGoalKPI(index, dropdown) {
        let goals = [...this.state.campaign.goals];
        let item = { ...goals[index] };
        item.type = dropdown.value;
        item.modified = true;
        item.symbol = listCampaignGoal.filter(goal => goal != null && goal.value == item.type).map(goal => { return goal.symbol }).toString();
        goals[index] = item;
        if(item.type == "CTR"){
            goals[index].value = "0.5"
        }
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                goals: goals
            }
        }))
    }
    handleChangeGoalValue(index, e) {
        let goals = [...this.state.campaign.goals];
        let item = { ...goals[index] };
        let maxLength = 10;

        // Allow only 2 decimal place limit length
        let t = e.target.value;
        if(t.length > maxLength){
            t = t.slice(0, maxLength)
        }
        item.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
        
        item.modified = true;
        goals[index] = item;
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                goals: goals
            }
        }))
    }

    getOriginId(id){
        const recordFound = this.props.duplicates && this.props.duplicates.items && this.props.duplicates.items.filter(item => item.campaignId == id)
        let originId = null
        if(recordFound.length > 0){
            originId = recordFound[0].originId
        }
        return originId
    }

    isDuplicateCampaign(){
        //If there is an origin then it must be a duplicate campaign
        return this.getOriginId(this.state.campaign.id) == null ? false : true
    }

    /*
    |----------------------------------------------------------------------------------------------------------------------------------------
    | Targeting Handlers (Audience Targeting)
    | - handleTargetingChange                   - Sets the campaign details initiated by dropdowns
    |----------------------------------------------------------------------------------------------------------------------------------------
    */
    handleTargetingChange(name, dropdown) {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown.value
        const { campaign } = this.state;
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                modified:true,
                targeting: {
                    ...prevState.campaign.targeting,
                    publisherGroups: {
                        ...prevState.campaign.targeting.publisherGroups,
                        [name]: value
                    }
                }
            }
        }))
    }

    /*
    |----------------------------------------------------------------------------------------------------------------------------------------
    | Budget Handlers
    | - handleAddNewBudget  - Adds a new bude=get (Note: A campaign can have multiple budget)
    |----------------------------------------------------------------------------------------------------------------------------------------
    */
    handleAddNewBudget() {
        const { creditId } = this.props;
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                budgets: [
                    ...prevState.campaign.budgets.filter(item => item != null),
                    {
                        modified:true,
                        creditId: creditId,
                        amount: "",
                        comment: "-",
                        margin: "0",
                        startDate: moment().format('YYYY-MM-DD'),
                        endDate: null,
                    }
                ]
            }
        }))
    }
    handleRemoveBudget(index) {
        let budgets = [...this.state.campaign.budgets];

        // Note: (Similar to handleRemoveGoal (Checkout the comment on that handler)
        if (budgets[index].id === undefined) {
            budgets.splice(index, 1);
            this.setState(prevState => ({
                campaign: {
                    ...prevState.campaign,
                    budgets: budgets
                }
            }))
        }
        else {
            let item = { ...budgets[index] };
            item.deleted = true;
            budgets[index] = item;
            this.setState(prevState => ({
                campaign: {
                    ...prevState.campaign,
                    budgets: budgets
                }
            }))
        }
    }
    handleChangeBudgetInput(value, index, name) {
        let budgets = [...this.state.campaign.budgets];
        let item = { ...budgets[index] };
        item.modified = true

        // Allow only 2 decimal place for budget amount and margin
        if(name == "amount" || name == "margin"){
            let t = value;
            value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
        }

        if (name == "startDate" || name == "endDate") {
            value = moment(value).format('YYYY-MM-DD');
        }
        item[name] = value;
        budgets[index] = item;
        if(budgets[index].comment == ""){
            budgets[index].comment = "-";
        }
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                budgets: budgets
            }
        }))
    }

    printCampGoalPriority(index){
        switch(index){
            case 1: return "1st";          break;
            case 2: return "2nd";          break;
            case 3: return "3rd";          break;
            default: return index + "th";  break;
        }
    }

    getTotalSpend(){
        if(
            this.props.statistics && 
            this.props.statistics.isTotalSpendDone && 
            this.props.statistics.totalSpend
        ){
            let dataSpend = this.props.statistics.totalSpend.dataspend_with_margin
            let mediaSpend = this.props.statistics.totalSpend.mediaSpend

            //Convert to Float if its string
            dataSpend   = this.convertStringToFloat(dataSpend)
            mediaSpend  = this.convertStringToFloat(mediaSpend)

            return parseFloat(dataSpend + mediaSpend)
        }
        else{
            return 0
        }
    }

    convertStringToFloat(num){
        return typeof String(num) ? parseFloat(num.toString().replaceAll(',', '')) : num
    }

    convertStringToNumberWithComma(num){
        return num == "" || num == undefined ? "" : Number(num).toLocaleString()
    
    }

    getTotalAvailableCredit(){
        const {credits}  = this.props
        const {campaign}    = this.state

        let totalSpend = this.getTotalSpend();
        let totalAvailableCredit =  credits ? credits: 0
        let totalAllocatedBudget =  campaign.budgets.length > 0 ? 
                                        campaign.budgets.reduce(function(a, b){
                                            b = b?.amount || 0  //If new budget_item amount is still empty (User has clicked add new budget item but hasn't placed an amout yet, it reads as empty string "". So we make it zero (0) to avoid NaN
                                            a = typeof String(a) ? parseFloat(a.toString().replaceAll(',', '')) : a
                                            b = typeof String(b) ? parseFloat(b) : b
                                            return a + b;       //Sum all budgetitem amount
                                        }, 0)
                                    : 0

        //Convert to Float if its string
        totalSpend              = this.convertStringToFloat(totalSpend);
        totalAvailableCredit    = this.convertStringToFloat(totalAvailableCredit);

        

        //Return {User Credit} - {Total User Spend}
        return parseFloat(totalAvailableCredit - totalSpend - totalAllocatedBudget)
    }

    getUserType(){
        if(this.props.user.accountId){
            if(this.props.user.parentAccountId === undefined){                                                                      //IF there is no parentAccountId THEN it means the account is Supernova
                return "supernova";
            }
            else if(this.props.user.parentAccountId === "0000" || this.props.user.parentAccountId === 0 || this.props.user.parentAccountId === "4762"){              //IF the parentAccountId is 0000 (Which is Supernova's Account ID) THEN it means it is a direct client of supernova
                return "direct-client";
            }
            else{                                                                                                                   //ELSE THEN it means it's a sub-client of supernova
                return "sub-client"
            }
        }
        else{
            return null;
        }
    }

    handleChangeConversionGoal(index, dropdown){
        let goals = [...this.state.campaign.goals];
        let item = { ...goals[index] };
        let maxLength = 10;


        if(item.conversionGoal == null){
            item.conversionGoal = {
                conversionDefinitionId  : dropdown.value,
                conversionWindow        : null,
                goalId                  : "",
                name                    : dropdown.label,
                pixelUrl                : null,
                type                    : "PIXEL"
            }
        }
        else{
            item.conversionGoal.conversionDefinitionId = dropdown.value
            item.conversionGoal.name = dropdown.label
        }

        item.modified = true;
        goals[index] = item;
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                goals: goals
            }
        }))
    }

    getDefaultCheckForAutomation(purpose, option, campaign){

        console.log(campaign?.autopilot)

        if(purpose == "create"){
            //If it is for a new campaign set the supernova ai the default chosen option


            if(option == "AI"){
                return true
            }
            else{
                return false
            }
        }
        else{
            //Otherwise, set it based on autopilot

            if(campaign?.automation)
            {
                return campaign?.automation == option
            }
            else{
                switch(option){
                    case "EVEN_PACING"  : return campaign?.autopilot;
                    case "MANUAL"       : return !campaign?.autopilot
                }
            }
        }

        
        
        return 
    }

    render() {
        try{
            const { newCampaignType, campaigns, sideDrawer, publishergroups, credits, measurement, user } = this.props
            const { campaign, submitted, isNameFieldVisible } = this.state
            const originalGoalLength = ((campaigns.items.filter(i => i.id == campaign.id) || []).length > 0 && campaigns.items.filter(i => i.id == campaign.id)[0].goals) ? campaigns.items.filter(i => i.id == campaign.id)[0].goals.length : 1;
            if(campaign.goals == undefined){
                campaign.goals = []
            }
            if(campaign.budgets == undefined){
                campaign.budgets = []
            }
            const title = sideDrawer.purpose == "edit" ? "Edit Campaign" : `Create ${listCampaignType.find(item => item.value == newCampaignType).label} Campaign`
            const countDeletedGoals = sideDrawer.purpose == "edit" 
                ? campaign.goals.filter(goal => goal !== null && goal.deleted ).length 
                : 0
    
            //Match the correct labels on dropdowns based on value
            let campaignType        = sideDrawer.purpose == "edit" ? listCampaignType.filter(type => type.value == campaign.type).map(type => { return type.label }).toString() : ""
            let campaignCategory    = sideDrawer.purpose == "edit" ? listIABCategory.filter(iabCategory => iabCategory.value == campaign.iabCategory).map(iabCategory => { return iabCategory.label }).toString() : ""
            let campaignLanguage    = sideDrawer.purpose == "edit" ? listLanguage.filter(language => language.value == campaign.language).map(language => { return language.label }).toString() : ""
    
            //Sort dropdown selection items
    
            //Get Audience Targeting (Publisher List)
            let optionPublisherGroup = []
            let whiteListedPublisherGroups = []
            let blackListedPublisherGroups = []
            if (publishergroups.items !== undefined) {
                optionPublisherGroup = publishergroups.items.filter(publisher =>
                    !campaign.targeting.publisherGroups.included.includes(parseInt(publisher.id)) &&
                    !campaign.targeting.publisherGroups.excluded.includes(parseInt(publisher.id))
                ).map((item) => {
                    return {
                        label: item.name,
                        value: parseInt(item.id)
                    }
                }).sortBy('label');
                whiteListedPublisherGroups = campaign.targeting.publisherGroups.included.map(item => {
                    return {
                        label: publishergroups.items.filter(publisher => publisher.id == item).map(publisher => { return publisher.name }).toString(),
                        value: parseInt(item)
                    }
                }).sortBy('label')
                blackListedPublisherGroups = campaign.targeting.publisherGroups.excluded.map(item => {
                    return {
                        label: publishergroups.items.filter(publisher => publisher.id == item).map(publisher => { return publisher.name }).toString(),
                        value: parseInt(item)
                    }
                }).sortBy('label')
            }
    
            //Get Goals
            let usedGoals = this.state.campaign.goals.filter(item => item != null && item.type != "" && item.deleted != true).map(item => {return(item.type)})
            
            ////////////////////////////////////////////////////////////////
            // IPC Impression Post Report
            ////////////////////////////////////////////////////////////////
                    //1. Initialize variables needed
                    let parentCampaignId = parseInt(this.getOriginId(this.state.campaign.id))
                    // if(parentCampaignId)
                    let currentPaidCampaignECPM         = 0
                    let totalSpendOfOriginalCampaign    = 0
                    let cpmFromOriginalCampaign         = this.props?.statistics?.lifetime?.find(item => item?.id == parentCampaignId)?.bid || 0
                    let cpmFromIPC                      = this.props?.statistics?.lifetime?.find(item => item?._id == parseInt(this.state.campaign.id))?.ipc_bid_value || 0
                    let ipcEarnedImpressions            = parseInt(this.props?.ipc?.items?.find(item => item.campaignId == parentCampaignId)?.ipc_impression || 0)
                    let totalClicksOfOriginalCampaign   = parseInt(this.props?.statistics?.lifetime?.find(item => item?.id == parentCampaignId)?.clicks || 0)
                    let impressionFromOriginalCampaign  = parseInt(this.props?.statistics?.lifetime?.find(item => item?.id == parentCampaignId)?.impressions || 0)
                    let impressionFromIPC               = parseInt(this.props?.statistics?.lifetime?.find(item => item?._id == parseInt(this.state.campaign.id))?.impressions || 0)
        
                    //If there is no stats found in the lifetime return the following:
                    let IPCLifetimeStatistics = this.props?.statistics?.lifetime?.find(item => item?.id == this.state.campaign.id) ?
                        this.props?.statistics?.lifetime?.find(item => item?.id == this.state.campaign.id)
                    :
                        {
                            _id:this.state.campaign.id,
                            dataSpend: 0,
                        }
        
        
                    if(this.getUserType() == "supernova"){
                        currentPaidCampaignECPM         = this.props?.statistics?.lifetime?.find(item => item?.id == parentCampaignId)?.ecpm || 0
                        totalSpendOfOriginalCampaign    = this.props?.statistics?.lifetime?.find(item => item?.id == parentCampaignId)?.dataSpend  || 0
                    }
                    if(this.getUserType() == "direct-client"){
                        currentPaidCampaignECPM         = this.props?.statistics?.lifetime?.find(item => item?.id == parentCampaignId)?.total_ecpm_client || 0
                        totalSpendOfOriginalCampaign    = this.props?.statistics?.lifetime?.find(item => item?.id == parentCampaignId)?.total_spend_client || 0
                        console.log(currentPaidCampaignECPM)
                    }
                    if(this.getUserType() == "sub-client"){
                        currentPaidCampaignECPM         = this.props?.statistics?.lifetime?.find(item => item?.id == parentCampaignId)?.total_ecpm_subclient || 0
                        totalSpendOfOriginalCampaign    = this.props?.statistics?.lifetime?.find(item => item?.id == parentCampaignId)?.total_spend_subclient || 0
                    }
                    
                    //2. Get Paid Campaign eCPM with IPC
                    let currentPaidCampaignECPMwithIPC      = (totalSpendOfOriginalCampaign * 1000) / (impressionFromIPC + impressionFromOriginalCampaign)
                    let anticipatedPaidCampaignECPMwithIPC  = (totalSpendOfOriginalCampaign * 1000) / (ipcEarnedImpressions + impressionFromOriginalCampaign)
        
                    
                    //3. Get eCPM Cost Savings
                    let currentECPMCostSavings      = currentPaidCampaignECPM - currentPaidCampaignECPMwithIPC
                    // let anticipatedECPMCostSavings  = anticipatedPaidCampaignECPMwithIPC - anticipatedPaidCampaignECPMwithIPC (Deprecated Old Version)
                    let anticipatedECPMCostSavings  = currentPaidCampaignECPM - anticipatedPaidCampaignECPMwithIPC
                    
                    // console.log(`totalSpendOfOriginalCampaign: ${totalSpendOfOriginalCampaign}`)
                    // console.log(`ipcEarnedImpressions: ${ipcEarnedImpressions}`)
                    // console.log(`this.state.campaign.id: ${this.state.campaign.id}`)
                    // console.log(`impressionFromIPC: ${impressionFromIPC}`)
                    // console.log(`impressionFromOriginalCampaign: ${impressionFromOriginalCampaign}`)
                    // console.log(`anticipatedPaidCampaignECPMwithIPC: ${anticipatedPaidCampaignECPMwithIPC}`)
        
                    
                    //EPC PART:
        
                    //4. Get Paid Campaign eCPC
                    let currentPaidCampaignECPC                                 = totalSpendOfOriginalCampaign / totalClicksOfOriginalCampaign
                    let anticipatedPaidCampaignECPC                             = 0 // Based on the spreadsheet reference leave a blank for now
        
                    //5. Get Paid Campaign eCPC with IPC 
                    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                    // Note:    To calculate your spend based on impressions and CPM (Cost Per Mille), use the following formula:
                    //          Spend = (Impressions / 1000) x CPM
                    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                    let faceValueOfUpToDateTotalSpendOfIPCCampaign              = (impressionFromIPC / 1000) * cpmFromOriginalCampaign                  // Facevalue is the total spend based on the cpm of the paid campaign (remember: paid campaign is the origin campaign)
                    let faceValueOfUpToDateAnticipatedTotalSpendOfIPCCampaign   = (ipcEarnedImpressions / 1000) * cpmFromOriginalCampaign               // Anticipated is the same but instead of recalculating based on cpm of the paid campaign we use cpm From IPC Earned Impression
        
        
                    let currentPaidCampaignECPCWithIPC                          = (totalSpendOfOriginalCampaign - faceValueOfUpToDateTotalSpendOfIPCCampaign) / totalClicksOfOriginalCampaign
                    let anticipatedPaidCampaignECPCWithIPC                      = (totalSpendOfOriginalCampaign - faceValueOfUpToDateAnticipatedTotalSpendOfIPCCampaign) / totalClicksOfOriginalCampaign
        
                    //6. Get eCPC Cost Savings
                    // let currentECPCCostSavings                                  = currentPaidCampaignECPC - anticipatedPaidCampaignECPCWithIPC (Deprecated Old Version)
                    let currentECPCCostSavings                                  = currentPaidCampaignECPC - currentPaidCampaignECPCWithIPC
                    // let anticipatedECPCCostSavings                              = anticipatedPaidCampaignECPC - anticipatedPaidCampaignECPCWithIPC (Deprecated Old Version)
                    let anticipatedECPCCostSavings                              = currentPaidCampaignECPC - anticipatedPaidCampaignECPCWithIPC

            return (
                <>
                    <div className="title">
                        {title}
                        {
                            isNameFieldVisible || sideDrawer.purpose == "create"
                                ? <input type="text" autoFocus className={`form-control ${submitted && !campaign.name ? "has-error" : ""}`} placeholder="Enter Campaign Name" name="name" value={campaign.name} onBlur={this.handleToggleEditName} onChange={this.handleChangeInputText} />
                                : this.isDuplicateCampaign() == true 
                                    ? <div className="object-name" onClick={this.handleToggleEditName}>{campaign.name} <i className="fa fa-pencil" aria-hidden="true"></i></div>
                                    : <div className="object-name">{campaign.name}</div>
                        }
                    </div>
                    <div className="outline-wrapper">
                        <button className={`blue-link d-block ${this.state.activeCampaignNavButton == 0 ? "active" : ""}`} onClick={this.handleFocusRef.bind(this, 0)}>1. General</button>
                        <button className={`blue-link d-block ${this.state.activeCampaignNavButton == 3 ? "active" : ""}`} onClick={this.handleFocusRef.bind(this, 3)}>2. Budget Planning <span className={campaign.budgets == undefined || campaign.budgets.length == 0 ? "d-none" : ""}>{campaign.budgets && campaign.budgets.length}</span></button>
                        <button className={`blue-link d-block ${this.state.activeCampaignNavButton == 4 ? "active" : ""}`} onClick={this.handleFocusRef.bind(this, 4)}>3. Automation</button>
                        <button className={`blue-link d-block ${this.state.activeCampaignNavButton == 1 ? "active" : ""}`} onClick={this.handleFocusRef.bind(this, 1)}>4. Campaign goals <span className={campaign.goals == undefined || campaign.goals.length == 0 ? "d-none" : ""}>{campaign.goals && campaign.goals.filter(goal => goal != null && !goal.deleted).length}</span></button>
                        <button className={`blue-link d-block ${this.state.activeCampaignNavButton == 2 ? "active" : ""}`} onClick={this.handleFocusRef.bind(this, 2)}>5. White / Black List <span className={campaign.targeting.publisherGroups.included.length + campaign.targeting.publisherGroups.excluded.length == 0 ? "d-none" : ""}>{campaign.targeting.publisherGroups.included.length} | {campaign.targeting.publisherGroups.excluded.length}</span></button>
                        <div className="validation-info-wrapper">
    
                            {
                                /** Campain Name is required **/
                                submitted && 
                                (campaign.name === undefined || campaign.name == "") &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Name <br/>is required
                                    </div>
                                </Animated>
                            }
                            {
                                /** Campain Type is required **/
                                submitted && campaign.type === undefined &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Type <br/>is required
                                    </div>
                                </Animated>
                            }
                            {
                                /** Category Type is required **/
                                submitted && campaign.iabCategory === undefined &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Category <br/>is required
                                    </div>
                                </Animated>
                            }
                            {
                                /** Category Language is required **/
                                submitted && campaign.language === undefined &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Language <br/>is required
                                    </div>
                                </Animated>
                            }
                            {
                                /** Goal value is required **/
                                submitted && 
                                this.state.campaign.goals.length > 0 && this.state.campaign.goals.filter(item => item.value == "" || item.value == null || item.value === undefined || item.type == "").length > 0 &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Goal Value <br/>is required
                                    </div>
                                </Animated>
                            }
                            {
                                /** Budget amount is required **/
                                submitted && 
                                this.state.campaign.budgets.length > 0 && 
                                this.state.campaign.budgets.filter(item => item.amount == "" || item.amount == null || item.amount === undefined || item.amount <= 0).length > 0 &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Budget Amount <br/>is required
                                    </div>
                                </Animated>
                            }
                            {
                                /** Budget Start date is required **/
                                submitted && 
                                this.state.campaign.budgets.length > 0 && 
                                this.state.campaign.budgets.filter(item => item.startDate == "" || item.startDate == null || item.startDate === undefined || !moment(item.startDate).isValid()).length > 0 &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Budget Start Date <br/>is required
                                    </div>
                                </Animated>
                            }
                            {
                                /** Budget End date is required **/
                                submitted && 
                                this.state.campaign.budgets.length > 0 && 
                                this.state.campaign.budgets.filter(item => item.endDate == "" || item.endDate == null || item.endDate === undefined || !moment(item.endDate).isValid()).length > 0 &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Budget End Date <br/>is required
                                    </div>
                                </Animated>
                            }
                            {
                                /** Budget End date cannot be earlier than Start date **/
                                submitted && 
                                this.state.campaign.budgets.length > 0 && 
                                this.state.campaign.budgets.filter(item => moment(item.endDate) < moment(item.startDate)).length > 0 &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> End Date cannot be earlier than Start Date
                                    </div>
                                </Animated>
                            }
                            {
                                /** At least one goal must be defined **/
                                submitted && 
                                this.state.campaign.goals.filter(item => item.deleted != true).length == 0 &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Goal is required.
                                    </div>
                                </Animated>
                            }
                            {
                                //Frequency cap cannot less than 0
                                submitted && 
                                this.state.campaign.frequencyCapping < 0 &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Invalid Frequency Capping Value
                                    </div>
                                </Animated>
                            }
                            {
                                //New Budget Item amount is greater than the Available Credit
                                submitted && 
                                this.getTotalAvailableCredit() < 0 &&
                                <Animated animationIn="fadeInUp" isVisible={true}>
                                    <div className="validation-panel">
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Budget Exceeded
                                    </div>
                                </Animated>
                            }
                        </div>
                    </div>
                    <div className="form-wrapper">
                        <form name="form" onSubmit={this.handleSubmit}>
    
                            {/* SETTINGS */}
                            <section ref={(ref) => { this.campaignRef[0] = ref }}>
                                <div className="title">1. General</div>
                                <div className={'form-group d-none'}>
                                    <label htmlFor="type">Campaign Type</label>
                                    <div className="content">
                                        {
                                            sideDrawer.purpose == "create"
                                                ? <Select
                                                    className="select-primary"
                                                    onChange={this.handleDropdownChange.bind(this, "type")}
                                                    defaultValue={{ label: campaign.type, value: campaign.type }}
                                                    options={listCampaignType}
                                                    styles={styleSelectBasic} />
                                                : <input
                                                    type="text"
                                                    className="form-control"
                                                    disabled
                                                    value={campaignType} />
                                        }
                                    </div>
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="type">Brand Name</label>
                                    <div className="content">
                                        {
                                            this.props.brandRefSupernova && 
                                            this.props.brandRefSupernova.items && 
                                            this.props.brandRefSupernova.items.length > 0 &&
                                            this.props.brands && 
                                            this.props.brands.items ?
                                            <CreatableSelect
                                                defaultValue={
                                                    this.props.brandRefSupernova.items?.find(item => item.accountId == this.props.user.accountId && item.campaignId == this.state.campaign.id) ?
                                                    {
                                                        value:this.props.brandRefSupernova.items?.find(item => item.accountId == this.props.user.accountId && item.campaignId == this.state.campaign.id).brand,
                                                        label:this.props.brandRefSupernova.items?.find(item => item.accountId == this.props.user.accountId && item.campaignId == this.state.campaign.id).brand
                                                    }
                                                    : null
                                                }
                                                isDisabled={this.isDuplicateCampaign()}
                                                onChange={this.saveNewBrand}
                                                className="select-primary"
                                                isClearable={true}
                                                // onChange={this.handleDropdownChange.bind(this, "iabCategory")}
                                                // defaultValue={
                                                //     {
                                                //         label: campaignCategory,
                                                //         value: campaign.iabCategory,
                                                //     }
                                                // }
                                                options={
                                                        this.props.brands.loading != true &&
                                                            this.props.brands?.items?.filter(item => item.status == "approved")?.map(item => {return({
                                                                label:item.name, 
                                                                value:item.name
                                                            })}).sortBy('label')
                                                }
                                                styles={styleSelectBasic} />
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="type">Campaign Category</label>
                                    <div className="content">
                                        <Select
                                            className="select-primary"
                                            onChange={this.handleDropdownChange.bind(this, "iabCategory")}
                                            isDisabled={this.isDuplicateCampaign()}
                                            defaultValue={
                                                {
                                                    label: campaignCategory,
                                                    value: campaign.iabCategory,
                                                }
                                            }
                                            options={listIABCategory}
                                            styles={styleSelectBasic} />
                                    </div>
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor="type">Campaign Language</label>
                                    <div className="content">
                                        <Select
                                            className="select-primary"
                                            onChange={this.handleDropdownChange.bind(this, "language")}
                                            isDisabled={this.isDuplicateCampaign()}
                                            defaultValue={
                                                {
                                                    label: campaignLanguage,
                                                    value: campaign.language,
                                                }
                                            }
                                            options={listLanguage}
                                            styles={styleSelectBasic} />
                                    </div>
                                </div>
                            </section>
    
                                            

                            {
                                this.isDuplicateCampaign() == true ?
                                    <>
                                        {/* IPC IMPRESSIONS */}
                                        <section ref={(ref) => { this.campaignRef[3] = ref }}>
                                            <div className="title">2. Budget Planning</div>
                                                <div className="budget-overview">
                                                    <div className="section ipc-impression">
                                                        <div className="name">IPC Earned Impressions to Deliver</div>
                                                        <div className="value">
                                                            {
                                                                this.convertStringToNumberWithComma(ipcEarnedImpressions)
                                                            }
                                                        </div>
                                                        <div className="sub-value">
                                                            {
                                                                this.convertStringToNumberWithComma(impressionFromIPC)
                                                            }
                                                        </div>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Current</th>
                                                                    <th>Anticipated</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/***************************
                                                                 * A. eCPM Cost Savings
                                                                 ****************************/}
                                                                <tr>
                                                                    <td>eCPM Cost Savings</td>
                                                                    <td className="text-right">
                                                                        <span>$</span>
                                                                        <span>
                                                                            {
                                                                                (currentECPMCostSavings <= 0.001 || isNaN(currentECPMCostSavings))  ? '0.00' : Number(currentECPMCostSavings).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <span>$</span>
                                                                        <span>
                                                                            {
                                                                                (anticipatedECPMCostSavings <= 0.001 || isNaN(anticipatedECPMCostSavings))  ? '0.00' : Number(anticipatedECPMCostSavings).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    
                                                                </tr>
                                                                {/***************************
                                                                 * B. Paid Campaign eCPM
                                                                 ****************************/}
                                                                <tr>
                                                                    <td>Paid Campaign eCPM</td>
                                                                    <td className="text-right">
                                                                        <span>$</span>
                                                                        <span>
                                                                            {
                                                                                (currentPaidCampaignECPM <= 0.001 || isNaN(currentPaidCampaignECPM))  ? '0.00' : Number(currentPaidCampaignECPM).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-right">-</td>
                                                                </tr>
                                                                {/***************************
                                                                 * C. Paid Campaign eCPM with IPC
                                                                 ****************************/}
                                                                <tr>
                                                                    <td>Paid Campaign eCPM with IPC</td>
                                                                    <td className="text-right">
                                                                        <span>$</span>
                                                                        <span>
                                                                            {
                                                                                (currentPaidCampaignECPMwithIPC <= 0.001 || isNaN(currentPaidCampaignECPMwithIPC))  ? '0.00' : Number(currentPaidCampaignECPMwithIPC).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <span>$</span>
                                                                        <span>
                                                                            {
                                                                                (anticipatedPaidCampaignECPMwithIPC <= 0.001 || isNaN(anticipatedPaidCampaignECPMwithIPC))  ? '0.00' : Number(anticipatedPaidCampaignECPMwithIPC).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                            }
                                                                        </span>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="no-side-border"></td>
                                                                    <td className="no-side-border"></td>
                                                                    <td></td>
                                                                </tr>
                                                                {/***************************
                                                                 * A. eCPC Cost Savings
                                                                 ****************************/}
                                                                <tr>
                                                                    <td>eCPC Cost Savings</td>
                                                                    <td className="text-right">
                                                                        <span>$</span>
                                                                        <span>
                                                                            {
                                                                                (currentECPCCostSavings <= 0.001 || isNaN(currentECPCCostSavings))  ? '0.00' : Number(currentECPCCostSavings).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <span>$</span>
                                                                        <span>
                                                                            {
                                                                                (anticipatedECPCCostSavings <= 0.001 || isNaN(anticipatedECPCCostSavings))  ? '0.00' : Number(anticipatedECPCCostSavings).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                {/***************************
                                                                 * B. Paid Campaign eCPC
                                                                 ****************************/}
                                                                <tr>
                                                                    <td>Paid Campaign eCPC</td>
                                                                    <td className="text-right">
                                                                        <span>$</span>
                                                                        <span>
                                                                            {
                                                                                (currentPaidCampaignECPC <= 0.001 || isNaN(currentPaidCampaignECPC))  ? '0.00' : Number(currentPaidCampaignECPC).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        -
                                                                    </td>
                                                                </tr>
                                                                {/***************************
                                                                 * C. Paid Campaign eCPC with IPC
                                                                 ****************************/}
                                                                <tr>
                                                                    <td>Paid Campaign eCPC with IPC</td>
                                                                    <td className="text-right">
                                                                        <span>$</span>
                                                                        <span>
                                                                            {
                                                                                (currentPaidCampaignECPCWithIPC <= 0.001 || isNaN(currentPaidCampaignECPCWithIPC))  ? '0.00' : Number(currentPaidCampaignECPCWithIPC).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <span>$</span>
                                                                        <span>
                                                                            {
                                                                                (anticipatedPaidCampaignECPCWithIPC <= 0.001 || isNaN(anticipatedPaidCampaignECPCWithIPC))  ? '0.00' : Number(anticipatedPaidCampaignECPCWithIPC).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                        </section>
                                    </>
                                :
                                    <>
                                        {/* BUDGET */}
                                        <section ref={(ref) => { this.campaignRef[3] = ref }}>
                                        <div className="title">2. Budget Planning</div>
                                        <div className="budget-overview">
                                            <div className="section">
                                                <div className="name">Current Total Spend</div>
                                                <div className="value">
                                                    ${this.getTotalSpend().toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                </div>
                                            </div>
                                            <div className="section">
                                                <div className="name text-blue"><strong>Total Available Credit</strong></div>
                                                <div className={`value ${this.getTotalAvailableCredit() < 0 ? "red" : ""}`}>
                                                    ${
                                                        this.getTotalAvailableCredit() < 0 
                                                        ? "0.00" 
                                                        : `${this.getTotalAvailableCredit().toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <table className="active-budget">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th className='text-left'>Start date</th>
                                                    <th className='text-left'>End date</th>
                                                    <th className='text-left'>Total Budget</th>
                                                    <th className='text-right'>Total spend</th>
                                                    <th className='text-right'>Available budget</th>
                                                </tr>
                                            </thead>
                                            {
                                                campaign.budgets && campaign.budgets.length > 0 ?
                                                    <tbody>
                                                        {
                                                            campaign.budgets.filter(item => item != null).filter(item => item.state != "DEPLETED").slice(0, 1).map((budget, index) =>
                                                            <tr key={index}>
                                                                    <td>{
                                                                        budget.id 
                                                                            ? budget.id 
                                                                            :   <OverlayTrigger placement="top" content="" overlay={<Tooltip>Remove</Tooltip>}>
                                                                                    <i className={`fa fa-times-circle remove ${budget.id > 0 ? 'disabled' : ''}`} aria-hidden="true" onClick={(e) => this.handleRemoveBudget(index)}></i>
                                                                                </OverlayTrigger>
                                                                    }</td>
                                                                    {/* <td>{moment(budget.startDate).isValid() ? moment(budget.startDate).format('MMM DD, YYYY') : ""}</td> */}
                                                                    {/* <td>{moment(budget.endDate).isValid() ? moment(budget.endDate).format('MMM DD, YYYY') : ""}</td> */}
                                                                    {/* <td className="text-right">{budget.amount ? `$${parseFloat(budget.amount).toFixed(2)}` : "-"}</td> */}
                                                                    <td>
                                                                        <div className="content">
                                                                            {
                                                                                //Note: Only pending budgets can change start date and amount
                                                                                sideDrawer.purpose == "edit" && budget.state && budget.state != "PENDING"  ?
                                                                                    <OverlayTrigger placement="top" content="" overlay={<Tooltip>Only pending budgets can change start date</Tooltip>}>
                                                                                        <DatePicker 
                                                                                            // readOnly={true}
                                                                                            // className='disabled'
                                                                                            selected={moment(budget.startDate).isValid() ? new Date(budget.startDate + " 00:00") : ""} 
                                                                                            onChange={(date) => this.handleChangeBudgetInput(date, index, "startDate")}
                                                                                            minDate={Date.now()}
                                                                                        />
                                                                                    </OverlayTrigger>
                                                                                :
                                                                                <DatePicker 
                                                                                    className='disabled'
                                                                                    selected={moment(budget.startDate).isValid() ? new Date(budget.startDate + " 00:00") : ""} 
                                                                                    onChange={(date) => this.handleChangeBudgetInput(date, index, "startDate")}
                                                                                    minDate={Date.now()}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="content">
                                                                            <DatePicker 
                                                                                selected={moment(budget.endDate).isValid() ? new Date(budget.endDate + " 00:00") : ""} 
                                                                                onChange={(date) => this.handleChangeBudgetInput(date, index, "endDate")} 
                                                                                minDate={new Date(budget.startDate + " 00:00")}
                                                                                maxDate={new Date(user.creditEndDate)}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="content">
                                                                            {
                                                                                //Note: Only pending budgets can change start date and amount
                                                                                // sideDrawer.purpose == "edit" && budget.state && budget.state != "PENDING"  ?
                                                                                //     <OverlayTrigger placement="top" content="" overlay={<Tooltip>Only pending budgets can change amount</Tooltip>}>
                                                                                //         <input type="number" className="disabled form-control text-right" min="0" readOnly={true} value={budget.amount} onChange={(e) => this.handleChangeBudgetInput(e.target.value, index, "amount")} />
                                                                                //     </OverlayTrigger>
                                                                                //     :
                                                                                    <input type="number" className="form-control text-right" min="0" value={budget.amount} onChange={(e) => this.handleChangeBudgetInput(e.target.value, index, "amount")} />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-right'>{budget.spend ? `$${parseFloat(budget.spend).toFixed(2)}` : "-"}</td>
                                                                    <td className='text-right'>{budget.available ? `$${parseFloat(budget.available).toFixed(2)}` : "-"}</td>
                                                                    {/* <td>
                                                                        <i className="fa fa-book budget-comment-icon" aria-hidden="true"></i>
                                                                    </td> */}
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="9">
                                                                No budget items.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                            }
                                        </table>
                                        {
                                            campaign.budgets && campaign.budgets.length > 0 &&  
                                            <div className='d-none'>
                                                {
                                                        campaign.budgets.filter(item => item != null).filter(item => item.state != "DEPLETED").map((budget, index) =>
                                                        <div key={index} className="budget-item-wrapper">
                                                            <div className="controls">
                                                                <span className='budget-item-title'>
                                                                    {
                                                                        `${index + 1}`
                                                                    }
                                                                </span>
                                                                <i className={`fa fa-times-circle remove ${budget.id > 0 ? 'disabled' : ''}`} aria-hidden="true" onClick={(e) => this.handleRemoveBudget(index)}></i>
                                                            </div>
                                                            <div className="budget-item-left-fields">
                                                                <div className="input-section">
                                                                    <div className="form-group">
                                                                        <label htmlFor="name">Start Date</label>
                                                                        <div className="content">
                                                                            <DatePicker 
                                                                                selected={moment(budget.startDate).isValid() ? new Date(budget.startDate + " 00:00") : ""} 
                                                                                onChange={(date) => this.handleChangeBudgetInput(date, index, "startDate")}
                                                                                minDate={Date.now()}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="name">End Date</label>
                                                                        <div className="content">
                                                                            <DatePicker 
                                                                                selected={moment(budget.endDate).isValid() ? new Date(budget.endDate + " 00:00") : ""} 
                                                                                onChange={(date) => this.handleChangeBudgetInput(date, index, "endDate")} 
                                                                                minDate={new Date(budget.startDate + " 00:00")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="name">Total budget</label>
                                                                        <div className="content">
                                                                            <input type="text" data-type="number" className="form-control" min="0" value={budget.amount} onChange={(e) => this.handleChangeBudgetInput(e.target.value, index, "amount")} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group d-none">
                                                                        <label htmlFor="name">Margin %</label>
                                                                        <div className="content">
                                                                            <input type="number" className="form-control" min="0" value={budget.margin}  onChange={(e) => this.handleChangeBudgetInput(e.target.value, index, "margin")} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="budget-item-right-fields">
                                                                    <div className="form-group">
                                                                        <label htmlFor="name">Notes</label>
                                                                        <div className="content full">
                                                                            <textarea maxLength="250" type="text" className="form-control" value={budget.comment} onChange={(e) => this.handleChangeBudgetInput(e.target.value, index, "comment")}>
                                                                                {budget.comment}
                                                                            </textarea>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        }
                                        {
                                            // <button className={`btn btn-primary ${(credits === undefined || user.creditId == "null") ? "disabled" : ""}`} type="button" onClick={this.handleAddNewBudget}><i className="fa fa-plus-circle"></i> Add new budget item</button>
                                        }
                                    </section>
                                </>
                            }

                            
    
                            {/* DELIVERY OPTIMIZATION */}
                            <section ref={(ref) => { this.campaignRef[4] = ref }}>
                                <div className="title">3. Automation</div>
                                <div className={`radio full-width ${sideDrawer.purpose == "edit" ? "disabled": ""}`} onChange={this.handleChangeInputText}>
                                    <label>
                                        <input disabled={this.isDuplicateCampaign()} type="radio" value={"AI"} name="automation" defaultChecked={this.getDefaultCheckForAutomation(sideDrawer.purpose, "AI", campaign)} />
                                        <span className="radio-description campaign-auto-pilot d-flex automation-supernova-ai"><b>Supernova AI <img src="/public/images/supernova-icon-white-bg.gif" alt=""/></b> <i>User uses Supernova AI for adgroup creation and optimization.</i></span>
                                    </label>
                                    <label>
                                        <input disabled={this.isDuplicateCampaign()} type="radio" value={"EVEN_PACING"} name="automation" defaultChecked={this.getDefaultCheckForAutomation(sideDrawer.purpose, "EVEN_PACING", campaign)} />
                                        <span className="radio-description campaign-auto-pilot d-flex"><b>Even Pacing</b> <i>User creates adgroups manually, and ad group budget is distributed evenly and automatically.</i></span>
                                    </label>
                                    <label>
                                        <input disabled={this.isDuplicateCampaign()} type="radio" value={"MANUAL"} name="automation" defaultChecked={this.getDefaultCheckForAutomation(sideDrawer.purpose, "MANUAL", campaign)} />
                                        <span className="radio-description campaign-auto-pilot d-flex"><b>Manual</b> <i>User creates adgroups and specify adgroup budget manually.</i></span>
                                    </label>
                                </div>
                                <div className={'form-group'} style={{display:"none"}}>
                                    <label htmlFor="frequencyCapping">Daily impression frequency cap</label>
                                    <div className="content">
                                        <input disabled={this.isDuplicateCampaign()} type="text" className="form-control" placeholder='No Limit' data-type="number" name="frequencyCapping" value={campaign.frequencyCapping == null ? "" : campaign.frequencyCapping} onChange={this.handleChangeInputText} />
                                    </div>
                                </div>
                            </section>
    
                            {/* GOALS */}
                            <section ref={(ref) => { this.campaignRef[1] = ref }}>
                                <div className="title">4. Campaign goals</div>
                                {
                                    campaign.goals && campaign.goals.filter(goal => goal != null && !goal.deleted).length > 0
                                        ?
                                        <table className="goals-tbl">
                                            <tbody>
                                                {
                                                    campaign.goals.map((item, index) =>
                                                        <React.Fragment key={index}>
                                                            <tr className={`${item.type == "CPA" ? "cpa" : ""} ${item.deleted ? "d-none" : ""}`} key={index}>
                                                                <td>{this.printCampGoalPriority((index + 1) - countDeletedGoals)}</td>
                                                                <td>
                                                                    Achieve 
                                                                </td>
                                                                <td>
                                                                    <div className="input-label-symbol">
                                                                        {
                                                                            item.symbol
                                                                        }
                                                                    </div>
                                                                    <input disabled={this.isDuplicateCampaign()} type="number" min="0" required className={`form-control`} name="goalValue" value={item.value} onChange={(e) => this.handleChangeGoalValue(index, e)} />
                                                                </td>
                                                                <td>
                                                                    <Select
                                                                        className={`select-primary ${sideDrawer.purpose == "edit" && parseInt(index + 1) <= parseInt(originalGoalLength) ? "disabled" : ""}`}
                                                                        onChange={this.handleChangeGoalKPI.bind(this, index)}
                                                                        isDisabled={this.isDuplicateCampaign()} 
                                                                        defaultValue={{
                                                                            label: listCampaignGoal.filter(goalType => goalType.value == item.type).map(goalType => { return goalType.label }).toString(),
                                                                            value: item.type
                                                                        }}
                                                                        value={{
                                                                            label: listCampaignGoal.filter(goalType => goalType.value == item.type).map(goalType => { return goalType.label }).toString(),
                                                                            value: item.type
                                                                        }}
                                                                        options={
                                                                            // If there is not conversion definiton THEN do not include CPA
                                                                            measurement.conversion_definitions.length == 0 ?
                                                                                listCampaignGoal.filter(item => !usedGoals.includes(item.value) && item.usedFor.indexOf(campaign.type) >= 0 && item.value != "CPA")
                                                                            :
                                                                                listCampaignGoal.filter(item => !usedGoals.includes(item.value) && item.usedFor.indexOf(campaign.type) >= 0)
                                                                        }
                                                                        styles={styleSelectGoal} />
                                                                        {
                                                                            item.type == "CPA" && measurement && measurement.conversion_definitions && 
                                                                            <>
                                                                                <Select
                                                                                    className={`select-primary ${sideDrawer.purpose == "edit" && parseInt(index + 1) <= parseInt(originalGoalLength) ? "disabled" : ""}`}
                                                                                    onChange={this.handleChangeConversionGoal.bind(this, index)}
                                                                                    isDisabled={this.isDuplicateCampaign()} 
                                                                                    defaultValue={{
                                                                                        label: item.conversionGoal != null 
                                                                                            ? measurement.conversion_definitions.filter(conversion_definition => conversion_definition.id == item.conversionGoal.conversionDefinitionId).map(goalType => { return goalType.name }).toString()
                                                                                            : measurement.conversion_definitions.length > 0 ? measurement.conversion_definitions[0].name : "",
                                                                                        value: item.conversionGoal != null 
                                                                                            ? item.conversionGoal.conversionDefinitionId
                                                                                            : measurement.conversion_definitions.length > 0 ? measurement.conversion_definitions[0].id : ""
                                                                                    }}
                                                                                    value={{
                                                                                        label: item.conversionGoal != null
                                                                                            ? measurement.conversion_definitions.filter(conversion_definition => conversion_definition.id == item.conversionGoal.conversionDefinitionId).map(goalType => { return goalType.name }).toString()
                                                                                            : measurement.conversion_definitions.length > 0 ? measurement.conversion_definitions[0].name : "",
                                                                                        value: item.conversionGoal != null 
                                                                                            ? item.conversionGoal.conversionDefinitionId
                                                                                            : measurement.conversion_definitions.length > 0 ? measurement.conversion_definitions[0].id : ""
                                                                                    }}
                                                                                    options={measurement.conversion_definitions.map(conversion_definition => {return({
                                                                                        value:conversion_definition.id,
                                                                                        label:conversion_definition.name
                                                                                    })})}
                                                                                    styles={styleSelectGoal} />
                                                                            </>
                                                                        }
                                                                </td>
                                                                <td>
                                                                    <button disabled={this.isDuplicateCampaign()} className={`btn btn-primary remove`} type="button" onClick={(e) => this.handleRemoveGoal(index)}><i className="fa fa-times-circle"></i> Remove</button>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
    
                                                }
                                            </tbody>
                                        </table>
    
                                        : <Alert key="alert-campaign-goal" variant="warning"><i className="fa fa-exclamation-triangle"></i> Currently there are no defined campaign goals.</Alert>
    
                                }
                                <button disabled={this.isDuplicateCampaign()} className={`btn btn-primary ${this.state.campaign.goals.filter(item => item.deleted != true).length >= listCampaignGoal.filter(item => item.usedFor.includes(campaign.type)).length ? "disabled" : ""}`} type="button" onClick={this.handleAddNewGoal}><i className="fa fa-plus-circle"></i> Add new goal</button>
                            </section>
    
                            {/* AUDIENCE TARGETING */}
                            <section ref={(ref) => { this.campaignRef[2] = ref }}>
                                <div className="title">5. White / Black List</div>
                                <div className="description">
                                    <span className="w-100 d-block sub-desc">Targeting by whitelisting or blacklisting publishers.</span>
                                    <div className="half-list left">
                                        <div className="title">Whitelist</div>
                                        <Select
                                            isDisabled={this.isDuplicateCampaign()} 
                                            value={whiteListedPublisherGroups}
                                            className="select-primary multi"
                                            menuPlacement="top"
                                            onChange={this.handleTargetingChange.bind(this, "included")}
                                            isMulti
                                            options={optionPublisherGroup}
                                            isClearable={false}
                                            styles={styleSelectMultiple} />
                                    </div>
                                    <div className="half-list right">
                                        <div className="title">Blacklist</div>
                                        <Select
                                            isDisabled={this.isDuplicateCampaign()} 
                                            value={blackListedPublisherGroups}
                                            className="select-primary multi"
                                            menuPlacement="top"
                                            onChange={this.handleTargetingChange.bind(this, "excluded")}
                                            isMulti
                                            options={optionPublisherGroup}
                                            isClearable={false}
                                            styles={styleSelectMultiple} />
                                    </div>
                                </div>
                            </section>
                            
                            {/* IPC */}
                            {/* <section ref={(ref) => { this.campaignRef[5] = ref }}>
                                <div className="title">6. IPC</div>
                                <div className="description">
                                    <span className="w-100 d-block sub-desc">

                                    </span>
                                    <div className="half-list left">
                                        first enable IPC
                                    </div>
                                    <div className="half-list right">
                                        <Toggle className={``}  defaultChecked={false}/> 
                                    </div>
                                </div>
                            </section> */}
    
                            {/* SUBMIT OR CANCEL */}
                            <div className="footer">
                                <button className={`btn btn-primary`} type="submit" onClick={this.handleSubmit}><i className="fa fa-check" aria-hidden="true"></i> {sideDrawer.purpose == "create" ? 'Create' : 'Save'}</button>
                                <button className="btn btn-secondary" onClick={this.handleCloseDrawer}>Cancel</button>
                                {/* <button className='btn btn-primary btn-xs btn-delete' onClick={this.deleteCampaign}>Delete Campaign</button> */}
                            </div>
                        </form>
                    </div>
                </>
            );
        }catch(e){
            console.log(e)
            // window.location.reload(false);
        }

        
    }
}

const mapState = state => ({
    statistics      : state.statistics,
    user            : state.users.current,
    sideDrawer      : state.sideDrawer,
    campaigns       : state.campaigns,
    publishergroups : state.publishergroups,
    credits         : state.users.current.credits,
    creditId        : state.users.current.creditId,
    measurement     : state.measurement,
    brands          : state.brands,
    brandRefSupernova   : state.brandRefSupernova,
    duplicates          : state.duplicates,
    ipc                 : state.ipc,
})

const actionCreators = {
    createCampaign                      : campaignActions.createCampaign,
    updateCampaign                      : campaignActions.updateCampaign,
    getPublisherGroups                  : publisherGroupActions.getPublisherGroups,
    closeDrawer                         : sideDrawerActions.close,
    getTotalSpend                       : statisticActions.getTotalSpend,
    getLifetimeByEntity                 : statisticActions.getLifetimeByEntity,
    getConversionDefinitionBySupernovaAccountId  : measurementActions.getConversionDefinitionBySupernovaAccountId,
    addBrand                            : brandsActions.addBrand,
    getBrands                           : brandsActions.getBrands,
    getBrandRefSupernovaBySupernovaAccountId                : brandRefSupernova.getBrandRefSupernovaBySupernovaAccountId,
    getDuplicatedCampaignsBySupernovaAccountId   : duplicatesActions.getDuplicatedCampaignsBySupernovaAccountId,
    getIPCByCampaignId                  : ipcActions.getIPCByCampaignId,

}


const connectedCampaignForm = connect(mapState, actionCreators)(CampaignForm);
export { connectedCampaignForm as CampaignForm };