import { statisticConstants } from '../_constants';
import { statisticService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const statisticActions = {
    getDaily,
    getDailyByEntity,
    getLifetimeByEntity,
    getStatsPerCampaign,
    getStatsPerAdgroup,
    getStatsPerCreative,
    getStatsPerAds,
    getTopPerformingDMAByCTR,
    getTopPerformingCountriesByCTR,
    getTopPerformingStatesByCTR,

    //Group By
    getStatsByCountry,
    getStatsByZone,
    getStatsByDevice,
    getStatsByOS,
    getStatsByState,
    getStatsByDMA,
    getStatsByEnvironment,
    getStatsByMediaSources,

    //Get Total Spend
    getTotalSpend,

    //Get Total Impressions and Clicks of a Campaign / Adgroup 
    getTotalByCampaign,
    getTotalByAdgroup
};

function getTotalByCampaign(campaignId) {
    return dispatch => {
        dispatch(request());

        statisticService.getTotalByCampaign(campaignId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_TOTALS_BY_CAMPAIGN_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_TOTALS_BY_CAMPAIGN_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_TOTALS_BY_CAMPAIGN_FAILURE, error } }
}

function getTotalByAdgroup(campaignId, adgroupId) {
    return dispatch => {
        dispatch(request());

        statisticService.getTotalByAdgroup(campaignId, adgroupId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_TOTALS_BY_ADGROUP_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_TOTALS_BY_ADGROUP_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_TOTALS_BY_ADGROUP_FAILURE, error } }
}

function getTotalSpend(campaignId, supernovaAccountId) {
    return dispatch => {
        dispatch(request());

        statisticService.getTotalSpend(campaignId, supernovaAccountId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_TOTAL_SPEND_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_TOTAL_SPEND_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_TOTAL_SPEND_FAILURE, error } }
}

function getDaily(supernovaAccountId, startDate, endDate) {
    return dispatch => {
        dispatch(request());
        statisticService.getDaily(supernovaAccountId, startDate, endDate)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_DAILY_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_DAILY_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_DAILY_FAILURE, error } }
}

function getDailyByEntity(accountId, entityName, entityId, startDate, endDate, campaignType) {
    return dispatch => {
        dispatch(request());

        statisticService.getDailyByEntity(accountId, entityName, entityId, startDate, endDate, campaignType)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_DAILY_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_DAILY_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_DAILY_FAILURE, error } }
}

function getLifetimeByEntity(supernovaAccountId, entityName, entityId, startDate, endDate, campaignType) {
    return dispatch => {
        dispatch(request());

        statisticService.getLifetimeByEntity(supernovaAccountId, entityName, entityId, startDate, endDate, campaignType)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_LIFETIME_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_LIFETIME_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_LIFETIME_FAILURE, error } }
}

function getTopPerformingDMAByCTR(entityName, entityId, startDate, endDate, accountId, limit) {
    return dispatch => {
        dispatch(request());

        statisticService.getTopPerformingDMAByCTR(entityName, entityId, startDate, endDate, accountId, limit)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_TOP_DMA_BY_CTR_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_TOP_DMA_BY_CTR_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_TOP_DMA_BY_CTR_FAILURE, error } }
}

function getTopPerformingCountriesByCTR(entityName, entityId, startDate, endDate, accountId, limit) {
    return dispatch => {
        dispatch(request());

        statisticService.getTopPerformingCountriesByCTR(entityName, entityId, startDate, endDate, accountId, limit)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_TOP_COUNTRIES_BY_CTR_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_TOP_COUNTRIES_BY_CTR_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_TOP_COUNTRIES_BY_CTR_FAILURE, error } }
}

function getTopPerformingStatesByCTR(entityName, entityId, startDate, endDate, accountId, limit) {
    return dispatch => {
        dispatch(request());

        statisticService.getTopPerformingStatesByCTR(entityName, entityId, startDate, endDate, accountId, limit)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_TOP_STATES_BY_CTR_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_TOP_STATES_BY_CTR_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_TOP_STATES_BY_CTR_FAILURE, error } }
}

function getStatsByCountry(entityName, entityId, startDate, endDate, accountId) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsByCountry(entityName, entityId, startDate, endDate, accountId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_COUNTRIES_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_COUNTRIES_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_COUNTRIES_FAILURE, error } }
}

function getStatsByZone(entityName, entityId, startDate, endDate, accountId) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsByZone(entityName, entityId, startDate, endDate, accountId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_ZONE_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_ZONE_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_ZONE_FAILURE, error } }
}

function getStatsByDevice(entityName, entityId, startDate, endDate, accountId) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsByDevice(entityName, entityId, startDate, endDate, accountId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_DEVICE_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_DEVICE_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_DEVICE_FAILURE, error } }
}

function getStatsByOS(entityName, entityId, startDate, endDate, accountId) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsByOS(entityName, entityId, startDate, endDate, accountId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_OS_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_OS_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_OS_FAILURE, error } }
}

function getStatsByState(entityName, entityId, startDate, endDate, accountId) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsByState(entityName, entityId, startDate, endDate, accountId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_STATE_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_STATE_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_STATE_FAILURE, error } }
}

function getStatsByDMA(entityName, entityId, startDate, endDate, accountId) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsByDMA(entityName, entityId, startDate, endDate, accountId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_DMA_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_DMA_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_DMA_FAILURE, error } }
}

function getStatsByEnvironment(entityName, entityId, startDate, endDate, accountId) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsByEnvironment(entityName, entityId, startDate, endDate, accountId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_ENVIRONMENT_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_ENVIRONMENT_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_ENVIRONMENT_FAILURE, error } }
}

function getStatsByMediaSources(entityName, entityId, startDate, endDate, accountId) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsByMediaSources(entityName, entityId, startDate, endDate, accountId)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_MEDIASOURCE_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_MEDIASOURCE_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_MEDIASOURCE_FAILURE, error } }
}

function getStatsPerCampaign(supernovaAccountId, startDate, endDate) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsPerCampaign(supernovaAccountId, startDate, endDate)
            .then(
                statistics => {
                    dispatch(success(statistics))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_CAMPAIGN_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_CAMPAIGN_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_CAMPAIGN_FAILURE, error } }
}

function getStatsPerAdgroup(supernovaAccountId, startDate, endDate) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsPerAdgroup(supernovaAccountId, startDate, endDate)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_ADGROUP_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_ADGROUP_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_ADGROUP_FAILURE, error } }
}

function getStatsPerCreative(supernovaAccountId, startDate, endDate) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsPerCreative(supernovaAccountId, startDate, endDate)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_CREATIVE_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_CREATIVE_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_CREATIVE_FAILURE, error } }
}

function getStatsPerAds(supernovaAccountId, startDate, endDate) {
    return dispatch => {
        dispatch(request());

        statisticService.getStatsPerAds(supernovaAccountId, startDate, endDate)
            .then(
                statistics => dispatch(success(statistics)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: statisticConstants.GET_ADS_REQUEST } }
    function success(statistics) { return { type: statisticConstants.GET_ADS_SUCCESS, statistics } }
    function failure(error) { return { type: statisticConstants.GET_ADS_FAILURE, error } }
}
