import { campaignConstants } from '../_constants';
import { campaignService, adgroupService, duplicatesService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const campaignActions = {
    createCampaign,
    updateCampaign,
    getCampaigns,
    select,
    getCampaignDetail,
    addPushCampaign,
    editPushCampaign,
    getInactiveCampaigns,
    disableCampaign,
    enableCampaign,
};

///////////////////////////////////////////////////////////////////////////////////////
//  Note:   Create campaign AND Duplicate campaign shares the same function.
//          If adgroup is not null then it means we are duplicating the campaign.
///////////////////////////////////////////////////////////////////////////////////////
function createCampaign(campaign, adgroup=null) {
    return dispatch => {
        dispatch(request(campaign));
        let _adgroup = adgroup;
        const goals = campaign.goals;
        const budgets = campaign.budgets;
        const accountId = campaign.accountId;
        const supernovaAccountId = campaign.supernovaAccountId;
        const totalProcess = budgets.length + goals.length + 1; //Note that we added one (1) because this is for add campaign api call
        let completedProcess = 0

        //1 Create campaign
        if(_adgroup != null){
            dispatch(alertActions.loading("Duplicating campaign..."));
        }
        else{
            dispatch(alertActions.loading("Creating campaign..."));
        }
        console.log("Adding campaign: " + campaign.name);
        campaignService.createCampaign(campaign)
            .then(
                campaign => { 
                    completedProcess += 1;
                    console.log(`newCampaignId ${campaign.id}`)
                    console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Adding campaign");
                    if(_adgroup != null){
                        
                        //Add the duplicated campaignId to duplicates table so we have a reference that it is a duplicate even if cron overwrites it through syncing
                        duplicatesService.addDuplicateReference(supernovaAccountId, campaign.id, _adgroup.campaignId)
                        .then(
                            duplicate => { 
                                duplicatesService.getDuplicatedCampaignsBySupernovasupernovaAccountId(accountId)
                                .then(
                                    duplicate => { 
                                        console.log("Duplicates up to date")
                                    },
                                    error => {
                                        console.log("ERROR: Adding duplicates");
                                        dispatch(failure(error.toString()));
                                        dispatch(alertActions.error(error.toString()));
                                    }
                                    );
                            },
                            error => {
                                console.log("ERROR: Adding duplicates");
                                dispatch(failure(error.toString()));
                                dispatch(alertActions.error(error.toString()));
                            }
                            );
                        
                        
                        //Duplicate the adgroup as well
                        _adgroup.campaignId = campaign.id

                        //Make sure date is not earlier than today. If so then change it to tomorrow (Zemanta requires it must be a future date)
                        let minimumDate   = new Date();
                        let dateToCheck     = new Date(_adgroup.startDate)
                        minimumDate.setDate(minimumDate.getDate() + 1); 
                        if(dateToCheck < minimumDate){
                            const year = minimumDate.getFullYear();
                            const month = String(minimumDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                            const day = String(minimumDate.getDate()).padStart(2, '0');
                            const formattedMinimumDate = `${year}-${month}-${day}`;
                            _adgroup.startDate = formattedMinimumDate
                        }

                        console.log(_adgroup.startDate)

                        //Make sure the bid is not 0 (Zemanta requires it to be at least $0.01)
                        if(_adgroup.bid == "0"){
                            _adgroup.bid = "0.01"
                        }

                        adgroupService.createAdgroup(_adgroup, accountId)
                        .then(
                            adgroup => { 
                                dispatch(alertActions.success('Campaign is duplicated'));
                            },
                            error => {
                                console.log("ERROR: Duplicating adgroup");
                                dispatch(failure(error.toString()));
                                dispatch(alertActions.error(error.toString()));
                            }
                            );
                    }
                    if(completedProcess >= totalProcess){
                        campaignService.getCampaigns(supernovaAccountId)
                        .then(
                            campaigns => {
                                dispatch(success(campaigns))
                                dispatch(alertActions.success('New Campaign Created'));
                            },
                            error => {
                                dispatch(failure(error.toString()))
                            }
                            );
                        }
                        
                        
                    //2. Update Goals (Delete, Add, Update)
                    goals.forEach((goal, index) => {
                        console.log("Adding goal: " + goal.type);
                        console.log(campaign)
                        campaignService.addGoal(goal, campaign.id,)
                            .then(
                                campaign => {
                                    completedProcess += 1;
                                    console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Adding goal");
                                    dispatch(progressActions.update(completedProcess, totalProcess));
                                    if(completedProcess >= totalProcess){
                                        campaignService.getCampaigns(supernovaAccountId)
                                        .then(
                                            campaigns => {
                                                dispatch(success(campaigns))
                                                console.log("New Campaign List Loaded:");
                                                console.log(campaigns);
                                                if(adgroup == null){
                                                    dispatch(alertActions.success('New Campaign Created'));
                                                }
                                                else{
                                                    dispatch(alertActions.success('Campaign is duplicated'));
                                                }
                                            },
                                            error => {
                                                dispatch(failure(error.toString()))
                                            }
                                        );
                                    }
                                },
                                error => {
                                    completedProcess += 1;
                                    console.log("ERROR: [" + completedProcess + "/" + totalProcess + "]" + " Adding goal");
                                    dispatch(progressActions.update(completedProcess, totalProcess));
                                    dispatch(failure(error.toString()));
                                    dispatch(alertActions.error(error.toString()));
                                }
                            );
                    });

                    //3. Update Budgets (Add, Update)
                    budgets.forEach((budget, index) => {
                        console.log("Adding budget: " + budget.startDate + " to " + budget.endDate);
                        campaignService.addBudget(budget, campaign.id)
                        .then(
                            campaign => {
                                completedProcess += 1;
                                console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Adding budget");
                                dispatch(progressActions.update(completedProcess, totalProcess));
                                if(completedProcess >= totalProcess){
                                    campaignService.getCampaigns(supernovaAccountId)
                                    .then(
                                        campaigns => {
                                            console.log("New Campaign List Loaded:");
                                            console.log(campaigns);
                                            dispatch(success(campaigns))
                                            if(adgroup == null){
                                                dispatch(alertActions.success('Changes Saved'));
                                            }
                                            else{
                                                dispatch(alertActions.success('Campaign is duplicated'));
                                            }
                                        },
                                        error => {
                                            dispatch(failure(error.toString()))
                                        }
                                    );
                                }
                            },
                            error => {
                                completedProcess += 1;
                                console.log("ERROR: [" + completedProcess + "/" + totalProcess + "]" + " Adding budget");
                                dispatch(progressActions.update(completedProcess, totalProcess));
                                dispatch(failure(error.toString()));
                                dispatch(alertActions.error(error.toString()));
                            }
                        );
                    });
                },
                error => {
                    completedProcess += 1;
                    console.log("ERROR: [" + completedProcess + "/" + totalProcess + "]" + " Adding campaign");
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(campaign) { return { type: campaignConstants.CREATE_REQUEST, campaign } }
    function success(campaigns) { return { type: campaignConstants.CREATE_SUCCESS, campaigns } }
    function failure(error) { return { type: campaignConstants.CREATE_FAILURE, error } }
}

function select(campaignID) {
    return dispatch => {
        dispatch(request(campaignID));
    };

    function request(campaignID) { return { type: campaignConstants.SELECT_CAMPAIGN, campaignID } }
}

function updateCampaign(campaign, supernovaAccountId) {
    return dispatch => {
        dispatch(request(campaign));
        const goals                 = campaign.goals;
        const budgets               = campaign.budgets;
        const accountId             = campaign.accountId;
        const campaignId            = campaign.id;
        let totalProcess            = budgets.filter(item => item != null).filter(item => item.modified).length + goals.filter(item => item.modified || item.deleted).length;
        let completedProcess        = 0

        let totalItemsDeleted       = 0;
        const totalItemsToDelete    = goals.filter(item => item.deleted).length;

        dispatch(alertActions.loading("Saving Changes..."));

        // 1. Update Campaign:
        totalProcess += 1;
        console.log("Updating campaign");
        campaignService.updateCampaign(campaign)
            .then(
                campaign => {
                    completedProcess += 1;
                    console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Updating campaign");
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    if(completedProcess >= totalProcess){
                        campaignService.getCampaigns(supernovaAccountId)
                        .then(
                            campaigns => {
                                dispatch(success(campaigns))
                                dispatch(alertActions.success('Changes Saved'));
                            },
                            error => {
                                dispatch(failure(error.toString()))
                            }
                        );
                    }
                },
                
                error => {
                    completedProcess += 1;
                    dispatch(progressActions.update(completedProcess, totalProcess));
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );


        //2. IF there are items to delete THEN delete them first (to avoid adding duplicates) ELSE proceed with Add and Update goals (The one in the else statement is the same, make sure to update that as well when modifying the add and update)
        if(totalItemsToDelete > 0){
            goals.filter(item => item.deleted).forEach((element, index) => {
                console.log("Deleting goal: " + element.type)
                campaignService.deleteGoal(campaign.id, element.id)
                .then(
                    campaign => {
                        completedProcess += 1;
                        totalItemsDeleted += 1;
                        console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Deleting goal: " + element.type)
                        dispatch(progressActions.update(completedProcess, totalProcess));

                        //If there are no more other jobs to process then show success message
                        if(completedProcess >= totalProcess){
                            campaignService.getCampaigns(supernovaAccountId)
                            .then(
                                campaigns => {
                                    dispatch(success(campaigns))
                                    dispatch(alertActions.success('Changes Saved'));
                                },
                                error => {
                                    dispatch(failure(error.toString()))
                                }
                            );
                        }

                        //After deleting items check if there are goals to add or update
                        if(totalItemsDeleted >= totalItemsToDelete){

                            //ADD GOALS: We know its new (for adding) because there is no existing id yet.
                            goals.filter(item => !item.deleted && item.id === undefined && item.modified).forEach((element, index) => {
                                console.log("Adding goal: " + element.type)
                                campaignService.addGoal(element, campaignId)
                                    .then(
                                        campaign => {
                                            completedProcess += 1;
                                            console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Adding goal: " + element.type)
                                            dispatch(progressActions.update(completedProcess, totalProcess));
                                            if(completedProcess >= totalProcess){
                                                campaignService.getCampaigns(supernovaAccountId)
                                                .then(
                                                    campaigns => {
                                                        dispatch(success(campaigns))
                                                        dispatch(alertActions.success('Changes Saved'));
                                                    },
                                                    error => {
                                                        dispatch(failure(error.toString()))
                                                    }
                                                );
                                            }
                                        },
                                        error => {
                                            completedProcess += 1;
                                            dispatch(progressActions.update(completedProcess, totalProcess));
                                            dispatch(failure(error.toString()));
                                            dispatch(alertActions.error(error.toString()));
                                        }
                                    );
                            });
                            //UPDATE GOALS: We know its for update because it has an existing id
                            goals.filter(item => !item.deleted && item.id != undefined && item.modified).forEach((element, index) => {
                                console.log("Updating goal: " + element.type)
                                campaignService.updateGoal(element, campaign.id, element.id)
                                    .then(
                                        campaign => {
                                            completedProcess += 1;
                                            console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Updating goal: " + element.type)
                                            dispatch(progressActions.update(completedProcess, totalProcess));
                                            if(completedProcess >= totalProcess){
                                                campaignService.getCampaigns(supernovaAccountId)
                                                .then(
                                                    campaigns => {
                                                        dispatch(success(campaigns))
                                                        dispatch(alertActions.success('Changes Saved'));
                                                    },
                                                    error => {
                                                        dispatch(failure(error.toString()))
                                                    }
                                                );
                                            }
                                        },
                                        error => {
                                            completedProcess += 1;
                                            dispatch(progressActions.update(completedProcess, totalProcess));
                                            dispatch(failure(error.toString()));
                                            dispatch(alertActions.error(error.toString()));
                                        }
                                    );
                            });
                        }
                    },
                    error => {
                        completedProcess += 1;
                        dispatch(progressActions.update(completedProcess, totalProcess));
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
            });
        }
        else{
            //We know its new (for adding) because there is no existing id yet.
            goals.filter(item => !item.deleted && item.id === undefined && item.modified).forEach((element, index) => {
                console.log("Adding goal: " + element.type)
                campaignService.addGoal(element, campaignId)
                    .then(
                        campaign => {
                            completedProcess += 1;
                            console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Adding goal: " + element.type)
                            dispatch(progressActions.update(completedProcess, totalProcess));
                            if(completedProcess >= totalProcess){
                                campaignService.getCampaigns(supernovaAccountId)
                                .then(
                                    campaigns => {
                                        dispatch(success(campaigns))
                                        dispatch(alertActions.success('Changes Saved'));
                                    },
                                    error => {
                                        dispatch(failure(error.toString()))
                                    }
                                );
                            }
                        },
                        error => {
                            completedProcess += 1;
                            dispatch(progressActions.update(completedProcess, totalProcess));
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.error(error.toString()));
                        }
                    );
            });
            //We know its for update because it has an existing id
            goals.filter(item => !item.deleted && item.id != undefined && item.modified).forEach((element, index) => {
                console.log("Updating goal: " + element.type)
                campaignService.updateGoal(element, campaign.id, element.id)
                    .then(
                        campaign => {
                            completedProcess += 1;
                            console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Updating goal: " + element.type)
                            dispatch(progressActions.update(completedProcess, totalProcess));
                            if(completedProcess >= totalProcess){
                                campaignService.getCampaigns(supernovaAccountId)
                                .then(
                                    campaigns => {
                                        dispatch(success(campaigns))
                                        dispatch(alertActions.success('Changes Saved'));
                                    },
                                    error => {
                                        dispatch(failure(error.toString()))
                                    }
                                );
                            }
                        },
                        error => {
                            completedProcess += 1;
                            dispatch(progressActions.update(completedProcess, totalProcess));
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.error(error.toString()));
                        }
                    );
            });
        }
        


        

        //3. Update Budgets (Add, Update)
        budgets.filter(item => item != null).filter(item => item.modified).forEach((element, index) => {
            const noGoalID = element.id === undefined
            const action = noGoalID ? "add" : "update"
            switch(action){
                case "update":
                    console.log("Updating budget");
                    campaignService.updateBudget(element, campaign.id, element.id)
                    .then(
                        campaign => {
                            completedProcess += 1;
                            console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Updating budget");
                            dispatch(progressActions.update(completedProcess, totalProcess));
                            if(completedProcess >= totalProcess){
                                campaignService.getCampaigns(supernovaAccountId)
                                .then(
                                    campaigns => {
                                        dispatch(success(campaigns))
                                        dispatch(alertActions.success('Changes Saved'));
                                    },
                                    error => {
                                        dispatch(failure(error.toString()))
                                    }
                                );
                            }
                        },
                        error => {
                            completedProcess += 1;
                            dispatch(progressActions.update(completedProcess, totalProcess));
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.error(error.toString()));
                        }
                    );
                    break;
                case "add":
                    console.log("Adding budget");
                    campaignService.addBudget(element, campaign.id, element.id)
                    .then(
                        campaign => {
                            completedProcess += 1;
                            console.log("DONE: [" + completedProcess + "/" + totalProcess + "]" + " Updating budget");
                            dispatch(progressActions.update(completedProcess, totalProcess));
                            if(completedProcess >= totalProcess){
                                campaignService.getCampaigns(supernovaAccountId)
                                .then(
                                    campaigns => {
                                        dispatch(success(campaigns))
                                        dispatch(alertActions.success('Changes Saved'));
                                    },
                                    error => {
                                        dispatch(failure(error.toString()))
                                    }
                                );
                            }
                        },
                        error => {
                            completedProcess += 1;
                            dispatch(progressActions.update(completedProcess, totalProcess));
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.error(error.toString()));
                        }
                    );
                    break;
            }
        });

        
    };

    function request(campaign) { return { type: campaignConstants.UPDATE_REQUEST, campaign } }
    function success(campaigns) { return { type: campaignConstants.UPDATE_SUCCESS, campaigns } }
    function failure(id, error) { return { type: campaignConstants.UPDATE_FAILURE, id, error } }
}

function getCampaigns(supernovaAccountId) {
    return dispatch => {
        dispatch(request());
        //console.log("Fetching campaign list");
        campaignService.getCampaigns(supernovaAccountId)
            .then(
                campaigns => {
                    //console.log("DONE: Fetching campaign list");
                    dispatch(success(campaigns))
                },
                error => {
                    //console.log("ERROR: Fetching campaign list");
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: campaignConstants.GET_REQUEST } }
    function success(campaigns) { return { type: campaignConstants.GET_SUCCESS, campaigns } }
    function failure(error) { return { type: campaignConstants.GET_FAILURE, error } }
}

function getInactiveCampaigns(supernovaAccountId) {
    return dispatch => {
        dispatch(request());
        campaignService.getInactiveCampaigns(supernovaAccountId)
            .then(
                campaigns => {
                    dispatch(success(campaigns))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: campaignConstants.GET_INACTIVE_REQUEST } }
    function success(campaigns) { return { type: campaignConstants.GET_INACTIVE_SUCCESS, campaigns } }
    function failure(error) { return { type: campaignConstants.GET_INACTIVE_FAILURE, error } }
}

function disableCampaign(data) {
    return dispatch => {
        dispatch(request());
        campaignService.disableCampaign(data)
            .then(
                () => {
                    dispatch(alertActions.success('Campaign Disabled'));
                    dispatch(success(data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: campaignConstants.DISABLE_REQUEST } }
    function success(data) { return { type: campaignConstants.DISABLE_SUCCESS, data } }
    function failure(error) { return { type: campaignConstants.DISABLE_FAILURE, error } }
}

function enableCampaign(data) {
    const campaignId = data.campaignId
    return dispatch => {
        dispatch(request());
        campaignService.enableCampaign(data)
            .then(
                () => {
                    dispatch(alertActions.success('Campaign Enabled'));
                    dispatch(success(campaignId))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: campaignConstants.ENABLE_REQUEST } }
    function success(campaignId) { return { type: campaignConstants.ENABLE_SUCCESS, campaignId } }
    function failure(error) { return { type: campaignConstants.ENABLE_FAILURE, error } }
}

function getCampaignDetail(campaignId) {
    return dispatch => {
        dispatch(request());

        campaignService.getCampaignDetail(campaignId)
            .then(
                campaign => dispatch(success(campaign)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: campaignConstants.GET_DETAIL_REQUEST } }
    function success(campaign) { return { type: campaignConstants.GET_DETAIL_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.GET_DETAIL_FAILURE, error } }
}

function addPushCampaign(data) {
    return dispatch => {
        dispatch(request());

        campaignService.addPushCampaign(data)
            .then(
                result => {
                    console.log("xxxxxxxxxxxxxxx")
                    console.log("add new push campaign done")
                    console.log(result)
                    console.log("xxxxxxxxxxxxxxx")
                    // result.id = result._id
                    dispatch(alertActions.success('New Campaign Created'));
                    dispatch(success(result))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: campaignConstants.CREATE_PUSH_REQUEST } }
    function success(pushCampaigns) { return { type: campaignConstants.CREATE_PUSH_SUCCESS, pushCampaigns } }
    function failure(error) { return { type: campaignConstants.CREATE_PUSH_FAILURE, error } }
}

function editPushCampaign(data, campaignId) {
    return dispatch => {
        dispatch(request());
        console.log(campaignId)
        campaignService.editPushCampaign(data, campaignId)
            .then(
                () => {
                    dispatch(alertActions.success('Changes saved!'));
                    dispatch(success({name:data.name, campaignId:campaignId}))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: campaignConstants.UPDATE_PUSH_REQUEST } }
    function success(data) { return { type: campaignConstants.UPDATE_PUSH_SUCCESS, data } }
    function failure(error) { return { type: campaignConstants.UPDATE_PUSH_FAILURE, error } }
}