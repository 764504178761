import { queryConstants } from '../_constants';

export function query(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET
    //=====================================
    case queryConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
        audience_description:""
      };
    case queryConstants.GET_SUCCESS:
      return {
        ...state,
        loading               : false,
        audience_description  : action.query.audience_description,
        geo_scope             : action.query.geo_scope,
      };
    case queryConstants.GET_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return {
        ...state,
        loading: false,
      }
  }
}