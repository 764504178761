import config from 'config';
import { authHeader } from '../_helpers';

export const statisticService = {
    getDaily,
    getDailyByEntity,
    getLifetimeByEntity,
    getStatsPerCampaign,
    getStatsPerAdgroup,
    getStatsPerCreative,
    getStatsPerAds,
    getTopPerformingDMAByCTR,
    getTopPerformingCountriesByCTR,
    getTopPerformingStatesByCTR,

    //Breakdowns
    getStatsByCountry,
    getStatsByZone,
    getStatsByDevice,
    getStatsByOS,
    getStatsByState,
    getStatsByDMA,
    getStatsByEnvironment,
    getStatsByMediaSources,

    //Get Total Spend of a Campaign
    getTotalSpend,

    //Get Total Impressions and Clicks of a Campaign
    getTotalByCampaign,
    getTotalByAdgroup

};

function getTotalByCampaign(campaignId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics/totals/${campaignId}`, requestOptions).then(handleResponse);
}

function getTotalByAdgroup(campaignId, adgroupId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics/totals/${campaignId}/${adgroupId}`, requestOptions).then(handleResponse);
}

function getTotalSpend(campaignId, supernovaAccountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics/total_spend/${campaignId}/${supernovaAccountId}`, requestOptions).then(handleResponse);
}

function getStatsByCountry(entityName, entityId, startDate, endDate, accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_country/all/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getStatsByZone(entityName, entityId, startDate, endDate, accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_zone/all/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getStatsByDevice(entityName, entityId, startDate, endDate, accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_device/all/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getStatsByOS(entityName, entityId, startDate, endDate, accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_os/all/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getStatsByState(entityName, entityId, startDate, endDate, accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_state/all/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getStatsByDMA(entityName, entityId, startDate, endDate, accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_dma/all/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getStatsByEnvironment(entityName, entityId, startDate, endDate, accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_environment/all/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getStatsByMediaSources(entityName, entityId, startDate, endDate, accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_mediasource/all/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getTopPerformingDMAByCTR(entityName, entityId, startDate, endDate, accountId, limit) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_dma/top/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}/${limit}`, requestOptions).then(handleResponse);
}

function getTopPerformingCountriesByCTR(entityName, entityId, startDate, endDate, accountId, limit) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_country/top/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}/${limit}`, requestOptions).then(handleResponse);
}

function getTopPerformingStatesByCTR(entityName, entityId, startDate, endDate, accountId, limit) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics_by_state/top/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}/${limit}`, requestOptions).then(handleResponse);
}

function getDaily(supernovaAccountId, startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics/daily/${supernovaAccountId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getDailyByEntity(accountId, entityName, entityId, startDate, endDate, campaignType) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics/daily/${accountId}/${entityName}/${entityId}/${startDate}/${endDate}/${campaignType}`, requestOptions).then(handleResponse);
}

function getLifetimeByEntity(supernovaAccountId, entityName, entityId, campaignType) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiURL}/statistics/lifetime/${supernovaAccountId}/${entityName}/${entityId}/${campaignType}`, requestOptions).then(handleResponse);
}

function getStatsPerCampaign(supernovaAccountId, startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${config.apiURL}/statistics/campaign/${supernovaAccountId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getStatsPerAdgroup(supernovaAccountId, startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${config.apiURL}/statistics/adgroup/${supernovaAccountId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getStatsPerCreative(supernovaAccountId, startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${config.apiURL}/statistics/creative/${supernovaAccountId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function getStatsPerAds(supernovaAccountId, startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${config.apiURL}/statistics/ads/${supernovaAccountId}/${startDate}/${endDate}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}