import { adgroupConstants } from '../_constants';
import { 
    adgroupService,
    creativeService,
    adgroupCreativeService
 } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const adgroupActions = {
    getAdgroups,
    getAllAdgroups,
    getAdgroupNames,
    createAdgroup,
    createMultipleAdgroups,
    getAdgroupDetail,
    select,
    updateAdgroup,
    createAdgroupPush,
    updateAdgroupPush,
    togglePushCampaign,
    createSupernovaAdgroup,
    getLockList,
    updateSupernovaAdgroup
    // deactive
};



function getLockList(campaignId) {
    return dispatch => {
        dispatch(request());

        adgroupService.getLockList(campaignId)
            .then(
                adgroups => {
                    dispatch(success(adgroups))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: adgroupConstants.GETLOCK_REQUEST } }
    function success(adgroups) { return { type: adgroupConstants.GETLOCK_SUCCESS, adgroups } }
    function failure(error) { return { type: adgroupConstants.GETLOCK_FAILURE, error } }
}

function getAdgroups(campaignId) {
    return dispatch => {
        dispatch(request());

        adgroupService.getAdgroups(campaignId)
            .then(
                adgroups => {
                    dispatch(success(adgroups))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: adgroupConstants.GET_REQUEST } }
    function success(adgroups) { return { type: adgroupConstants.GET_SUCCESS, adgroups } }
    function failure(error) { return { type: adgroupConstants.GET_FAILURE, error } }
}

function getAllAdgroups(accountId) {
    return dispatch => {
        dispatch(request());

        adgroupService.getAllAdgroups(accountId)
            .then(
                adgroups => dispatch(success(adgroups)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: adgroupConstants.GET_REQUEST } }
    function success(adgroups) { return { type: adgroupConstants.GET_SUCCESS, adgroups } }
    function failure(error) { return { type: adgroupConstants.GET_FAILURE, error } }
}

function getAdgroupNames() {
    return dispatch => {
        dispatch(request());

        adgroupService.getAdgroupNames()
            .then(
                adgroups => dispatch(success(adgroups)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: adgroupConstants.GET_REQUEST } }
    function success(adgroups) { return { type: adgroupConstants.GET_SUCCESS, adgroups } }
    function failure(error) { return { type: adgroupConstants.GET_FAILURE, error } }
}



function createAdgroup(adgroup, accountId) {
    return dispatch => {
        const campaignId = adgroup.campaignId
        const supernovaCampaignId = adgroup.supernovaCampaignId 
        dispatch(request(adgroup));
        dispatch(alertActions.loading("Creating Ad Group..."));
        console.log("Adding adgroup: " + adgroup.name);
        adgroupService.createAdgroup(adgroup, accountId)
            .then(
                adgroup => { 
                    // adgroupService.getAdgroups(campaignId)
                    adgroupService.getAdgroups(supernovaCampaignId)
                    .then(
                        adgroups => {
                            dispatch(success(adgroups))
                            dispatch(alertActions.success('New Adgroup Created'));
                            console.log("DONE: Adding adgroup");
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => {
                    console.log("ERROR: Adding adgroup");
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(adgroup) { return { type: adgroupConstants.CREATE_REQUEST, adgroup } }
    function success(adgroups) { return { type: adgroupConstants.CREATE_SUCCESS, adgroups } }
    function failure(error) { return { type: campaignConstants.CREATE_FAILURE, error } }
}

function createSupernovaAdgroup(adgroup, accountId, campaignId, clusters, budgetInfo) {
    let supernovaAdgroupId = "";
    let supernovaCampaignId = adgroup.supernovaCampaignId
    // console.log(budgetInfo);
    // return;
    return dispatch => {
        dispatch(request(adgroup));
        // dispatch(alertActions.loading("Creating Ad Group..."));
        console.log("Adding adgroup: " + adgroup.name);
        return adgroupService.createSupernovaAdgroup(adgroup, accountId, campaignId)
        .then(adgroup => { 
                supernovaAdgroupId = adgroup.newAdgroupId
                return adgroupService.getAdgroups(campaignId)
        })
        .then(adgroups => {
            dispatch(success(adgroups))
            console.log("CREATING 1 to 1 Zip")
            adgroupService.createAdgroupSupernovaExtensions({
                campaignId          : campaignId,
                adgroup_name        : `${adgroup.name.replace(/[^\w\s"']/gi, '')}`,
                accountId           : accountId,
                supernovaAdgroupId  : supernovaAdgroupId,
                supernovaCampaignId  : supernovaCampaignId,
                clusters            : clusters,
                budgetInfo          : budgetInfo
            }, adgroup.query_id);            
        })
    };

    function request(adgroup) { return { type: adgroupConstants.CREATE_REQUEST, adgroup } }
    function success(adgroups) { return { type: adgroupConstants.CREATE_SUCCESS, adgroups } }
    function failure(error) { return { type: campaignConstants.CREATE_FAILURE, error } }
}

//This function is similar to createAdgroup. The only difference is that this adgroup creates multiple with just a difference audience for each
//Note the parameter adgroupNames is an array the contains the list values from the multiple input fields
function createMultipleAdgroups(adgroup, accountId, adgroupNames) {
    return dispatch => {
        const campaignId = adgroup.campaignId
        dispatch(request(adgroup));
        dispatch(alertActions.loading("Creating Ad Group..."));
        
        //For each audience, create a new adgroup
        //Submit only one audience per adgroup
        // adgroup.targeting.audience["AND"][0]["OR"].map((audience, index) => {
        adgroup.targeting.audience["AND"].map((audience, index) => {
            let newAdgroup = adgroup
            // newAdgroup.name = adgroup.name + " " + audience.category
            newAdgroup.name = adgroupNames[index].name
            console.log(audience.category)
            newAdgroup.targeting.audience = {
                AND : [
                    audience
                ]
            }

            adgroupService.createAdgroup(newAdgroup, accountId)
            .then(
                adgroup => { 
                    adgroupService.getAdgroups(campaignId)
                    .then(
                        adgroups => {
                            dispatch(success(adgroups))
                            dispatch(alertActions.success('New Adgroup Created'));
                            console.log("DONE: Adding adgroup");
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => {
                    console.log("ERROR: Adding adgroup");
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );


        })




        return;
        
    };

    function request(adgroup) { return { type: adgroupConstants.CREATE_REQUEST, adgroup } }
    function success(adgroups) { return { type: adgroupConstants.CREATE_SUCCESS, adgroups } }
    function failure(error) { return { type: campaignConstants.CREATE_FAILURE, error } }
}
function createAdgroupPush(adgroup, accountId, campaignId) {
    return dispatch => {
        dispatch(request(adgroup));
        dispatch(alertActions.loading("Creating Ad Group..."));
        console.log("Adding adgroup push: " + adgroup.name);
        adgroupService.createAdgroupPush(adgroup, accountId, campaignId)
            .then(
                adgroup => { 
                    adgroupService.getAdgroups(campaignId)
                    .then(
                        adgroups => {
                            dispatch(success(adgroups))
                            dispatch(alertActions.success('New Adgroup Created'));
                            console.log("DONE: Adding adgroup");
                            console.log(accountId)
                            // creativeService.getCreativesInAdgroup(accountId);
                            adgroupCreativeService.getAdgroupCreatives(accountId);
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => {
                    console.log("ERROR: Adding adgroup push");
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(adgroup) { return { type: adgroupConstants.CREATE_REQUEST, adgroup } }
    function success(adgroups) { return { type: adgroupConstants.CREATE_SUCCESS, adgroups } }
    function failure(error) { return { type: campaignConstants.CREATE_FAILURE, error } }
}

function select(adgroupID) {
    return dispatch => {
        dispatch(request(adgroupID));
    };

    function request(adgroupID) { return { type: adgroupConstants.SELECT_ADGROUP, adgroupID } }
}

function getAdgroupDetail(adgroupId) {
    return dispatch => {
        dispatch(request());

        adgroupService.getAdgroupDetail(adgroupId)
            .then(
                adgroup => dispatch(success(adgroup)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: adgroupConstants.GET_DETAIL_REQUEST } }
    function success(adgroup) { return { type: adgroupConstants.GET_DETAIL_SUCCESS, adgroup } }
    function failure(error) { return { type: adgroupConstants.GET_DETAIL_FAILURE, error } }
}

function updateAdgroup(adgroup, accountId) {
    return dispatch => {
        const supernovaCampaignId = adgroup.supernovaCampaignId
        dispatch(request(adgroup));
        dispatch(alertActions.loading("Saving changes..."));

        // 1. Update Adgroup Details
        adgroupService.updateAdgroup(adgroup, accountId)
            .then(
                adgroup => {
                    adgroupService.getAdgroups(supernovaCampaignId)
                    .then(
                        adgroups => {
                            dispatch(success(adgroups))
                            dispatch(alertActions.success('Changes Saved'));
                            console.log("DONE: Updating adgroup");
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => {
                    console.log("ERROR: Updating adgroup");
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(adgroup) { return { type: adgroupConstants.UPDATE_REQUEST, adgroup } }
    function success(adgroups) { return { type: adgroupConstants.UPDATE_SUCCESS, adgroups } }
    function failure(id, error) { return { type: adgroupConstants.UPDATE_FAILURE, id, error } }
}

function updateSupernovaAdgroup(adgroup, accountId) {
    return dispatch => {
        const campaignId = adgroup.campaignId
        dispatch(request(adgroup));
        dispatch(alertActions.loading("Saving changes..."));
        console.log("Updating adgroup: " + adgroup.name);

        // 1. Update Adgroup Details
        adgroupService.updateSupernovaAdgroup(adgroup, accountId)
            .then(
                adgroup => {
                    adgroupService.getAdgroups(campaignId)
                    .then(
                        adgroups => {
                            dispatch(success(adgroups))
                            dispatch(alertActions.success('Changes Saved'));
                            console.log("DONE: Updating adgroup");
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => {
                    console.log("ERROR: Updating adgroup");
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(adgroup) { return { type: adgroupConstants.UPDATE_REQUEST, adgroup } }
    function success(adgroups) { return { type: adgroupConstants.UPDATE_SUCCESS, adgroups } }
    function failure(id, error) { return { type: adgroupConstants.UPDATE_FAILURE, id, error } }
}

function updateAdgroupPush(adgroup, accountId, campaignId) {
    return dispatch => {
        dispatch(request(adgroup));
        dispatch(alertActions.loading("Saving changes..."));
        console.log("Updating adgroup: " + adgroup);
        return;

        // 1. Update Adgroup Details
        adgroupService.updateAdgroupPush(adgroup, accountId)
            .then(
                adgroup => {
                    console.log("xxxxxxC1")
                    console.log(campaignId)
                    adgroupService.getAdgroups(campaignId)
                    .then(
                        adgroups => {
                            dispatch(success(adgroups))
                            dispatch(alertActions.success('Changes Saved'));
                            console.log("DONE: Updating adgroup");
                        },
                        error => {
                            dispatch(failure(error.toString()))
                        }
                    );
                },
                error => {
                    console.log("ERROR: Updating adgroup");
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(adgroup) { return { type: adgroupConstants.UPDATE_REQUEST, adgroup } }
    function success(adgroups) { return { type: adgroupConstants.UPDATE_SUCCESS, adgroups } }
    function failure(id, error) { return { type: adgroupConstants.UPDATE_FAILURE, id, error } }
}
function togglePushCampaign(id, action, campaignId) {
        return dispatch => {
            dispatch(request());
            dispatch(alertActions.loading("Saving changes..."));
            console.log("Updating adgroup (push) ");
    
            // 1. Update Adgroup Details
            adgroupService.togglePushCampaign(id, action)
                .then(
                    adgroup => {
                        adgroupService.getAdgroups(campaignId)
                        .then(
                            adgroups => {
                                dispatch(success(adgroups))
                                dispatch(alertActions.success('Changes Saved'));
                                console.log("DONE: Updating adgroup (push)");
                            },
                            error => {
                                dispatch(failure(error.toString()))
                            }
                        );
                    },
                    error => {
                        console.log("ERROR: Updating adgroup");
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        };
    
        function request() { return { type: adgroupConstants.UPDATE_REQUEST } }
        function success(adgroups) { return { type: adgroupConstants.UPDATE_SUCCESS, adgroups } }
        function failure(id, error) { return { type: adgroupConstants.UPDATE_FAILURE, id, error } }
    }