import { deactivatedAdgroupConstants } from '../_constants';
import { 
    deactivatedAdgroupService,
 } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const deactivatedAdgroupActions = {
    getDeactivatedAdgroups,
};



function getDeactivatedAdgroups(supernovaAccountId) {
    return dispatch => {
        dispatch(request());

        deactivatedAdgroupService.getDeactivatedAdgroups(supernovaAccountId)
            .then(
                deactivatedAdgroups => {
                    // console.log(deactivatedAdgroups)
                    dispatch(success(deactivatedAdgroups))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: deactivatedAdgroupConstants.GET_DEACTIVATED_ADGROUPS_REQUEST } }
    function success(deactivatedAdgroups) { return { type: deactivatedAdgroupConstants.GET_DEACTIVATED_ADGROUPS_SUCCESS, deactivatedAdgroups } }
    function failure(error) { return { type: deactivatedAdgroupConstants.GET_DEACTIVATED_ADGROUPS_FAILURE, error } }
}
