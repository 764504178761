import config from 'config';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { listCreativeType, listImageCrop, listIABCategory, listCreativeAttributes  } from '../../_constants/data'
import { 
    overlayActions, 
    creativeActions, 
    adgroupCreativeActions,
    creativesPendingActions,
} from '../../_actions';



//React Select
import { styleSelectCreativeForm, styleSelectBasic } from '../../_constants/settings'
import Select from 'react-select'

//Helpers
import '../../_helpers/calculate-ratio.js'

//Tooltip
import Carousel from 'react-bootstrap/Carousel'

//React bootstrap
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//React Frame Component
import InnerHTML from 'dangerously-set-html-content'


class CreativeForm extends React.Component {
    constructor(props) {
        super(props);
        const { overlay, creatives, selectedCreative, campaignType } = this.props
        this.state = {
            key:'info', //Used for react bootstrap tabs
            isPreviewOnDesktop: true,
            isNameFieldVisible: false,
            submitted: false,
            errorImageUrl: [""],
            errorIconUrl: [""],
            selected:0,
            newCreatives: overlay.purpose == "edit"
                // ? [creatives.items.filter(item => item?.supernovaCreativeId == selectedCreative || item?.id == selectedCreative)?.reduce((acc, cur) => cur)]     // Bug
                ? [creatives.items.filter(item => item?.supernovaCreativeId == selectedCreative || item?.id == selectedCreative).reduce((acc, cur) => cur, null)]   // Solution (Being tested): Provide a default value like 'null'
                : [{
                    state           : "INACTIVE",
                    accountId       : this.props.user.accountId,
                    title           : config.testMode ? `test ${Math.floor(Date.now() / 1000)}` : "Ad Name #1",
                    imageUrl        : config.testMode ? "https://beta.supernova.inc/public/uploads/1683545347467-dafefd26137ade4d48bbda1afced7908e5.gif" : "",
                    // imageUrl        : config.testMode ? "https://zem.outbrainimg.com/p/srv/sha/d8/06/ac/6ddf3f214cee3b8adbcbc3c6d566bcd84e.jpg" : "",
                    callToAction    : "Read More",
                    imageCrop       : "CENTER",
                    brandName       : config.testMode ? "Supernova House 300x250" : "",
                    description     : config.testMode ? "Supernova House 300x250" : "",
                    adTagWidth      : config.testMode ? "300" : "",
                    adTagHeight     : config.testMode ? "250" : "",
                    iconUrl         : config.testMode ? "https://beta.supernova.inc/public/uploads/1655957954534-brand-logo-sample.jpg" : "",
                    url             : config.testMode ? "https://www.supernova.inc" : "",
                    type            : campaignType == "DISPLAY" ? "DISPLAY_BANNER" : campaignType, // Change this when the DISPLAY_AD_TAG is ready. In the meantime we are only accepting DISPLAY_BANNER creative where campaign type is "DISPLAY"
                    displayUrl      : config.testMode ? "www.supernova.inc" : "",
                    label           : "",
                    trackers        : [{
                        "eventType": "IMPRESSION",
                        "method": "IMG",
                        "url": `https://pixel.supernova.inc/i/${this.props.user.accountId}/0`,
                        "trackerOptional": true
                    }],
                    videoUploadType : "DIRECT_UPLOAD"
                }]
        }
        this.handleCloseOverlay         = this.handleCloseOverlay.bind(this);
        this.handleDropdownChange       = this.handleDropdownChange.bind(this);   
        this.handleChangeInputText      = this.handleChangeInputText.bind(this);
        this.handleFileUpload           = this.handleFileUpload.bind(this);
        this.handleAddNewCreative       = this.handleAddNewCreative.bind(this);
        this.handleSelectCreative       = this.handleSelectCreative.bind(this);
        this.handleRemoveCreative       = this.handleRemoveCreative.bind(this);
        this.handleSubmit               = this.handleSubmit.bind(this);
        this.handleChangePreview        = this.handleChangePreview.bind(this);
        this.handleSetKey               = this.handleSetKey.bind(this);
        
        //For trackers
        this.handleAddTracker               = this.handleAddTracker.bind(this);
        this.handleChangeTrackerMethod      = this.handleChangeTrackerMethod.bind(this);
        this.handleDropdownChangeTracker    = this.handleDropdownChangeTracker.bind(this);   
        this.handleChangeInputTextTracker   = this.handleChangeInputTextTracker.bind(this);
        
        //For Video
        this.handleCreateVideoAsset     = this.handleCreateVideoAsset.bind(this);
    }

    componentDidMount(){

        //IF the purpose of the form is for editing (meaning the click url contains something) THEN
        //Show the original click url (hiding pixel.supernova)
        if(this.props.overlay.purpose == "edit"){
            let url = this.state.newCreatives[0]?.url
            //This if statements prevents error about cutting http. Because it will only replace ?=https if it exists in the string
            if(url?.indexOf("r=https") >= 0){
                const remove_str = url.indexOf("r=https") + 2
                const original_url = url.substring(remove_str)
                let new_items = this.state.newCreatives[0]
                new_items["url"] = original_url
                console.log(url)
                console.log(new_items)
                this.setState({
                    newCreatives:[new_items],
                });
            }
        }
    }


    componentDidUpdate(prevProps, prevState) {
        
        const { creatives, addCreative, addAdgroupCreatives, user } = this.props
        const { newCreatives, selected } = this.state
        
        //Check if imageUrl is done uploading
        if (creatives.file !== undefined && creatives.file[selected] && Object.hasOwn(creatives.file[selected], "imageUrl") && `${window.location.origin}/public/uploads/${creatives.file[selected]?.imageUrl}` !== newCreatives[selected]?.imageUrl) {

            const index = creatives.file[selected]?.index
            const value = `${window.location.origin}/public/uploads/${creatives.file[selected]?.imageUrl}`

            let items = [...newCreatives];
            let item = {...items[index]};
            item["imageUrl"] = value
            items[index] = item;
            console.log(items)
            this.setState({
                newCreatives:items,
            });
        }
        
        //Check if iconUrl is done uploading
        if (creatives.file !== undefined && creatives.file[selected] && Object.hasOwn(creatives.file[selected], "iconUrl") && `${window.location.origin}/public/uploads/${creatives.file[selected]?.iconUrl}` !== newCreatives[selected]?.iconUrl) {

            const index = creatives.file[selected]?.index
            const value = `${window.location.origin}/public/uploads/${creatives.file[selected]?.iconUrl}`

            let items = [...newCreatives];
            let item = {...items[index]};
            item["iconUrl"] = value
            items[index] = item;
            console.log(items)
            this.setState({
                newCreatives:items,
            });
        }
        
        //Check if Video is done uploading
        if (creatives.file !== undefined && creatives.file[selected] && Object.hasOwn(creatives.file[selected], "videoAssetId") && creatives.file[selected]?.videoAssetId !== newCreatives[selected]?.videoAssetId) {

            const index = creatives.file[selected]?.index
            const value = creatives.file[selected]?.videoAssetId 

            let items = [...newCreatives];
            let item = {...items[index]};
            item["videoAssetId"] = value
            items[index] = item;
            console.log(items)
            this.setState({
                newCreatives:items,
            });
        }
    }


    handleCloseOverlay() {
        this.props.closeOverlay()
        this.props.clearFileUploads()
    }

    handleDropdownChange(name, dropdown) {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown.value
        
        const { newCreatives, selected } = this.state;
        let items = [...newCreatives];
        let item = {...items[selected]};
        if(name == "adTagSize"){
            item.adTagWidth = value.split("x")[0]
            item.adTagHeight = value.split("x")[1]
        }
        else{
            item[name] = value;
        }
        items[selected] = item;
        this.setState({newCreatives:items});
    }
    
    handleChangeInputText(event) {
        const { name, value } = event.target;
        const { newCreatives, selected } = this.state;
        let items = [...newCreatives];
        let item = {...items[selected]};

        // If its a URL then automatically add "https" (Deprecated, we no longer add https because the limit is only 35 to conserve letters)
        // if([name] == "displayUrl" || [name] == "url"){
        if([name] == "url"){
            item[name] = !value.match(/^[a-zA-Z]+:\/\//) ? "https://" : "" + value
        }
        else{
            item[name] = value
        }
        // item[name] = value

        items[selected] = item;
        this.setState({
            newCreatives:items,
        });
    }
    
    handleFileUpload(event) {
        const { newCreatives, selected } = this.state;
        const field = event.target.name
        let file = event.target.files[0]

        if(field == "imageUrl" && newCreatives[0]?.type == "NATIVE"){
            //IF this is for imageURL of Native campaign THEN make sure it is at least 300x300
            let img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.onload = () => {
                if(img.width < 300 || img.height < 300){
                    let items = [...newCreatives];
                    let item = {...items[selected]};
                    item["imageUrl"] = ""
                    items[selected] = item;
                    
                    let new_errorImageUrl = this.state.errorImageUrl
                    new_errorImageUrl[selected] = "Must be minimum size of 300x300"
                    
                    this.setState({
                        errorImageUrl:new_errorImageUrl,
                        newCreatives:items,
                    })
                }
                else{
                    let new_errorImageUrl = this.state.errorImageUrl
                    new_errorImageUrl[selected] = ""

                    this.setState({
                        errorImageUrl:new_errorImageUrl
                    })
                    this.props.uploadFile(file, selected, field)
                }
            }
        }
        else if(field == "imageUrl" && newCreatives[0]?.type == "DISPLAY_BANNER"){
            //IF this is for imageURL of Display Banner campaign THEN make sure it is one of the supported one
            let img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.onload = () => {
                let imageSize = `${img.width}x${img.height}`
                let supportedSize = [
                    "300x250", 
                    "320x50", 
                    "300x50", 
                    "728x90", 
                    "336x280",
                    "300x600",
                    "120x600",
                    "160x600",
                    "320x100",
                    "468x60",
                    "300x1050",
                    "970x90",
                    "970x250",
                    "250x250",
                    "200x200",
                    "180x150",
                    "125x125",
                    "800x250",
                    "500x250",
                    "300x165",
                    "320x480"]
                if(!supportedSize.includes(imageSize)){
                    let items = [...newCreatives];
                    let item = {...items[selected]};
                    item["imageUrl"] = ""
                    items[selected] = item;

                    let new_errorImageUrl = this.state.errorImageUrl
                    new_errorImageUrl[selected] = `${imageSize} size is not supported`

                    this.setState({
                        errorImageUrl:new_errorImageUrl,
                        newCreatives:items,
                    })
                }
                else{
                    let new_errorImageUrl = this.state.errorImageUrl
                    new_errorImageUrl[selected] = ""

                    this.setState({
                        errorImageUrl:new_errorImageUrl
                    })
                    this.props.uploadFile(file, selected, field)
                }
            }
        }
        else if(field == "iconUrl" && newCreatives[0]?.type == "NATIVE"){
            let img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.onload = () => {
                //IF this is for iconUrl of Native campaign THEN make sure it is at least 128x128
                if(img.width < 128 || img.height < 128){
                    let items = [...newCreatives];
                    let item = {...items[selected]};
                    item["iconUrl"] = ""
                    items[selected] = item;

                    let new_errorIconUrl = this.state.errorIconUrl
                    new_errorIconUrl[selected] = "Must be minimum size of 128x128"

                    this.setState({
                        errorIconUrl:new_errorIconUrl,
                        newCreatives:items,
                    })
                }
                //IF this is for iconUrl of Native campaign THEN make sure it is square in ratio
                else if(img.width != img.height){
                    let items = [...newCreatives];
                    let item = {...items[selected]};
                    item["iconUrl"] = ""
                    items[selected] = item;

                    let new_errorIconUrl = this.state.errorIconUrl
                    new_errorIconUrl[selected] = "Image height and width must be equal"

                    this.setState({
                        errorIconUrl:new_errorIconUrl,
                        newCreatives:items,
                    })
                }
                else{

                    let new_errorIconUrl = this.state.errorIconUrl
                    new_errorIconUrl[selected] = ""

                    this.setState({
                        errorIconUrl:new_errorIconUrl
                    })
                    this.props.uploadFile(file, selected, field)
                }
            }
        }
        else{
            this.props.uploadFile(file, selected, field)
        }
    }

    handleCreateVideoAsset(event) {

        const { selected } = this.state
        const field = event.target.name
        const data = {
            // name: `video_${Math.floor(Date.now() / 1000)}.mp4`,
            name: `video_${this.props.user.id}_${Date.now()}.mp4`,
            accountId: this.props.user.accountId,
            upload: {
            "type": "DIRECT_UPLOAD"
            }
        }
        
        //Add the videoURL for submittion (note that it will only be used on creatives supernova.)
        const { newCreatives} = this.state
        let items = [...newCreatives];
        let item = {...items[selected]};
        item.videoUrl = `https://beta.supernova.inc/public/uploads/${data.name}`
        items[selected] = item;
        this.setState({
            newCreatives:items,
        });

        //Create new "videoAsset" to Upload in Zemanta
        this.props.createNewVideoAsset(event.target.files[0], selected, data, field)
        
        /************* 
        const { selected } = this.state
        const field = event.target.name
        const data = {
            name: `video_${Math.floor(Date.now() / 1000)}.mp4`,
            accountId: this.props.user.accountId,
            upload: {
            "type": "DIRECT_UPLOAD"
            }
        }

        var reader = new FileReader();
        reader.onloadend = () => {
            // console.log(reader.result) // this is the base64 encoded dataurl
            this.props.createNewVideoAsset(reader.result, data, field) 
        }
        reader.readAsDataURL(event.target.files[0]);
        **/

        // this.props.createNewVideoAsset(event.target.files[0], data)
    }

    handleChangePreview(e, val){
        e.preventDefault();
        this.setState({
            isPreviewOnDesktop:val
        })
    }

    handleSetKey(val){
        console.log(val)
        this.setState({
            key:val
        })
    }

    handleAddTracker(){
        event.stopPropagation();
        event.preventDefault();
        const { newCreatives, selected } = this.state;
        let items = [...newCreatives];
        let item = {...items[selected]};
        item.trackers.push({
            "eventType": "IMPRESSION",
            "method": "IMG",
            "url": config.testMode ? "https://example.com/t1" : "",
            "trackerOptional": true
        })
        items[selected] = item;
        this.setState({
            newCreatives:items,
        });
    }

    handleRemoveCreative(index, event) {
        event.stopPropagation();
        event.preventDefault();
        const { newCreatives } = this.state;
        var array = [...newCreatives]; 
        var index = index
        array.splice(index, 1);
        this.setState(prevState => ({
            ...prevState.selected,
            selected: 0
        }))
        this.setState({
            newCreatives: array
        });
    }

    handleAddNewCreative(event){
        const { newCreatives, selected} = this.state;
        this.setState({
            newCreatives: [
                ...newCreatives,
                {
                    state           : "INACTIVE",
                    title           : config.testMode ? `test ${Math.floor(Date.now() / 1000)}` : "Ad Name #1",
                    // imageUrl        : config.testMode ? "https://placeit-assets0.s3-accelerate.amazonaws.com/custom-pages/facebook-ad-templates/ad-banner-maker-for-a-black-friday-promo-746a-1903-300x300.png" : "",
                    imageUrl        : config.testMode ? "https://beta.supernova.inc/public/uploads/1683545347467-dafefd26137ade4d48bbda1afced7908e5.gif" : "",
                    callToAction    : "Read More",
                    imageCrop       : "CENTER",
                    brandName       : config.testMode ? "Test" : "",
                    description     : config.testMode ? "Test" : "",
                    adTagWidth      : config.testMode ? "970" : "",
                    adTagHeight     : config.testMode ? "90" : "",
                    iconUrl         : config.testMode ? "https://beta.supernova.inc/public/uploads/1655957954534-brand-logo-sample.jpg" : "",
                    url             : config.testMode ? "https://www.supernova.inc" : "",
                    type            : this.props.campaignType == "DISPLAY" ? "DISPLAY_BANNER" : this.props.campaignType, // Change this when the DISPLAY_AD_TAG is ready. In the meantime we are only accepting DISPLAY_BANNER creative where campaign type is "DISPLAY"
                    displayUrl      : config.testMode ? "www.supernova.inc" : "",
                    label           : "",
                    trackers        : [{
                        "eventType": "IMPRESSION",
                        "method": "IMG",
                        "url": `https://pixel.supernova.inc/i/${this.props.user.accountId}/0`,
                        "trackerOptional": true
                    }],
                    videoUploadType : "DIRECT_UPLOAD"
                }
            ],
            selected:newCreatives.length
        });
    }

    handleSelectCreative(index){
        this.setState({
            selected:(index)
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const { overlay, selectedAdgroup } = this.props;
        const { newCreatives, selected } = this.state;

        this.setState({
            submitted:true
        })

        newCreatives.map((creative, index) => {
                //Remove properties that is not for DISPLAY_AD_TAG:
                if(creative.type == "DISPLAY_AD_TAG"){
                    delete creative.imageCrop
                    delete creative.callToAction
                    delete creative.brandName
                    delete creative.description
                    delete creative.iconUrl
                }
        
                //Remove properties that is not for DISPLAY_BANNER:
                if(creative.type == "DISPLAY_BANNER"){
                    delete creative.adTagWidth
                    delete creative.adTagHeight
                    delete creative.adTag
                    
                    delete creative.imageCrop
                    delete creative.callToAction
                    delete creative.brandName
                    delete creative.description
                    delete creative.iconUrl
                }
                
                //Remove properties that is not for NATIVE:
                if(creative.type == "NATIVE"){
                    delete creative.adTagWidth
                    delete creative.adTagHeight
                    delete creative.adTag
                }
        
                //Remove trackers if its empty
                if(creative.trackers.length == 0){
                    delete creative.trackers //Temporary disabled to accomodate the supernova tracker
                }

                // Change the url to supernova for tracking
                // Note:    We only do this when editing because creative.id is not generated yet until the creative is inserted to db
                //          For the use case of creating a new creative we will do it in the API by updating the creative.url after the creative is inserted to the db. 
                if(overlay.purpose == "edit"){
                    creative.url = `https://pixel.supernova.inc/t/c/?aid=${this.props.user.accountId}&cid=${creative.id}&r=${creative.url}`
                    
                    
                }
        })

        //Form Validation
        if(overlay.purpose == "create" ){
            //Creating new creative
            console.log(selectedAdgroup)
            this.props.submitCreative(newCreatives, selectedAdgroup, this.props.user.accountId) //Tempoary comment
            this.props.closeOverlay()
            this.props.clearFileUploads()
        }
        else {
            //Editing a creative
            this.props.updateCreative(newCreatives[0])
            this.props.closeOverlay()
            this.props.clearFileUploads()
        }
    }

    /******************************
    * Trackers Events
    * ******************************/
    handleDropdownChangeTracker(name, index, dropdown) {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown.value
        
        const { newCreatives, selected } = this.state;
        let items = [...newCreatives];
        let item = {...items[selected]};
        item["trackers"][index][name] = value
        items[selected] = item;
        this.setState({newCreatives:items});
    }

    handleChangeTrackerMethod(index, value){
        const { newCreatives, selected } = this.state;
        let items = [...newCreatives];
        let item = {...items[selected]};
        console.log("index:" + index)
        console.log("value:" + value)
        item["trackers"][index].method = value
        items[selected] = item;
        this.setState({newCreatives:items});
    }

    handleChangeInputTextTracker(event, index) {
        const { name, value } = event.target;
        const { newCreatives, selected } = this.state;
        let items = [...newCreatives];
        let item = {...items[selected]};
        item["trackers"][index][name] = value

        items[selected] = item;
        this.setState({
            newCreatives:items,
        });
    }

    render(){
        try{
            const html = `
    <div>This wil be rendered</div>
    <script>
      alert('testing')
    </script>

  `

            const { overlay, creatives } = this.props
            const { isNameFieldVisible, newCreatives, selected, submitted, isPreviewOnDesktop, key} = this.state
            const title = overlay.purpose == "create" ? "Batch Upload" : `Creative ID: ${this.props.selectedCreative}`

            // The following are required IF its {Display Banner}, {Native} or {Video}
            // - title
            // - url
            // - displayUrl
            // 
            // The following are required IF its {Native} campaign
            // - brandName
            // - description
            // - callToAction
            //
            // The following are required IF its {Display} AdTag
            // - adTag
            // - adTagHeight
            // - adTagWidth
            //
            // The following are required IF its {Video}
            // - Video Asset Creation 
            // - Video Processing
            //

            // Bug
            // let isValidForm = newCreatives.filter(item => 
            //     ((item.type == "NATIVE" || item.type == "DISPLAY_BANNER" || item.type == "VIDEO") && (item.title == "" || item.url == "" || item.displayUrl == "" || item.imageUrl == "")) ||
            //     (item.type == "NATIVE" && (item.brandName == "" || item.description == "" || item.callToAction == "")) || 
            //     (item.type == "DISPLAY_AD_TAG" && (!item.adTag || item.adTagHeight == "" || item.adTagWidth == "")) ||
            //     (item.type == "VIDEO" && (creatives.file && creatives.file[selected] && Object.hasOwn(creatives.file[selected], "videoAssetId") && creatives.file[selected]?.videoAssetId === undefined))
            // ).length > 0 ? false : true

            // Solution (For Testing)
            let isValidForm = newCreatives.filter(item => 
                item && // Ensure item is not null or undefined
                (
                    ((item.type == "NATIVE" || item.type == "DISPLAY_BANNER" || item.type == "VIDEO") &&
                    (item.title == "" || item.url == "" || item.displayUrl == "" || item.imageUrl == "")) ||
                    (item.type == "NATIVE" && (item.brandName == "" || item.description == "" || item.callToAction == "")) || 
                    (item.type == "DISPLAY_AD_TAG" && (!item.adTag || item.adTagHeight == "" || item.adTagWidth == "")) ||
                    (item.type == "VIDEO" && (creatives.file && creatives.file[selected] && Object.hasOwn(creatives.file[selected], "videoAssetId") && creatives.file[selected]?.videoAssetId === undefined))
                )
            ).length > 0 ? false : true;
            
    
    
            // console.log(
            //     newCreatives[selected]?.trackers
            // )
    
            return(
                    <div className={`window creative-form ${overlay.purpose == "create" ? "wide" : "dual-width"}`}> 
                        <form name="form" onSubmit={this.handleSubmit}>
                            <section className="title main">
                                {title}
                            </section>
                            {/* <section className={`body ${overlay.purpose == "create" ? "tri-width" : "dual-width"}`}> */}
                            <section className={`body ${(overlay.purpose == "create" && newCreatives[selected]?.type != "VIDEO2") ? "tri-width" : "dual-width"}`}>
                                {
                                    // overlay.purpose == "create" && 
                                    (overlay.purpose == "create" && newCreatives[selected]?.type != "VIDEO2") && 
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                {
                                                    overlay.purpose == "create" &&
                                                    <>
                                                        <th></th>
                                                        <th></th>
                                                    </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                newCreatives.map((item, index) => 
                                                    <tr key={index} className={`clickable ${index == selected ? "selected" : ""}`} onClick={() => this.handleSelectCreative(index)}>
                                                        <td className="preview">
                                                            {
                                                                newCreatives[index]?.imageUrl == "" ? <i className="fa fa-file-image-o" aria-hidden="true"></i> : <img src={newCreatives[index]?.imageUrl} alt="" width="25" height="25"/>
                                                            }
                                                        </td>
                                                        {
                                                            overlay.purpose == "create" &&
                                                            <td className={`status ${creatives.progress < 100 ? "in-progress" : "done"}`}>
                                                                {
                                                                    creatives.progress < 100 ? creatives.progress : ""
                                                                }
                                                            </td>
    
                                                        }
                                                        <td className={`name ${creatives.progress !== undefined ? "submitted": ""}`}>
                                                            {item.title}
                                                        </td>
                                                        {
                                                            overlay.purpose == "create" &&
                                                            <td className="controls">
                                                                <button className={`btn btn-primary btn-table ${newCreatives.length == 1 || creatives.progress !== undefined ? "disable" : ""}`} onClick={(e) => this.handleRemoveCreative(index, e)}><i className="fa fa-times" aria-hidden="true"></i> <span>Remove</span></button>
                                                            </td>
    
                                                        }
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                        {
                                            overlay.purpose == "create" &&
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="3">
                                                        <button type="button" className="btn btn-primary btn-xs btn-more" onClick={this.handleAddNewCreative}>
                                                            <i className="fa fa-plus-circle"></i> Add More
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tfoot>
    
                                        }
                                    </table>
                                }
                                <div className="form-panel">
                                    <div className="fields">
                                        <div className="title">{overlay.purpose == "edit" ? "Edit" : "Add New"} Creative</div>
                                        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => this.handleSetKey(k)} className="mb-3">
                                            <Tab eventKey="info" title="Info">
                                                {
                                                    // Disabled until DISPLAY_AD_TAG is ready (for future release).
                                                    // In the meantime, use the following as default based on the campaign type
                                                    //   IF campaign type is "VIDEO" THEN  the creative type should be "VIDEO"
                                                    //   IF campaign type is "NATIVE" THEN  the creative type should be "NATIVE"
                                                    //   IF campaign type is "DISPLAY" THEN  the creative type should be "DISPLAY_BANNER"
                                                    // This is set as the initial value above
                                                    //
                                                    overlay.purpose == "create" && (newCreatives[selected]?.type == "DISPLAY_BANNER" || newCreatives[selected]?.type == "DISPLAY_AD_TAG") && 
                                                    <div className={'form-group'}>
                                                        <label>Ad Type</label>
                                                        <div className="content">
                                                            {
                                                                <Select
                                                                    className="select-primary green"
                                                                    onChange={this.handleDropdownChange.bind(this, "type")}
                                                                    options={
                                                                        [
                                                                            {value:'DISPLAY_BANNER', label:'Image'},
                                                                            {value:'DISPLAY_AD_TAG', label:'Ad Tag'},
                                                                        ]
                                                                    }
                                                                    defaultValue={{value:'DISPLAY_BANNER', label:'Image'}}
                                                                    styles={styleSelectCreativeForm} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className={'form-group'}>
                                                    <label>{newCreatives[selected] && newCreatives[selected]?.type == "NATIVE" ? "Ad Title" : "Ad Name"}</label>

                                                    <div className="content">
                                                        <input type="text" className="form-control" name="title" value={newCreatives[selected]?.title == undefined ? "" : newCreatives[selected]?.title} onChange={this.handleChangeInputText} disabled={submitted || overlay.purpose == "edit"}/>
                                                    </div>
                                                </div>
                                                {/* <div className={'form-group'}>
                                                    <label>Marketing Funnel</label>
                                                    <div className="content">
                                                        <Select
                                                            className="select-primary green"
                                                            onChange={this.handleDropdownChange.bind(this, "marketingFunnel")}
                                                            options={[
                                                                {label:"Awareness",     value:"Awareness"},
                                                                {label:"Consideration", value:"Consideration"},
                                                                {label:"Purchase",      value:"Purchase"},
                                                                {label:"Loyalty",       value:"Loyalty"},
                                                            ]}
                                                            styles={styleSelectCreativeForm} />
                                                    </div>
                                                </div> */}
                                                <div className={'form-group'}>
                                                    <label>Click URL</label>
                                                    <div className="content">
                                                        <input type="text" className="form-control" name="url" value={newCreatives[selected]?.url == undefined ? "" : newCreatives[selected]?.url} onChange={this.handleChangeInputText}/>
                                                    </div>
                                                </div>
                                                {
                                                    overlay.purpose == "create" && (newCreatives[selected]?.type == "NATIVE" || newCreatives[selected]?.type == "DISPLAY_BANNER" || newCreatives[selected]?.type == "VIDEO") && 
                                                    <div className={'form-group'}>
                                                        <label className="has-description">Image 
                                                            {
                                                                newCreatives[0]?.type == "NATIVE" && 
                                                                <>
                                                                    <br/>
                                                                    <small>
                                                                        - Minimum: 300x300
                                                                    </small>
                                                                </>
                                                            }
                                                            {
                                                                newCreatives[0]?.type == "DISPLAY_BANNER" &&
                                                                <>
                                                                    <small>
                                                                        <br/>
                                                                        Check supported
                                                                        <OverlayTrigger placement="right" overlay={<Tooltip>
                                                                            <strong>Supported Sizes</strong><br/>
                                                                            125x125<br/>
                                                                            160x600<br/>
                                                                            180x150<br/>
                                                                            200x200<br/>
                                                                            250x250<br/>
                                                                            300x1050<br/>
                                                                            300x165<br/>
                                                                            300x250<br/>
                                                                            300x50<br/>
                                                                            300x600<br/>
                                                                            320x100<br/>
                                                                            320x480<br/>
                                                                            320x50<br/>
                                                                            336x280<br/>
                                                                            468x60<br/>
                                                                            500x250<br/>
                                                                            728x90<br/>
                                                                            800x250<br/>
                                                                            970x250<br/>
                                                                            970x90<br/>
                                                                            </Tooltip>}>
                                                                            <span className='hover-me'>
                                                                                sizes 
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </small>
                                                                </>
                                                            }
                                                        </label>
                                                        <div className="content">
                                                            <input 
                                                                ref="file" 
                                                                type="file" 
                                                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                                                name="imageUrl"
                                                                multiple={false}
                                                                value=""
                                                                onChange={this.handleFileUpload }
                                                                id="field_upload_imageUrl"
                                                                className='d-none'
                                                            />
                                                            <label className={`btn btn-primary btn-xs`} htmlFor="field_upload_imageUrl">
                                                                <i className="fa fa-cloud-upload"></i> 
                                                                {
                                                                    (newCreatives[selected]?.imageUrl != "" && !this.state.errorImageUrl[selected]) ? <> Change file</> :<> Choose file</>
                                                                }
                                                            </label>
                                                            {/* <span>Or Through URL:</span> */}
                                                            {/* <input type="text" className="form-control" name="imageUrl" value={newCreatives[selected]?.imageUrl == undefined ? "" : newCreatives[selected]?.imageUrl} onChange={this.handleChangeInputText }  disabled={submitted || overlay.purpose == "edit"}/> */}
                                                            <div className={`progress-wrapper ${(creatives.imageUrlUploadProgress && newCreatives[selected]?.imageUrl != "") ? "" : "d-none"}`}>
                                                                {
                                                                    creatives.imageUrlUploadProgress == 100 ?
                                                                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                                                                    :
                                                                    <div className="progress-wrapper">
                                                                        <div className="progress-bar-upload">
                                                                            <div className="fill" style={{width:`${creatives.imageUrlUploadProgress}%`}}></div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='error-field'>{this.state.errorImageUrl[selected]}</div>
                                                    </div>
                                                }
                                                {
                                                    newCreatives[selected]?.type == "DISPLAY_AD_TAG" && 
                                                    <div className={'form-group'}>
                                                        <label>Ad Size</label>
                                                        <div className="content">
                                                            <Select
                                                                className="select-primary green"
                                                                onChange={this.handleDropdownChange.bind(this, "adTagSize")}
                                                                options={
                                                                    [
                                                                        {value:'320x50', label:'320x50'},
                                                                        {value:'300x50', label:'300x50'},
                                                                        {value:'300x250', label:'300x250'},
                                                                        {value:'728x90', label:'728x90'},
                                                                        {value:'336x280', label:'336x280'},
                                                                        {value:'300x600', label:'300x600'},
                                                                        {value:'120x600', label:'120x600'},
                                                                        {value:'160x600', label:'160x600'},
                                                                        {value:'320x100', label:'320x100'},
                                                                        {value:'468x60', label:'468x60'},
                                                                        {value:'300x1050', label:'300x1050'},
                                                                        {value:'970x90', label:'970x90'},
                                                                        {value:'970x250', label:'970x250'},
                                                                        {value:'250x250', label:'250x250'},
                                                                        {value:'200x200', label:'200x200'},
                                                                        {value:'180x150', label:'180x150'},
                                                                        {value:'125x125', label:'125x125'},
                                                                        {value:'800x250', label:'800x250'},
                                                                        {value:'500x250', label:'500x250'},
                                                                        {value:'300x165', label:'300x165'},
                                                                        {value:'320x480', label:'320x480'}
                                                                    ]
                                                                }
                                                                defaultValue={
                                                                    overlay.purpose == "edit" &&
                                                                    {
                                                                        value:`${newCreatives[selected]?.adTagWidth}x${newCreatives[selected]?.adTagHeight}`, 
                                                                        label:`${newCreatives[selected]?.adTagWidth}x${newCreatives[selected]?.adTagHeight}`, 
                                                                    }
                                                                }
                                                                styles={styleSelectCreativeForm} />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    newCreatives[selected]?.type == "DISPLAY_AD_TAG" && 
                                                    newCreatives[selected]?.adTagHeight !== undefined && 
                                                    newCreatives[selected]?.adTagWidth !== undefined && 
                                                    newCreatives[selected]?.adTagHeight != "" && 
                                                    newCreatives[selected]?.adTagWidth != "" && 
                                                    <div className={'form-group'}>
                                                        <label>Ad Tag</label>
                                                        <div className="content">
                                                            <textarea type="text" name="adTag" className="form-control" value={newCreatives[selected]?.adTag == undefined ? "" : newCreatives[selected]?.adTag} onChange={this.handleChangeInputText }>
                                                                    {newCreatives[selected]?.adTag}
                                                            </textarea>
                                                        </div>
                                                    </div>
    
                                                }
                                                <div className={'form-group d-none'}>
                                                    <label>Image Crop</label>
                                                    <div className="content">
                                                        {/* <input type="text" className="form-control" name="url" value={newCreatives[selected]?.imageCrop == undefined ? "" : newCreatives[selected]?.imageCrop} onChange={this.handleChangeInputText} disabled={submitted || overlay.purpose == "edit"}/> */}
                                                        <Select
                                                            className="select-primary green"
                                                            onChange={this.handleDropdownChange.bind(this, "imageCrop")}
                                                            options={listImageCrop}
                                                            defaultValue={{value:'CENTER', label:'Center'}}
                                                            styles={styleSelectCreativeForm} />
                                                    </div>
                                                </div>
                                                {
                                                    overlay.purpose == "create" && (newCreatives[selected]?.type == "NATIVE") && 
                                                    <div className={'form-group'}>
                                                        <label className="has-description">Brand Logo<br/><small>- Minimum: 128x128<br/>- Same width &amp; height</small></label>
                                                        <div className="content">
                                                                <input 
                                                                    ref="file" 
                                                                    type="file" 
                                                                    accept="image/png, image/gif, image/jpeg, image/jpg"
                                                                    name="iconUrl"
                                                                    multiple={false}
                                                                    value=""
                                                                    onChange={this.handleFileUpload }
                                                                    id="field_upload_iconUrl"
                                                                    className='d-none'
                                                                />
                                                                <label className={`btn btn-primary btn-xs`} htmlFor="field_upload_iconUrl">
                                                                    <i className="fa fa-cloud-upload"></i> 
                                                                    {
                                                                        (newCreatives[selected]?.iconUrl != "" && !this.state.errorIconUrl[selected]) ? <> Change file</> :<> Choose file</>
                                                                    }
                                                                </label>
                                                                {/* <input type="text" className="form-control" name="iconUrl" value={newCreatives[selected]?.iconUrl == undefined ? "" : newCreatives[selected]?.iconUrl} onChange={this.handleChangeInputText }  disabled={submitted || overlay.purpose == "edit"}/> */}
                                                                {/* {newCreatives[selected]?.iconUrl != undefined && newCreatives[selected]?.iconUrl != "" && <img src={newCreatives[selected]?.iconUrl} alt="" className="brand-logo" width="25" height="25"/>} */}
                                                            <div className={`progress-wrapper ${(creatives.iconUrlUploadProgress && newCreatives[selected]?.iconUrl != "") ? "" : "d-none"}`}>
                                                                {
                                                                    creatives.iconUrlUploadProgress == 100 ?
                                                                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                                                                    :
                                                                    <div className="progress-wrapper">
                                                                        <div className="progress-bar-upload">
                                                                            <div className="fill" style={{width:`${creatives.iconUrlUploadProgress}%`}}></div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='error-field'>{this.state.errorIconUrl[selected]}</div>
                                                    </div>
                                                }
                                                {
                                                    overlay.purpose == "create" && (newCreatives[selected]?.type == "VIDEO") && 
                                                    <div className={'d-none form-group'}>
                                                        <label>Upload by</label>
                                                        <div className="content">
                                                        <Select
                                                                className="select-primary green"
                                                                onChange={this.handleDropdownChange.bind(this, "videoUploadType")}
                                                                options={[
                                                                    {value:"DIRECT_UPLOAD", label:"Video file"},
                                                                    {value:"VAST_UPLOAD", label:"VAST XML file"},
                                                                    {value:"VAST_URL", label:"Vast tag"}
                                                                ]}
                                                                defaultValue={{value:'DIRECT_UPLOAD', label:'Video file'}}
                                                                styles={styleSelectCreativeForm} />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    overlay.purpose == "create" && (newCreatives[selected]?.type == "VIDEO") && 
                                                    <div className={'form-group'}>
                                                        <label>
                                                            {
                                                                newCreatives[selected]?.videoUploadType == "DIRECT_UPLOAD" ? "Video" :
                                                                newCreatives[selected]?.videoUploadType == "VAST_UPLOAD" ? "VAST" :
                                                                newCreatives[selected]?.videoUploadType == "VAST_URL" ? "VAST tag URL" : ""
                                                            }
                                                        </label>
                                                        <div className="content">
                                                            {
                                                                newCreatives[selected]?.videoUploadType == "DIRECT_UPLOAD" &&
                                                                <>
                                                                    <input 
                                                                        ref="file" 
                                                                        type="file" 
                                                                        accept="video/mp4"
                                                                        name="videoAssetId"
                                                                        multiple={false}
                                                                        value=""
                                                                        onChange={this.handleCreateVideoAsset }
                                                                        id="field_upload_videoFile"
                                                                        className={`d-none`}
                                                                    />
                                                                    <label className={`btn btn-primary btn-xs ${
                                                                            Object.hasOwn(creatives, "isVideoProcessing") && 
                                                                            creatives.isVideoProcessing ? "disabled" : ""}`
                                                                        } htmlFor="field_upload_videoFile">
                                                                        <i className="fa fa-cloud-upload"></i> 
                                                                        {
                                                                            (newCreatives[selected]?.videoAssetId && newCreatives[selected]?.videoAssetId != "") ? <> Change file</> :<> Choose file</>
                                                                        }
                                                                    </label>
                                                                        <div className={`progress-wrapper ${creatives.file && creatives.file[selected] && Object.hasOwn(creatives.file[selected], "videoAssetIdUploadProgress") && creatives.file[selected]?.videoAssetIdUploadProgress !== undefined ? "" : "d-none"}`}>
                                                                            {
                                                                                (creatives.file && creatives.file[selected] && Object.hasOwn(creatives.file[selected], "videoAssetIdUploadProgress") && creatives.file[selected]?.videoAssetIdUploadProgress == 100 && creatives.file[selected]?.videoAssetId !== undefined)?
                                                                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                                                                :
                                                                                <div className="progress-bar-upload">
                                                                                    {/* Note We added an allowance in the preloader to give way for video processing after video upload is complete */}
                                                                                    <div className="fill" style={{width:`${(creatives.file && creatives.file[selected] && Object.hasOwn(creatives.file[selected], "videoAssetIdUploadProgress") && creatives.file[selected]?.videoAssetIdUploadProgress / 110) * 100 }%`}}></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                </>
                                                            }
                                                            {
                                                                newCreatives[selected]?.videoUploadType == "VAST_UPLOAD" &&
                                                                <>
                                                                    <input 
                                                                        ref="file" 
                                                                        type="file" 
                                                                        accept="text/xml"
                                                                        name="videoAssetId"
                                                                        multiple={false}
                                                                        value=""
                                                                        onChange={this.handleFileUpload }
                                                                    />
                                                                        <div className={`progress-wrapper ${creatives.videoFileUploadProgress ? "" : "d-none"}`}>
                                                                            {
                                                                                creatives.videoFileUploadProgress == 100 ?
                                                                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                                                                :
                                                                                <div className="progress-bar-upload">
                                                                                    <div className="fill" style={{width:`${creatives.videoFileUploadProgress}%`}}></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                </>
                                                            }
                                                            {
                                                                newCreatives[selected]?.videoUploadType == "VAST_URL" &&
                                                                <input type="text" className="form-control" name="vastUrl" value={newCreatives[selected]?.vastUrl == undefined ? "" : newCreatives[selected]?.vastUrl} onChange={this.handleChangeInputText}/>
                                                            }
                                                        </div>
                                                    </div>
                                                }
    
                                                    <div className={'form-group'}>
                                                        <label>Advertiser URL</label>
                                                        <div className="content">
                                                            <input type="text" maxLength="35" className="form-control" name="displayUrl" value={newCreatives[selected]?.displayUrl == undefined ? "" : newCreatives[selected]?.displayUrl} onChange={this.handleChangeInputText }/>
                                                        </div>
                                                    </div>
                                                    {/********************
                                                     * SENSITIVE CATEGORY
                                                     *********************/}
                                                    <div className={'form-group'} style={{display:"none"}}>
                                                        <label>Sensitive Category</label>
                                                        <div className="content">
                                                        <Select
                                                            className="select-primary"
                                                            onChange={this.handleDropdownChange.bind(this, "iabCategory")}
                                                            // defaultValue={
                                                            //     {
                                                            //         label: campaignCategory,
                                                            //         value: campaign.iabCategory,
                                                            //     }
                                                            // }
                                                            options={listIABCategory}
                                                            styles={styleSelectCreativeForm} />
                                                        </div>
                                                    </div>
                                                    {/********************
                                                     * CREATIVE ATTRIBUTES
                                                     *********************/}
                                                    <div className={'form-group creative-attr'} style={{display:"none"}}>
                                                        <label>Creative Attributes</label>
                                                        <div className="content">
                                                        {/* <Select
                                                            className="select-primary"
                                                            onChange={this.handleDropdownChange.bind(this, "creativeAttributes")}
                                                            // defaultValue={
                                                            //     {
                                                            //         label: campaignCategory,
                                                            //         value: campaign.iabCategory,
                                                            //     }
                                                            // }
                                                            options={listCretaiveAttributes}
                                                            styles={styleSelectCreativeForm} /> */}
                                                            {
                                                                listCreativeAttributes.map((item, index) => 
                                                                    <label key={index}><input type="checkbox" name="" data-catt={item.value}/><span>{item.label}</span></label>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                {
                                                    (newCreatives[selected]?.type == "NATIVE" || newCreatives[selected]?.type == "VIDEO") && 
                                                    <div className={'form-group'}>
                                                        <label>Description</label>
                                                        <div className="content">
                                                            <textarea type="text" className="form-control" value={newCreatives[selected]?.description == undefined ? "" : newCreatives[selected]?.description} name="description" onChange={this.handleChangeInputText }>
                                                                {newCreatives[selected]?.description == undefined ? "" : newCreatives[selected]?.description}
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (newCreatives[selected]?.type == "NATIVE" || newCreatives[selected]?.type == "VIDEO") && 
                                                    <div className={'form-group'}>
                                                        <label>Brand name</label>
                                                        <div className="content">
                                                            <input type="text" className="form-control" name="brandName" value={newCreatives[selected]?.brandName == undefined ? "" : newCreatives[selected]?.brandName} onChange={this.handleChangeInputText }/>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (newCreatives[selected]?.type == "NATIVE" || newCreatives[selected]?.type == "VIDEO") && 
                                                    <div className={'form-group'}>
                                                        <label>Call to action</label>
                                                        <div className="content">
                                                            <input type="text" className="form-control" name="callToAction" value={newCreatives[selected]?.callToAction == undefined ? "" : newCreatives[selected]?.callToAction} onChange={this.handleChangeInputText }/>
                                                        </div>
                                                    </div>
                                                }
    
                                                {/* DISABLED TAGS BECAUSE WE WILL HAVE OUR OWN TAGGING SYSTEM */}
                                                {/* <div className={'form-group'}>
                                                    <label>Tags</label>
                                                    <div className="content">
                                                        <Select
                                                            // onChange={(event) => {setHasChanges(true); setEmployment(handleDropdownChange(event))}}
                                                            className="select-primary multi"
                                                            defaultValue={
                                                                Array.isArray(newCreatives[selected]?.trackers) && 
                                                                newCreatives[selected]?.tags.map((selected_item) => {
                                                                    return({
                                                                        label: selected_item,
                                                                        value: selected_item
                                                                    })
                                                                })
                                                            }
                                                            isMulti
                                                            options={
                                                                Array.isArray(newCreatives[selected]?.trackers) && 
                                                                newCreatives[selected]?.tags.map((selected_item) => {
                                                                    return({
                                                                        label: selected_item,
                                                                        value: selected_item
                                                                    })
                                                                })
                                                            }
                                                            multi={true}
                                                            isClearable={false}
                                                            styles={styleSelectCreativeForm} />
                                                    </div>
                                                </div> */}
    
                                                <div className={'form-group'}>
                                                    <label>Trackers</label>
                                                    <div className="tracker-wrapper">
                                                        {
                                                            newCreatives[selected]?.trackers && 
                                                            newCreatives[selected]?.trackers.length > 1 && // Note: creative.trackers[0] is reserved for supernova
                                                            newCreatives[selected]?.trackers.map((item, index) => 
                                                                <section className={item.url.startsWith("https://pixel.supernova.inc") ? "disabled" : ""} key={index}>
                                                                    
                                                                    {/* [SUP-289] Hide these 2 tabs for now. */}
                                                                    <button type="button" className={`btn btn-primary btn-xs btn-tracker d-none tab ${item.method == "JS" ? "active": ""}`} onClick={() => this.handleChangeTrackerMethod(index, "JS")}>Javascript Tag</button>
                                                                    <button type="button" className={`btn btn-primary btn-xs btn-tracker d-none tab ${item.method == "IMG" ? "active": ""}`} onClick={() => this.handleChangeTrackerMethod(index, "IMG")}>Image Pixel</button>
    
                                                                    <div className="tracker-fields">
                                                                    <Select
                                                                        className="select-primary green"
                                                                        onChange={this.handleDropdownChangeTracker.bind(this, "eventType", index)}
                                                                        options={[
                                                                            {value:'IMPRESSION', label:'Impression'},
                                                                            {value:'VIEWABILITY', label:'Viewability'}
                                                                        ]}
                                                                        defaultValue={{value:'IMPRESSION', label:'Impression'}}
                                                                        styles={styleSelectCreativeForm} />
                                                                        <textarea type="text" name="url" className="form-control" placeholder="Paste your tracker URL here" value={item.url == undefined ? "" : item.url} onChange={(e) => this.handleChangeInputTextTracker(e, index) }>
                                                                            {item.url == undefined ? "" : item.url}
                                                                        </textarea>
                                                                        <p>
                                                                            Please note that only vendors registered on the IAB Global Vendor List (GVL) and complying with the IAB Europe Transparency &amp; Consent Framework (TCF) are approved for any activity in the EU and in the UK across the Supernova network.
                                                                        </p>
                                                                        {
                                                                            item.method == "JS" && 
                                                                            <input type="text" className="mt-3 mb-3 form-control" placeholder="Paste your image pixel tracker URL here" name="fallbackUrl" value={item.fallbackUrl == undefined ? "" : item.fallbackUrl} onChange={(e) => this.handleChangeInputTextTracker(e, index) }/>
                                                                        }
    
                                                                        {/* [SUP-286] Hide this option for now. */}
                                                                        <div className="radio full-width d-none" onChange={(e) => this.handleChangeInputTextTracker(e, index)}>
                                                                            <label>
                                                                                <input type="checkbox" value={true} name="trackerOptional" defaultChecked={item.trackerOptional} />
                                                                                <span className="tracker-description">Allow Supernova to purchase traffic where 3rd party tracking is not supported.</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            )
                                                        }
                                                        {
                                                            // "Z" only allows three. So the maximum allowed for our user is 2 Trackers (the 3rd one is supernova)
                                                            newCreatives[selected]?.trackers && newCreatives[selected]?.trackers.length >= 2 ?
                                                            <span className="max-notification">You have reached the maximum number of tracking tags.</span>
                                                            :
                                                            <button type="button" className="btn btn-primary btn-xs btn-tracker" onClick={this.handleAddTracker}>
                                                                <i className="fa fa-plus-circle"></i> Add Trackers
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </Tab>
                                            {/* <Tab eventKey="reviews" title="Reviews">
                                                <div className='reviews'>
                                                    Not Available...
                                                </div>
                                            </Tab> */}
                                        </Tabs>
                                        
                                    </div>
                                    <div className="preview">
                                        <div className="title">
                                            Preview ad
                                            {
                                                // overlay.purpose == "edit" &&
                                                newCreatives[selected]?.type == "NATIVE" &&
                                                <div className="preview-button-placeholder">
                                                    <button className={`${isPreviewOnDesktop ? "active":""}`} onClick={(e) => this.handleChangePreview(e, true)}>
                                                        <i className="fa fa-desktop" aria-hidden="true"></i>
                                                        <span>Desktop</span>
                                                    </button>
                                                    <button className={`${isPreviewOnDesktop ? "":"active"}`} onClick={(e) => this.handleChangePreview(e, false)}>
                                                        <i className="fa fa-mobile" aria-hidden="true"></i>
                                                        <span>Mobile</span>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                        <div className="canvas">
                                            {
                                                (newCreatives[selected]?.type == "NATIVE" && isPreviewOnDesktop) ?
                                                <Carousel interval={null}>
    
                                                    {/* Native Ad 1 */}
                                                    <Carousel.Item>
                                                        <Carousel.Caption>Square ad with logo</Carousel.Caption>
                                                        <div className='native-ad-wrapper'>
                                                            <div className="native-ad-2">
                                                                <div className="header">
                                                                    <div className="icon-placeholder">
                                                                        <img
                                                                        className="d-block w-100"
                                                                        src={newCreatives[selected]?.iconUrl.replace(/\?w=.*.&h/, `?w=300&h=300&o`)}
                                                                        alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="brand-name">{newCreatives[selected]?.brandName}</div>
                                                                </div>
                                                                <div className="image-placeholder">
                                                                    <img
                                                                    className="d-block w-100"
                                                                    src={newCreatives[selected]?.imageUrl.replace(/\?w=.*.&h/, `?w=${calculateRatio(newCreatives[selected]?.imageWidth, newCreatives[selected]?.imageHeight, 380, 280).width}&h=${calculateRatio(newCreatives[selected]?.imageWidth, newCreatives[selected]?.imageHeight, 380, 280).height}&o`)}
                                                                    alt=""
                                                                    />
                                                                </div>
                                                                <div className="preview-title">{newCreatives[selected]?.title}</div>
                                                                <div className="preview-description">{newCreatives[selected]?.description}</div>
                                                                <div className="brand-name">{newCreatives[selected]?.displayUrl}</div>
                                                            </div>
                                                        </div>
                                                    </Carousel.Item>
    
                                                    {/* Native Ad 2 */}
                                                    <Carousel.Item>
                                                        <Carousel.Caption>Square ad without description</Carousel.Caption>
                                                        <div className='native-ad-wrapper'>
                                                            <div className="native-ad-1">
                                                                <div className="image-placeholder">
                                                                    <img
                                                                    className="d-block w-100"
                                                                    src={newCreatives[selected]?.imageUrl.replace(/\?w=.*.&h/, `?w=${calculateRatio(newCreatives[selected]?.imageWidth, newCreatives[selected]?.imageHeight, 380, 280).width}&h=${calculateRatio(newCreatives[selected]?.imageWidth, newCreatives[selected]?.imageHeight, 380, 280).height}&o`)}
                                                                    alt=""
                                                                    />
                                                                </div>
                                                                <div className="preview-title">{newCreatives[selected]?.title}</div>
                                                                <div className="brand-name">{newCreatives[selected]?.brandName}</div>
                                                            </div>
                                                        </div>
                                                    </Carousel.Item>
    
                                                    {/* Native Ad 3 */}
                                                    <Carousel.Item>
                                                        <Carousel.Caption>Horizontal ad</Carousel.Caption>
                                                        <div className='native-ad-wrapper'>
                                                            <div className="native-ad-3">
                                                                <div className="image-placeholder">
                                                                    <img
                                                                    className="d-block w-100"
                                                                    src={newCreatives[selected]?.imageUrl.replace(/\?w=.*.&h/, `?w=300&h=300&o`)}
                                                                    alt=""
                                                                    />
                                                                </div>
                                                                <div className="text-placeholder">
                                                                    <div className="preview-title">{newCreatives[selected]?.title}</div>
                                                                    <div className="preview-description">{newCreatives[selected]?.description}</div>
                                                                    <div className="brand-name">{newCreatives[selected]?.brandName}</div>
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </Carousel.Item>
    
    
                                                    {/* Native Ad 4 */}
                                                    <Carousel.Item>
                                                        <Carousel.Caption>Horizontal ad with logo</Carousel.Caption>
                                                        <div className='native-ad-wrapper'>
                                                            <div className="native-ad-4">
                                                                <div className='image-placeholder'>
                                                                    <img
                                                                    className="d-block w-100"
                                                                    src={newCreatives[selected]?.iconUrl.replace(/\?w=.*.&h/, `?w=300&h=300&o`)}
                                                                    alt=""
                                                                    />
                                                                </div>
                                                                <div className="text-placeholder">
                                                                    <div className="preview-title">{newCreatives[selected]?.title}</div>
                                                                    <div className="brand-name">{newCreatives[selected]?.brandName}</div>
                                                                </div>
                                                                <div className="button-placeholder">
                                                                    <button>{newCreatives[selected]?.callToAction}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Carousel.Item>
                                                </Carousel>
                                                : (newCreatives[selected]?.type == "NATIVE" && !isPreviewOnDesktop) ?
                                                <div className="mobile-placeholder">
                                                    <div className="mobile">
                                                        <div className="mobile-content">
                                                            <div className="mobile-content-item">
                                                                <img src="/public/images/empty-content-news.png" alt="" width="268" height="125"/>
                                                            </div>
                                                            <div className="mobile-native">
                                                                <div className="placeholder">
                                                                    <img src={newCreatives[selected]?.imageUrl.replace(/\?w=.*.&h/, `?w=720&h=450&o`)} alt="" width="246" height="154"/>
                                                                </div>
                                                                <div className="preview-title">{newCreatives[selected]?.title}</div>
                                                                <div className="brand-name">{newCreatives[selected]?.brandName}</div>
                                                            </div>
                                                            <div className="mobile-content-item">
                                                                <img src="/public/images/empty-content-blog.png" alt="" width="268" height="135"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : (newCreatives[selected]?.type == "VIDEO") ?
                                                        (newCreatives[selected]?.videoAssetId && newCreatives[selected]?.videoAssetId != "" && newCreatives[selected]?.imageUrl != "") ?
                                                            <div className="video-preview">
                                                                <video controls poster={newCreatives[selected]?.imageUrl}>
                                                                    <source src={`https://videos.zemanta.com/${newCreatives[selected]?.videoAssetId}_720p.mp4`} type="video/mp4"/>
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </div>
                                                        : <>{newCreatives[selected]?.imageUrl == "" ? "Please upload an image cover" : "Please upload a video"}</>
                                                : (newCreatives[selected]?.type == "DISPLAY_AD_TAG" && newCreatives[selected]?.adTagWidth && newCreatives[selected]?.adTagHeight) ?
                                                    <div className="adtag-preview" style={{width:newCreatives[selected]?.adTagWidth+"px", height:newCreatives[selected]?.adTagHeight+"px", overflow:"hidden"}}>
                                                        <InnerHTML html={newCreatives[selected]?.adTag} />
                                                    </div>
                                                :
                                                newCreatives[selected]?.imageUrl !== undefined && 
                                                <img src={newCreatives[selected]?.imageUrl.replace(/\?w=.*.&h/, `?w=${calculateRatio(newCreatives[selected]?.imageWidth, newCreatives[selected]?.imageHeight, 380, 280).width}&h=${calculateRatio(newCreatives[selected]?.imageWidth, newCreatives[selected]?.imageHeight, 380, 280).height}&o`)} alt=""/>
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                            </section>
                            <section className="footer">
                                <button className="btn btn-primary" disabled={submitted || !isValidForm} type="submit" onClick={this.handleSubmit}><i className="fa fa-check" aria-hidden="true"></i> 
                                    {
                                        submitted ? "Uploading" :
                                        overlay.purpose == "create" ? 'Upload' : 'Save'
                                    }
                                </button>
                                <button className="btn btn-secondary" onClick={this.handleCloseOverlay}>Cancel</button>
                            </section>
                        </form>
                    </div>
            )
        }
        catch(e){
            console.log(e)
            // window.location.reload(false);
        }
        
    }
}

const mapState = state => ({
    user:state.users.current,
    overlay:state.overlay,
    creatives: state.creatives,
})

const actionCreators = {
    closeOverlay            : overlayActions.close,
    submitCreative          : creativesPendingActions.submitCreative,
    updateCreative          : creativeActions.updateCreative,
    uploadFile              : creativeActions.uploadFile,   //Upload Image
    createNewVideoAsset     : creativeActions.createNewVideoAsset,
    addCreative             : creativeActions.addCreative,
    clearFileUploads        : creativeActions.clearFileUploads,
    addAdgroupCreatives     : adgroupCreativeActions.addAdgroupCreatives,
    addCreativesPending     : creativesPendingActions.addCreativesPending,
    getCreativesPending     : creativesPendingActions.getCreativesPending
}


const connectedCreativeForm = connect(mapState, actionCreators)(CreativeForm);
export { connectedCreativeForm as CreativeForm };