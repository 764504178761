import config from 'config';
import { authHeader } from '../_helpers';

export const logSupernovaService = {
    getLogSupernovaBySupernovaAccountId,
    getDailyLogBySupernovaAccountIdAndCreativeIds
};

function getLogSupernovaBySupernovaAccountId(supernovaAccountId, creativeId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/log_supernova/daily/${supernovaAccountId}/${creativeId}`, requestOptions).then(handleResponse);
}

function getDailyLogBySupernovaAccountIdAndCreativeIds(supernovaAccountId, filteredCreatives) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },

        // Commented out last Oct 03, 2023 because we want to pull all creatives already instead of by selected entity
        // We will just filter it out on local browser
        // body: JSON.stringify({"creativeList":filteredCreatives})

        body: JSON.stringify({"creativeList":[]})

    };

    return fetch(`${config.apiURL}/log_supernova/daily/${supernovaAccountId}/null`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
