import React, { useState, useEffect, Component, useDebugValue } from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    userActions,
    adgroupActions,
    adgroupCreativeActions,
    creativeActions,
} from '../_actions';

//React Select
import { listCreativeType } from '../_constants/data'
import { styleSelectBasic, styleSelectTopControl, styleSelectMultiple } from '../_constants/settings'
import Select from 'react-select'

//Components
import {Footer} from '../_components/Footer';
import {LeftNavigation} from '../_components/LeftNavigation'

//Helpers
import '../_helpers/sort-by.js'


const CreativeLibrary = (props) => {
    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    useEffect(() => {
        props.getCreativesInAccount(user.supernovaAccountId)
        props.getAdgroupCreatives(user.supernovaAccountId)
        props.getAllAdgroups(user.accountId)
    },[]);/*  */


    const [creativeSearch, setCreativeSearch] = useStateWithLabel("", "creativeSearch");
    const [creativePaginationOffset, setCreativePaginationOffset] = useStateWithLabel(0, "setCreativePaginationOffset");
    const [filterByType, setFilterByType] = useStateWithLabel("", "filterByType");
    const [filterByAdGroupId, setFilterByAdGroupId] = useStateWithLabel("", "filterByAdGroupId");
    const [filterByCreativeId, setFilterByCreativeId] = useStateWithLabel("", "filterByCreativeId");
    let filteredCreatives = []


    const handleDropdownChange = (dropdown) => {
        const value = Array.isArray(dropdown)
            ? dropdown.map((item) => {
                return item.value
            })
            : dropdown === null ? "" : dropdown.value
        return (value)
    }

    const { user, users, creatives, adgroups} = props;

    /** CREATIVES **/
    filteredCreatives = filterByCreativeId.length > 0 
    ?
        creatives.items && creatives.items.filter(creative => 
        (
            creative.title.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0 || 
            creative.id.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0 ||  
            creative.url.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0 || 
            creative.description.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0 || 
            creative.displayUrl.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0
        ) &&
        creative.type && 
        creative.type.toUpperCase().indexOf(filterByType.toUpperCase()) >= 0 && 
        filterByCreativeId.indexOf(creative.id) >= 0
        )
    :
        creatives.items && creatives.items.filter(creative => 
        (
            creative.title.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0 || 
            creative.id.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0 ||  
            creative.url.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0 || 
            creative.description.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0 || 
            creative.displayUrl.toUpperCase().indexOf(creativeSearch.toUpperCase()) >= 0
        ) &&
        creative.type &&
        creative.type.toUpperCase().indexOf(filterByType.toUpperCase()) >= 0
        )


    /** FOR PAGINATION **/
    const creativeRowsPerPage = 50
    let countCreativeRows = creatives.items && filteredCreatives.length
    let creativeCurrentPage = (creativePaginationOffset / creativeRowsPerPage) + 1
    let creativeTotalPage = parseInt(countCreativeRows / creativeRowsPerPage) + 1
    const creativeNextPage = () => {
        setCreativePaginationOffset(creativePaginationOffset + creativeRowsPerPage);
    }
    const creativePrevPage = () => {
        setCreativePaginationOffset(creativePaginationOffset - creativeRowsPerPage);
    }

    {/* GET Unique AdGroupId from props.adgroupCreatives.items */}
    const adGroupIds = adgroups.items && props.adgroupCreatives.items && [...new Set(props.adgroupCreatives.items.map(item => item.adGroupId))].map((item) => {
        return {
            label: adgroups.items.filter(adgroup => adgroup.id == item).map(item => {
                return `(${item.id}) ${item.name}`
            }),
            value: item
        }
    })
    
    return (
        <div className="creative-library">

            <LeftNavigation />
            <div className="canvas">
                <div className={`custom-panel creativelib table`}>
                    <div className="header">
                        Creative Library
                        <div className="controls">

                            {/* FILTER BY CREATIVE TYPE */}
                            <Select
                                onChange={event => setFilterByType(() => handleDropdownChange(event))}
                                className="select-primary"
                                placeholder="Filter by creative type"
                                options={listCreativeType}
                                styles={styleSelectTopControl} />

                            {/* FILTER BY AD GROUP */}
                            <Select
                                onChange={(event) => {
                                    let adGroupId = handleDropdownChange(event)
                                    setFilterByAdGroupId(adGroupId)
                                    setFilterByCreativeId(props.adgroupCreatives.items.filter(item => item.adGroupId == adGroupId).map(item => {return item.creativeId}))
                                }}
                                className="select-primary"
                                placeholder="Filter by Ad Group"
                                options={adGroupIds}
                                isClearable={true}
                                styles={styleSelectTopControl} />

                            {/* FILTER BY ID, TITLE, URL OR DESCRIPTION */}
                            <input type="text" placeholder="Search by ID, Title, URL or Description" className="form-control search" name="creativeSearch" value={creativeSearch} onChange={(event) => {
                                setCreativeSearch(event.target.value)
                                setCreativePaginationOffset(0)
                                }} />
                                {
                                    countCreativeRows > creativeRowsPerPage &&
                                    <>
                                        <button type="button" className={`btn btn-primary btn-xs btn-pagination ${creativeCurrentPage >= creativeTotalPage ? "disabled" : ""}`} onClick={() => creativeNextPage()}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                        <button type="button" className={`btn btn-primary btn-xs btn-pagination ${creativePaginationOffset < creativeRowsPerPage ? "disabled" : ""}`} onClick={() => creativePrevPage()}><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                        
                                        <div className="pagination-info">
                                            Page {creativeCurrentPage} of {creativeTotalPage}
                                        </div>
                                    </>
                                }
                        </div>
                    </div>
                    {
                        creatives.loading ?
                        <div className="loading">
                            Initializing...
                        </div>
                        :
                        <table className="home primary fixed-header has-hover"> 
                            <thead>
                                <tr>
                                    <th className="creative-library-thumb"></th>
                                    <th>Title</th>
                                    <th className="creative-library-id">ID</th>
                                    <th>Description</th>
                                    <th>URL</th>
                                    <th>Display URL</th>
                                    <th>Tags</th>
                                    <th>Type</th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                        creatives.items && filteredCreatives.slice(creativePaginationOffset, creativePaginationOffset + creativeRowsPerPage).map((creative, index) =>
                                        <tr key={creative.id}>
                                            <td className="creative-library-thumb"><img src={creative.imageUrl.replace(/\?w=.*.&h/, '?w=19&h=19&o')} width="19" height="19" alt=""/></td>
                                            <td>{creative.title}</td>
                                            <td className="creative-library-id">{creative.id}</td>
                                            <td>{creative.description}</td>
                                            <td><a href={creative.url} target="_blank">{creative.url}</a></td>
                                            <td><a href={`https://${creative.displayUrl}`} target="_blank">{creative.displayUrl}</a></td>
                                            <td className='has-badge'>
                                                {
                                                    creative.tags.map((tag, index) => 
                                                        <span key={index} className="badge">{tag}</span>
                                                    )
                                                }
                                            </td>
                                            <td>{creative.type}</td>
                                            {/* <td>
                                                <button type="button" className="btn btn-primary btn-xs btn-actions btn-pagination mr-3"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                <button type="button" className="btn btn-primary btn-xs btn-actions btn-pagination mr-3"><i className="fa fa-files-o" aria-hidden="true"></i></button>
                                            </td> */}
                                        </tr>
                                )}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        
            <Footer />
        </div>
    )
}

const mapState = state => ({
    user:state.users.current,
    users:state.users,
    creatives:state.creatives,
    adgroups:state.adgroups,
    adgroupCreatives:state.adgroupCreatives,
})

const actionCreators = {
    getAllAdgroups: adgroupActions.getAllAdgroups,
    getCreativesInAccount: creativeActions.getCreativesInAccount,
    getAdgroupCreatives: adgroupCreativeActions.getAdgroupCreatives,
}

const connectedCreativeLibrary = connect(mapState, actionCreators)(CreativeLibrary);

export { connectedCreativeLibrary as CreativeLibrary };