import { statisticConstants } from '../_constants';

export function statistics(state = {}, action) {
  switch (action.type) {
    //=====================================
    // GET TOTALS BY ADGROUP
    //=====================================
    case statisticConstants.GET_TOTALS_BY_ADGROUP_REQUEST:
      return {
        ...state,
        isTotalsDone:false,
      };
    case statisticConstants.GET_TOTALS_BY_ADGROUP_SUCCESS:
      return {
        ...state,
        isTotalsDone:true,
        totals: action.statistics[0]    // This will always return 1 row 
      }
    case statisticConstants.GET_TOTALS_BY_ADGROUP_FAILURE:
      return { 
        ...state,
        isTotalsDone:true,
        error: action.error
      };
    //=====================================
    // GET TOTALS BY CAMPAIGN
    //=====================================
    case statisticConstants.GET_TOTALS_BY_CAMPAIGN_REQUEST:
      return {
        ...state,
        isTotalsDone:false,
      };
    case statisticConstants.GET_TOTALS_BY_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isTotalsDone:true,
        totals: action.statistics[0]    // This will always return 1 row 
      }
    case statisticConstants.GET_TOTALS_BY_CAMPAIGN_FAILURE:
      return { 
        ...state,
        isTotalsDone:true,
        error: action.error
      };
    //=====================================
    // GET TOTAL SPEND
    //=====================================
    case statisticConstants.GET_TOTAL_SPEND_REQUEST:
      return {
        ...state,
        isTotalSpendDone:false,
      };
    case statisticConstants.GET_TOTAL_SPEND_SUCCESS:
      return {
        ...state,
        isTotalSpendDone:true,
        totalSpend: action.statistics[0]    // This will always return 1 row 
      }
    case statisticConstants.GET_TOTAL_SPEND_FAILURE:
      return { 
        ...state,
        isTotalSpendDone:true,
        error: action.error
      };
    //=====================================
    // GET INSIGHTS1
    //=====================================
    case statisticConstants.GET_TOP_DMA_BY_CTR_REQUEST:
      return {
        ...state,
        isTopDMAByCtrDone:false,
      };
    case statisticConstants.GET_TOP_DMA_BY_CTR_SUCCESS:
      return {
        ...state,
        isTopDMAByCtrDone:true,
        topDMAByCtr: action.statistics
      }
    case statisticConstants.GET_TOP_DMA_BY_CTR_FAILURE:
      return { 
        ...state,
        isTopDMAByCtrDone:true,
        error: action.error
      };
    //=====================================
    // GET COUNTRIES
    //=====================================
    case statisticConstants.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isCountriesDone:false,
      };
    case statisticConstants.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isCountriesDone:true,
        countries: action.statistics
      }
    case statisticConstants.GET_COUNTRIES_FAILURE:
      return { 
        ...state,
        isCountriesDone:true,
        error: action.error
      };
    //=====================================
    // GET COUNTRIES
    //=====================================
    case statisticConstants.GET_ZONE_REQUEST:
      return {
        ...state,
        isZoneDone:false,
      };
    case statisticConstants.GET_ZONE_SUCCESS:
      return {
        ...state,
        isZoneDone:true,
        zone: action.statistics
      }
    case statisticConstants.GET_ZONE_FAILURE:
      return { 
        ...state,
        isZoneDone:true,
        error: action.error
      };
    //=====================================
    // GET DEVICES
    //=====================================
    case statisticConstants.GET_DEVICE_REQUEST:
      return {
        ...state,
        isDevicesDone:false,
      };
    case statisticConstants.GET_DEVICE_SUCCESS:
      return {
        ...state,
        isDevicesDone:true,
        devices: action.statistics
      }
    case statisticConstants.GET_DEVICE_FAILURE:
      return { 
        ...state,
        isDevicesDone:true,
        error: action.error
      };
    //=====================================
    // GET OS
    //=====================================
    case statisticConstants.GET_OS_REQUEST:
      return {
        ...state,
        isOSDone:false,
      };
    case statisticConstants.GET_OS_SUCCESS:
      return {
        ...state,
        isOSDone:true,
        os: action.statistics
      }
    case statisticConstants.GET_OS_FAILURE:
      return { 
        ...state,
        isOSDone:true,
        error: action.error
      };
    //=====================================
    // GET STATE
    //=====================================
    case statisticConstants.GET_STATE_REQUEST:
      return {
        ...state,
        isStateDone:false,
      };
    case statisticConstants.GET_STATE_SUCCESS:
      return {
        ...state,
        isStateDone:true,
        state: action.statistics
      }
    case statisticConstants.GET_STATE_FAILURE:
      return { 
        ...state,
        isStateDone:true,
        error: action.error
      };
    //=====================================
    // GET DMA
    //=====================================
    case statisticConstants.GET_DMA_REQUEST:
      return {
        ...state,
        isDMADone:false,
      };
    case statisticConstants.GET_DMA_SUCCESS:
      return {
        ...state,
        isDMADone:true,
        dma: action.statistics
      }
    case statisticConstants.GET_DMA_FAILURE:
      return { 
        ...state,
        isDMADone:true,
        error: action.error
      };
    //=====================================
    // GET ENVIRONMENT
    //=====================================
    case statisticConstants.GET_ENVIRONMENT_REQUEST:
      return {
        ...state,
        isEnvironmentDone:false,
      };
    case statisticConstants.GET_ENVIRONMENT_SUCCESS:
      return {
        ...state,
        isEnvironmentDone:true,
        environments: action.statistics
      }
    case statisticConstants.GET_ENVIRONMENT_FAILURE:
      return { 
        ...state,
        isEnvironmentDone:true,
        error: action.error
      };
    //=====================================
    // GET MEDIASOURCE
    //=====================================
    case statisticConstants.GET_MEDIASOURCE_REQUEST:
      return {
        ...state,
        isMediaSourcesDone:false,
      };
    case statisticConstants.GET_MEDIASOURCE_SUCCESS:
      return {
        ...state,
        isMediaSourcesDone:true,
        media_sources: action.statistics
      }
    case statisticConstants.GET_MEDIASOURCE_FAILURE:
      return { 
        ...state,
        isMediaSourcesDone:true,
        error: action.error
      };
    //=====================================
    // GET TOP COUNTIES BY CTR
    //=====================================
    case statisticConstants.GET_TOP_COUNTRIES_BY_CTR_REQUEST:
      return {
        ...state,
        isTopCountriesByCtrDone:false,
      };
    case statisticConstants.GET_TOP_COUNTRIES_BY_CTR_SUCCESS:
      return {
        ...state,
        isTopCountriesByCtrDone:true,
        topCountriesByCtr: action.statistics
      }
    case statisticConstants.GET_TOP_COUNTRIES_BY_CTR_FAILURE:
      return { 
        ...state,
        isTopCountriesByCtrDone:true,
        error: action.error
      };
    //=====================================
    // GET TOP STATES BY CTR
    //=====================================
    case statisticConstants.GET_TOP_STATES_BY_CTR_REQUEST:
      return {
        ...state,
        isTopStatesByCtrDone:false,
      };
    case statisticConstants.GET_TOP_STATES_BY_CTR_SUCCESS:
      return {
        ...state,
        isTopStatesByCtrDone:true,
        topStatesByCtr: action.statistics
      }
    case statisticConstants.GET_TOP_STATES_BY_CTR_FAILURE:
      return { 
        ...state,
        isTopStatesByCtrDone:true,
        error: action.error
      };
    //=====================================
    // GET DAILY
    //=====================================
    case statisticConstants.GET_DAILY_REQUEST:
      return {
        ...state,
        isDailyDone:false,
      };
    case statisticConstants.GET_DAILY_SUCCESS:
      return {
        ...state,
        isDailyDone:true,
        daily: action.statistics
      }
    case statisticConstants.GET_DAILY_FAILURE:
      return { 
        ...state,
        isDailyDone:true,
        error: action.error
      };
    //=====================================
    // GET LIFETIME
    //=====================================
    case statisticConstants.GET_LIFETIME_REQUEST:
      return {
        ...state,
        isLifetimeDone:false,
      };
    case statisticConstants.GET_LIFETIME_SUCCESS:
      //Add the result but make sure it does not double
      let newLifetime = state?.lifetime?.filter(item => item?._id != action.statistics[0]._id) || []
      newLifetime.push(action.statistics[0])
      return {
        ...state,
        isLifetimeDone:true,
        lifetime: newLifetime
      }
    case statisticConstants.GET_LIFETIME_FAILURE:
      return { 
        ...state,
        isLifetimeDone:true,
        error: action.error
      };
    //=====================================
    // GET MONTHLY
    //=====================================
    case statisticConstants.GET_MONTHLY_REQUEST:
      return {
        ...state,
        isMonthlyDone:false,
      };
    case statisticConstants.GET_MONTHLY_SUCCESS:
      return {
        ...state,
        isMonthlyDone:true,
        monthly: action.statistics
      }
    case statisticConstants.GET_MONTHLY_FAILURE:
      return { 
        ...state,
        isMonthlyDone:true,
        error: action.error
      };
    //=====================================
    // GET PER CAMPAIGN
    //=====================================
    case statisticConstants.GET_CAMPAIGN_REQUEST:
      return {
        ...state,
        isCampaignDone:false,
      };
    case statisticConstants.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isCampaignDone:true,
        campaign: action.statistics
      }
    case statisticConstants.GET_CAMPAIGN_FAILURE:
      return { 
        ...state,
        isCampaignDone:true,
        error: action.error
      };
    //=====================================
    // GET PER ADGROUP
    //=====================================
    case statisticConstants.GET_ADGROUP_REQUEST:
      return {
        ...state,
        isAdgroupDone:false,
      };
    case statisticConstants.GET_ADGROUP_SUCCESS:
      return {
        ...state,
        isAdgroupDone:true,
        adgroup: action.statistics
      }
    case statisticConstants.GET_ADGROUP_FAILURE:
      return { 
        ...state,
        isAdgroupDone:true,
        error: action.error
      };
    //=====================================
    // GET PER CREATIVE
    //=====================================
    case statisticConstants.GET_CREATIVE_REQUEST:
      return {
        ...state,
        isCreativeDone:false,
      };
    case statisticConstants.GET_CREATIVE_SUCCESS:
      return {
        ...state,
        isCreativeDone:true,
        creative: action.statistics
      }
    case statisticConstants.GET_CREATIVE_FAILURE:
      return { 
        ...state,
        isCreativeDone:true,
        error: action.error
      };
    //=====================================
    // GET PER ADS
    //=====================================
    case statisticConstants.GET_ADS_REQUEST:
      return {
        ...state,
        isAdsDone:false,
      };
    case statisticConstants.GET_ADS_SUCCESS:
      return {
        ...state,
        isAdsDone:true,
        ads: action.statistics
      }
    case statisticConstants.GET_ADS_FAILURE:
      return { 
        ...state,
        isAdsDone:true,
        error: action.error
      };
      //=====================================
      // DEFAULT
      //=====================================
    default:
      return state
  }
}