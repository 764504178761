import React, { useState, useEffect, Component, useDebugValue } from 'react';
import {BrowserRouter as Router, Link } from "react-router-dom";

//Redux
import { connect } from 'react-redux';
import { 
    conversionActions,
} from '../_actions';

//React Select
import { listCreativeType } from '../_constants/data'
import { styleSelectBasic, styleSelectMultiple } from '../_constants/settings'
import Select from 'react-select'

//Components
import {TopNavigation} from '../_components/TopNavigation'
import {Submenu} from '../_components/Submenu'
import {Footer} from '../_components/Footer';
import {LeftNavigation} from '../_components/LeftNavigation'

//Moment
import Moment from 'react-moment';
import moment from 'moment';

//Tooltip
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//Helpers
import '../_helpers/sort-by.js'

const ConversionPage = (props) => {

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    const [isCopyConversionWindowVisible, setIsCopyConversionWindowVisible] = useStateWithLabel(false, "isCopyConversionWindowVisible");
    const [isEditConversionWindowVisible, setIsEditConversionWindowVisible] = useStateWithLabel(false, "isEditConversionWindowVisible");
    const [conversionId, setConversionId] = useStateWithLabel("", "conversionId");
    const [conversionName, setConversionName] = useStateWithLabel("", "conversionName");
    const [conversionNotes, setConversionNotes] = useStateWithLabel("", "conversionNotes");

    const { user, users, conversions } = props;
    const accountId = user.accountId


    useEffect(() => {
        console.log(user)
        props.getConversionByAccountId(accountId)
    },[]);

    const showConversionForm = (data, purpose) => {
        setIsEditConversionWindowVisible(true); 
        setIsEditingForm(purpose == "edit" ? true : false); 
        setConversionId(data == null ? "" : data.id)
        setConversionName(data == null ? "" : data.name)
        setConversionNotes(data == null ? "" : data.notes)
    }

    const showCopyConversionForm = (data) => {
        setIsCopyConversionWindowVisible(true);
        setConversionId(data.id)
    }

    const [isEditingForm, setIsEditingForm] = useStateWithLabel(false, "setIsEditingForm");
    


    
    return (
        <div className="conversion">

{
            /* COPY TAG FORM */
            isCopyConversionWindowVisible && 
            <div className="backdrop">
                    <div className="window">
                        <div className="title">
                            {isEditingForm ? "Edit" : "Copy"} Conversion
                        </div>
                        <div className="body">
                            <section>
                                {/* <b>Option 1:</b> Using an iframe tag */}
                                <div className="form-group">
                                        <div className="form-control readonly code-snippet">
                                            <pre>{`<!-- Supernova Pixel -->
<script type="text/javascript">
!function(_window, _document) {
var SUPERNOVA_TAG_ID='${conversionId}';
if (_window.Api) {var toArray = function(object) { return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];};_window.Api.marketerId = toArray(_window.Api.marketerId).concat(toArray(SUPERNOVA_TAG_ID));return;}
var api = _window.Api = function() {api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);};api.version = '1.0';api.loaded = true;api.marketerId = SUPERNOVA_TAG_ID;api.queue = [];var tag = _document.createElement('script');tag.async = true;tag.src = 'https://c.local.supernova.inc/zcpt.js';tag.type = 'text/javascript';var script = _document.getElementsByTagName('script')[0];script.parentNode.insertBefore(tag, script);
}(window, document);
Api('track', 'PAGE_VIEW');
</script>
<noscript><iframe src="https://c.local.supernova.inc/ns.html?aid=4762" height="1" width="1" border="0" alt="" /></noscript>`}</pre>
                                        </div>
                                </div>
                            </section>
                        </div>
                        <div className="footer">
                            <button className="btn btn-xs btn-secondary" onClick={() => setIsCopyConversionWindowVisible(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            }


{
                /* PIXEL FORM */
                isEditConversionWindowVisible && 
                <div className="backdrop">
                    <div className="window">
                        <div className="title">
                            {isEditingForm ? "Edit" : "Create"} Conversion
                        </div>
                        <div className="body">
                            <section>
                                <div className="form-group">
                                    <label>Name</label>
                                    <div className="content">
                                            <input type="text" className="form-control" disabled={isEditingForm} value = {conversionName} name="name" onChange={event => setConversionName(event.target.value)}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Notes</label>
                                    <div className="content">
                                        <textarea maxLength="250" type="text" className="form-control" value={conversionNotes} onChange={event => setConversionNotes(event.target.value)}>{conversionNotes}</textarea>
                                    </div>
                                </div>
                                <div className="description-block">
                                    <div className="title">Where do I place my conversion pixel on my site?</div>
                                    <div className="content">Conversions need to be properly placed in order to measure goals effectively. We recommend placing your pixel on the confirmation page which is shown to a person after the desired action is performed.</div>
                                </div>
                            </section>
                        </div>
                        <div className="footer">
                            {
                                isEditingForm 
                                ? <button className="btn btn-xs btn-primary" type="button" onClick={() => updateConversion(pixelId)}><i className="fa fa-check" aria-hidden="true"></i> Save</button>
                                : <button className="btn btn-xs btn-primary" type="button" onClick={() => createConversion()}><i className="fa fa-check" aria-hidden="true"></i> Create</button>
                                    
                            }
                            <button className="btn btn-xs btn-secondary" onClick={() => setIsEditConversionWindowVisible(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            }


            <LeftNavigation />
            <div className="canvas">
                {/* PIXELS */}
                <div className={`custom-panel creativelib table`}>
                    <div className="header">
                        Conversions
                        <div className="subtitle">Supernova's pixels allow you to report conversions and get insights about how people use your website.</div>
                        <div className="controls">
                            <button type="button" className={`btn btn-primary btn-xs btn-pagination`} onClick={() => showConversionForm(null, "create")}>
                                <i className="fa fa-plus-circle" aria-hidden="true"></i> Add Conversion
                            </button>
                        </div>
                    </div>
                    <table className="home primary fixed-header has-hover pixels"> 
                            <thead>
                                <tr>
                                    <th className="text-left">Name </th>
                                    <th className="text-left">Last Triggered </th>
                                    <th className="text-left">Notes</th>
                                    <th className="text-left">Recent Traffic</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    conversions?.items && conversions?.items.map((conversion, index) =>
                                    <tr key={index}>
                                        <td className="text-left">{conversion.name}</td>
                                        <td className="text-left">{conversion.lastTriggered == undefined ? "N/A" : moment(conversion.lastTriggered).format('MMM D, YYYY hh:mm:ss a')}</td>
                                        <td className="text-left">{conversion.notes}</td>
                                        <td className="text-left">{conversion.recentTraffic}</td>
                                        <td>
                                            <OverlayTrigger placement="top" overlay={<Tooltip><b>Show</b></Tooltip>}>
                                                <button type="button" className="btn btn-primary btn-xs btn-actions btn-pagination mr-3" onClick={() => showCopyConversionForm(conversion)}><i className="fa fa-clone" aria-hidden="true"></i></button>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip><b>Edit</b></Tooltip>}>
                                                <button type="button" className="btn btn-primary btn-xs btn-actions btn-pagination mr-3" onClick={() => {showConversionForm(conversion, "edit")}}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                </div>
                
            </div>

            <Footer />
        </div>
    )
}

const mapState = state => ({
    user        : state.users.current,
    users       : state.users,
    conversions : state.conversions
})

const actionCreators = {
    getConversionByAccountId    : conversionActions.getConversionByAccountId
}

const connectedConversionPage = connect(mapState, actionCreators)(ConversionPage);

export { connectedConversionPage as ConversionPage };