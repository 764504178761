import config from 'config';
import { authHeader } from '../_helpers';

export const campaignService = {
    createCampaign,
    updateCampaign,
    getCampaigns,
    updateGoal,
    addGoal,
    deleteGoal,
    addBudget,
    updateBudget,
    getCampaignDetail,
    addPushCampaign,
    editPushCampaign,
    getPushCampaign,
    getInactiveCampaigns,
    disableCampaign,
    enableCampaign,
};

function createCampaign(data) {
    data.autopilot = Boolean(data.autopilot)
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/campaigns`, requestOptions).then(handleResponse);
}

function updateCampaign(data) {
    data.autopilot = Boolean(data.autopilot)
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/campaigns/${data.id}`, requestOptions).then(handleResponse);
}

function updateGoal(data, campaignID, goalID) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/campaigns/${campaignID}/goals/${goalID}`, requestOptions).then(handleResponse);
}

function addGoal(data, campaignID) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/campaigns/${campaignID}/goals/`, requestOptions).then(handleResponse);
}

function deleteGoal(campaignID, goalID) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json'
        },
    };

    return fetch(`${config.apiURL}/campaigns/${campaignID}/goals/${goalID}`, requestOptions).then(handleResponse);
}

function updateBudget(data, campaignID, budgetID) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/campaigns/${campaignID}/budgets/${budgetID}`, requestOptions).then(handleResponse);
}

function addBudget(data, campaignID) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/campaigns/${campaignID}/budgets/`, requestOptions).then(handleResponse);
}

function getCampaigns(supernovaAccountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/campaigns/?said=${supernovaAccountId}`, requestOptions).then(handleResponse);
}

function getInactiveCampaigns(supernovaAccountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/campaigns_inactive/?said=${supernovaAccountId}`, requestOptions).then(handleResponse);
}

function disableCampaign(data) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            campaignType: data.campaignType
        }),      
        headers: { 
            'Content-Type': 'application/json'
        },
    };

    return fetch(`${config.apiURL}/campaigns_inactive/${data.accountId}/${data.campaignId}`, requestOptions).then(handleResponse);
}

function enableCampaign(data) {
    const requestOptions = {
        method: 'DELETE',
        body: JSON.stringify({
            campaignType: data.campaignType
        }),
        headers: { 
            'Content-Type': 'application/json'
        },
    };

    return fetch(`${config.apiURL}/campaigns_inactive/${data.accountId}/${data.campaignId}`, requestOptions).then(handleResponse);
}

function getCampaignDetail(campaignId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/campaigns/${campaignId}`, requestOptions).then(handleResponse);
}

function editPushCampaign(data, campaignId) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/campaigns/push/${campaignId}`, requestOptions).then(handleResponse);
}

function addPushCampaign(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiURL}/campaigns/push/`, requestOptions).then(handleResponse);
}

function getPushCampaign(accountId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiURL}/push_campaigns/?aid=${accountId}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}