import React, { useEffect, useRef, useState } from 'react';
import { Ion, Viewer, CustomDataSource, Color, Cartesian3, GeoJsonDataSource, ScreenSpaceEventHandler, ScreenSpaceEventType, ColorGeometryInstanceAttribute, DataSourceCollection, DataSourceDisplay } from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';

const GlobePrototype = (props) => {
    const viewerRef = useRef(null);
    const customDataSourceRef = useRef(new CustomDataSource('spikes'));
    const [highlightedEntity, setHighlightedEntity] = useState(null);
    const [pickedColor, setPickedColor] = useState(null);

    useEffect(() => {

        Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxNzUyNjVjNi0wMjhkLTQ1ZTgtOTc0MS1hOTQyNWU2MzY4ZGEiLCJpZCI6MjQ1Njc2LCJpYXQiOjE3Mjc5NDgwODJ9.6rS_nATP_TL-LJzqwdx7wTsol5AZyAwAMNkiZL9lP1A';

        // Initialize the Cesium viewer
        viewerRef.current = new Viewer('cesiumContainer', {
            animation: false,
            timeline: false
        });

        viewerRef.current.camera.flyTo({
            destination: Cartesian3.fromDegrees(-98.0, 39.0, 10000000),
            orientation: {
                heading: 0.0,
                pitch: -Math.PI / 2.0,
                roll: 0.0
            },
            duration: 2
        });
        
        viewerRef.current.dataSources.add(customDataSourceRef.current);

        const scene = viewerRef.current.scene;
        const dataSourceCollection = new DataSourceCollection();
        const geoJsonDataSource = new GeoJsonDataSource();
        const dataSourceDisplay = new DataSourceDisplay({
            scene: scene,
            dataSourceCollection: dataSourceCollection
        });

        // Load the GeoJSON or TopoJSON data
        // geoJsonDataSource.load('https://lastboy.github.io/apps/cesium/data/ne_10m_us_states.topojson', {
        geoJsonDataSource.load('https://beta.supernova.inc/public/uploads/ai-map-boundaries.json', {
            stroke: Color.TRANSPARENT,
            fill: Color.YELLOW,
            strokeWidth: 1
        }).then(ds => {
            dataSourceCollection.add(ds);

            const entities = ds.entities.values;
            const colorHash = {};

            entities.forEach(entity => {
                const name = entity.name;
                let color = colorHash[name];
                if (!color) {
                    color = Color.fromRandom({ alpha: 1.0 });
                    colorHash[name] = color;
                }
                entity.polygon.material = color;
                entity.polygon.outline = false;
                entity.polygon.extrudedHeight = entity.properties.Population / 50.0;
            });

            viewerRef.current.clock.onTick.addEventListener(clock => {
                dataSourceDisplay.update(clock.currentTime);
            });

        }).catch(error => {
            console.error("Error: ", error);
        });

        // Mouse event handling
        const mouseHandler = new ScreenSpaceEventHandler(scene.canvas);

        mouseHandler.setInputAction(movement => {
            const pickedObject = scene.pick(movement.position);
            if (pickedObject && pickedObject.id instanceof Cesium.Entity) {
                if (pickedObject.id.polygon.extrudedHeight.getValue() !== 1000000) {
                    pickedObject.id.polygon.outline = false;
                    pickedObject.id.polygon.extrudedHeight = 1000000;
                }
            }
        }, ScreenSpaceEventType.LEFT_CLICK);

        mouseHandler.setInputAction(movement => {
            if (highlightedEntity) {
                highlightedEntity.color = pickedColor;
                setHighlightedEntity(null);
            }

            const pickedObject = scene.pick(movement.endPosition);
            if (pickedObject && pickedObject.id instanceof Cesium.Entity) {
                const attributes = pickedObject.primitive.getGeometryInstanceAttributes(pickedObject.id);
                setHighlightedEntity(attributes);
                setPickedColor(attributes.color);
                attributes.color = ColorGeometryInstanceAttribute.toValue(Color.RED);
            }
        }, ScreenSpaceEventType.MOUSE_MOVE);

        return () => {
            if (viewerRef.current) {
                viewerRef.current.destroy();
            }
        };
    }, [highlightedEntity, pickedColor]);

    useEffect(() => {
        if (props.globeData) {
            customDataSourceRef.current.entities.removeAll();
            props.globeData.forEach(item => {
                const height = item[1] * 20;
                const position = Cartesian3.fromDegrees(item[3], item[2], height / 2);
                
                customDataSourceRef.current.entities.add({
                    name: `Zipcode: ${item[0]}`,
                    position: position,
                    cylinder: {
                        length: height,
                        topRadius: 0,
                        bottomRadius: 5000,
                        material: Color.YELLOW.withAlpha(0.6),
                        outline: true,
                        outlineColor: Color.YELLOW
                    }
                });
            });
        }
    }, [props.globeData]);

    return (
        <div id="globe-wrapper" className="globe-wrapper">
            <div
                id="cesiumContainer"
                style={{ width: "37vw", height: "100%" }}
            />
        </div>
    );
};

export { GlobePrototype };
