export const styleSelectBasic = {
    control: (base, state) => ({
        ...base,
        background: "#fff",
        borderTopLeftRadius: state.isFocused ? "10px" : 10,
        borderTopRightRadius: state.isFocused ? "10px" : 10,
        borderColor: state.isFocused ? "#fff" : "#fff",
        boxShadow: state.isFocused ? null : null,
        padding:"0px",
        fontSize:"18px",
        // height:"23px",
        // height:"33px", //Note: Setting height would break multi select dropdown like the on in intake form screener
        minHeight:"23px",
        "&:hover": {
            borderColor: state.isFocused ? "#fff" : "#fff",
        }
    }),
    valueContainer: (base) => ({
        ...base,
        // display: "contents",
        width:"100%",
        minHeight:"26px",
    }),
    menu: (base) => ({
        ...base,
        borderRadius: "0px",
        border:"1px solid #ccc",
        // width:"430px",
        width:"auto",
        background: "#fff",
        marginTop: 0,
    }),
    input: (base) => ({
        ...base,
        marginTop:"8px",
        width:"calc(100% - 45px)",
        "input":{
            paddingLeft:"0px !important"
        },
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
    }),
    multiValue: (base) => ({
        ...base,
        width:"100%",
        backgroundColor:"transparent !important",
        "borderBottom": "1px dashed #ccc",
        "div:first-of-type":{
            width:"100%",
        },
        "div:nth-of-type(2)":{
            background:"#01575c",
            borderRadius: "100%",
            width: "25px",
            height: "25px",
        },
        "div:nth-of-type(2):hover":{
            background:"#414855",
        },
        "div:nth-of-type(2) svg":{
            color:"#fff !important",
            width:"25px !important",
            height:"25px !important",
        },
    }),
    singleValue: (base) => ({
        ...base,
        paddingLeft:"8px",
        maxWidth:"calc(100% - 70px)",
        fontWeight:"normal",
        top:"none",
        top:"21px",
        transform:"none",
    }),
    indicatorsContainer: (base) => ({
        ...base,
        marginTop:"0px !important",
        position: "absolute !important",
        bottom: "0px !important",
        right: "0px !important",
        "div":{
            padding:"0px 0px 3px 8px !important",
        }
    }),
    indicatorContainer: (base) => ({
        ...base,
        fontColor:"#01575c !important",
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display:"none !important"
    }),
    option: (provided, state) => ({
        ...provided,
        padding: "5px 10px",
        background: "#fff",
        fontSize: "13px",
        display: state.isSelected ? "none" : "block",
        "&:hover": {
            background: "#eef3e7"
        }
    }),
    placeholder: (base) => ({
        ...base,
        top: "21px",
        transform: "none",
    }),
};

export const styleSelectFit = {
    control: (base, state) => ({
        ...base,
        background: "#fff",
        borderRadius: state.isFocused ? "0px" : 0,
        borderColor: state.isFocused ? "#ddd" : "#ddd",
        boxShadow: state.isFocused ? null : null,
        padding:"0px",
        border:"0px",
        fontSize:"13px",
        // height:"23px",
         height:"23px",
        minHeight:"23px",
        width:"fit-content",
        fontSize:"18px",
        "&:hover": {
            borderColor: state.isFocused ? "#ddd" : "#ddd",
        }
    }),
    valueContainer: (base) => ({
        ...base,
        display: "contents",
        width:"100%"
    }),
    menu: (base) => ({
        ...base,
        borderRadius: "0px",
        border:"1px solid #ccc",
        width:"auto",
        background: "#fff",
        marginTop: 0,
    }),
    input: (base) => ({
        ...base,
        marginTop:"-5px",
        width:"calc(100% - 95px)"
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
    }),
    singleValue: (base) => ({
        ...base,
        paddingLeft:"8px",
        maxWidth:"calc(100% - 70px)"
    }),
    indicatorsContainer: (base) => ({
        ...base,
        marginTop:"-8px !important"
    }),
    indicatorSeparator: (base) => ({
        ...base,
        backgroundColor:"transparent !important"
    }),
    option: (provided, state) => ({
        ...provided,
        padding: "5px 10px",
        background: "#fff",
        fontSize: "13px",
        display: state.isSelected ? "none" : "block",
        "&:hover": {
            background: "#eef3e7"
        }
    }),
};

export const styleSelectGoal = {
    control: (base, state) => ({
        ...base,
        background: "#fff",
        borderRadius: state.isFocused ? "0px" : 0,
        borderColor: state.isFocused ? "#ddd" : "#ddd",
        boxShadow: state.isFocused ? null : null,
        padding:"0px",
        border:"0px",
        fontSize:"13px",
        // height:"23px",
         height:"23px",
        minHeight:"23px",
        width:"fit-content",
        backgroundColor:"transparent !important",
        fontSize:"18px",
        "&:hover": {
            borderColor: state.isFocused ? "#ddd" : "#ddd",
        }
    }),
    valueContainer: (base) => ({
        ...base,
        display: "contents",
        width:"100%"
    }),
    menu: (base) => ({
        ...base,
        borderRadius: "0px",
        border:"1px solid #ccc",
        width:"100%",
        background: "#fff",
        marginTop: 0,
    }),
    input: (base) => ({
        ...base,
        marginTop:"-1px",
        backgroundColor:"transparent !important",
        width:"calc(100% - 95px)",
        "input":{
            backgroundColor:"transparent !important",
        },
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        border:"1px solid #ccc"
    }),
    singleValue: (base) => ({
        ...base,
        fontSize:"14px",
        paddingLeft:"8px",
        maxWidth:"calc(100% - 70px)",
        transform:"none",
        top:"5px"
    }),
    indicatorsContainer: (base) => ({
        ...base,
        marginTop:"-8px !important"
    }),
    indicatorSeparator: (base) => ({
        ...base,
        backgroundColor:"transparent !important"
    }),
    option: (provided, state) => ({
        ...provided,
        padding: "5px 10px",
        background: "#fff",
        fontSize: "13px",
        display: state.isSelected ? "none" : "block",
        "&:hover": {
            background: "#eef3e7"
        }
    }),
};


export const styleSelectTopControl = {
    control: (base, state) => ({
        ...base,
        background: "#fff",
        borderTopLeftRadius: state.isFocused ? "10px" : 10,
        borderTopRightRadius: state.isFocused ? "10px" : 10,
        borderColor: state.isFocused ? "#fff" : "#fff",
        boxShadow: state.isFocused ? null : null,
        padding:"0px",
        fontSize:"14px",
        fontWeight: "700",
        // height:"23px",
        // height:"33px", //Note: Setting height would break multi select dropdown like the on in intake form screener
        minHeight:"26px",
        "&:hover": {
            borderColor: state.isFocused ? "#fff" : "#fff",
        },
    }),
    valueContainer: (base) => ({
        ...base,
        // display: "contents",
        width:"100%",
        minHeight:"26px",
    }),
    menu: (base) => ({
        ...base,
        borderRadius: "0px",
        border:"1px solid #ccc",
        // width:"430px",
        width:"auto",
        background: "#fff",
        marginTop: 0,
    }),
    input: (base, state) => ({
        ...base,
        marginTop:"0px",
        width:"calc(100% - 45px)",
        height:"20px",
        position:"absolute !important",
        marginTop:"15px",
        "input": {
            paddingLeft:"0px !important"
        }
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
    }),
    multiValue: (base) => ({
        ...base,
        width:"100%",
        backgroundColor:"transparent !important",
        "borderBottom": "1px dashed #ccc",
        "div:first-of-type":{
            width:"100%",
        },
        "div:nth-of-type(2)":{
            background:"#01575c",
            borderRadius: "100%",
            width: "25px",
            height: "25px",
        },
        "div:nth-of-type(2):hover":{
            background:"#414855",
        },
        "div:nth-of-type(2) svg":{
            color:"#fff !important",
            width:"25px !important",
            height:"25px !important",
        },
    }),
    singleValue: (base) => ({
        ...base,
        paddingLeft:"8px",
        maxWidth:"calc(100% - 70px)",
        fontWeight:"normal",
        top:"none",
        top:"21px",
        transform:"none",
    }),
    indicatorsContainer: (base) => ({
        ...base,
        marginTop:"0px !important",
        position: "absolute !important",
        bottom: "0px !important",
        right: "0px !important",
        "div":{
            padding:"1px !important",
        }
    }),
    indicatorContainer: (base) => ({
        ...base,
        fontColor:"#01575c !important",
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display:"none !important"
    }),
    placeholder: (base) => ({
        ...base,
        position:"absolute !important",
        top: "7px",
        transform: "none",
    }),
    option: (provided, state) => ({
        ...provided,
        padding: "5px 10px",
        background: "#fff",
        fontSize: "13px",
        display: state.isSelected ? "none" : "block",
        "&:hover": {
            background: "#eef3e7"
        }
    }),
};