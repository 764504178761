import { logSupernovaConstants } from '../_constants';
import { logSupernovaService } from '../_services';
import { alertActions, progressActions } from './';
import { history } from '../_helpers';

export const logSupernovaActions = {
    getLogSupernovaBySupernovaAccountId,
    getDailyLogBySupernovaAccountIdAndCreativeIds
};



function getLogSupernovaBySupernovaAccountId(logSupernovaId, creativeId) {
    console.log("getLogSupernovaBySupernovaAccountId()")
    return dispatch => {
        dispatch(request());

        logSupernovaService.getLogSupernovaBySupernovaAccountId(logSupernovaId, creativeId)
            .then(
                logSupernova => dispatch(success(logSupernova)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: logSupernovaConstants.GET_REQUEST } }
    function success(logSupernova) { return { type: logSupernovaConstants.GET_SUCCESS, logSupernova } }
    function failure(error) { return { type: logSupernovaConstants.GET_FAILURE, error } }
}

function getDailyLogBySupernovaAccountIdAndCreativeIds(logSupernovaId, filteredCreatives) {
    return dispatch => {
        dispatch(request());

        logSupernovaService.getDailyLogBySupernovaAccountIdAndCreativeIds(logSupernovaId, filteredCreatives)
            .then(
                logSupernova => dispatch(success(logSupernova)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: logSupernovaConstants.GET_REQUEST } }
    function success(logSupernova) { return { type: logSupernovaConstants.GET_SUCCESS, logSupernova } }
    function failure(error) { return { type: logSupernovaConstants.GET_FAILURE, error } }
}

