import React, { useState, useEffect, Component, useDebugValue } from 'react'

//Redux
import { connect } from 'react-redux';
import {
    statisticActions
} from '../../_actions';

//Calendar Charts (Heatmap)

import HeatMapDate from "react-d3-heatmap";

//Moment
import Moment from 'react-moment';
import moment from 'moment';

//Helpers
import '../../_helpers/sort-by.js'

//Recharts
import { BarChart, Bar, LineChart, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LifetimeValue = (props) => {

    const useStateWithLabel = (initialValue, label) => {
        const [value, setValue] = useState(initialValue);
        useDebugValue(`${label}: ${value}`);
        return [value, setValue];
    };

    const { statistics, campaigns, adgroups, creatives, adgroupCreatives, groupBy, entityKey, entityId, publishers, campaignType, topDMAByCtr, topCountriesByCtr, topStatesByCtr } = props

    useEffect(() => {
        if (props.entityKey != null) {
            props.getDailyByEntity(props.supernovaAccountId, props.entityKey, props.entityId, props.startDate, props.endDate, props.campaignType);
        }
        else {
            props.getDaily(props.supernovaAccountId, props.startDate, props.endDate);
        }

    }, [props.startDate, props.endDate, props.entityKey, props.entityId]);

    const [visibleMap, setVisibleMap] = useStateWithLabel("line", "visibleMap");
    const [toolTipX, setToolTipX] = useStateWithLabel(0, "toolTipX");
    const [toolTipY, setToolTipY] = useStateWithLabel(0, "toolTipY");
    const [toolTipOpacity, setToolTipOpacity] = useStateWithLabel(0, "toolTipOpacity");
    const [tooltipContent, setTooltipContent] = useStateWithLabel(0, "tooltipContent");
    const [toolTipImpressions, setToolTipImpressions] = useStateWithLabel(0, "toolTipImpressions");
    const [toolTipDate, setToolTipDate] = useStateWithLabel(0, "toolTipDate");

    /*************************************************
     * Line Chart Properties
     *************************************************/
    const CustomizedDot = (props) => {
        const { cx, cy, stroke, payload, value } = props;
        if (value > 0) {
            return (
                <rect x={cx - 4} y={cy - 4} width={0} height={0} fill={stroke} viewBox="0 0 1024 1024" />
            );
        }
        return ("");
    };

    const graphLabelMaxChar = 25

    /*************************************************
     * Bar chart when user selects either of the ff:
     * environments
     * devices
     * os
     * countries
     * zone
     * state
     * dma
     * media_sources
     *************************************************/
    const getTopStatisticsByKey = (key) => {
        if(statistics[key]){
            return(
                statistics[key] && statistics[key].slice(0,5).map((item, index) => {
                    return (

                        //If key is Top State By CTR:
                        key == "topStatesByCtr" ?
                        {
                            name        : item.stateName.replace(", United States", ""),
                            Impressions : item.impressions,
                            Clicks      : item.clicks,
                        }
                        
                        //If key is Top Country By CTR:
                        : key == "topCountriesByCtr" ?
                        {
                            name        : item.countryName,
                            Impressions : item.impressions,
                            Clicks      : item.clicks,
                        }
                        
                        //Else (default)
                        : {
                            name        : item._id,
                            Impressions : item.impressions,
                            Clicks      : item.clicks,

                        }
                    )
                })
            )
        }
        else{
            return []
        }
    }

    
    /*************************************************
     * Bar chart when user selects Group by Publishers
     *************************************************/
    const getTopPublishers = () => {
        if(statistics.campaign && campaigns){
            let key = entityKey == null ?  "account"
            : entityKey == "campaignId" ? "campaign"
            : entityKey == "adGroupId" ? "adgroup"
            : entityKey == "creativeId" ? "creative"
            : ""
            return(
                publishers[key] && publishers[key].sortByNumeric("impressions").reverse().slice(0,3).map((item, index) => {
                    return (
                        {
                            name: `${item.publisher} (${item.mediaSource})`,
                            Impressions: item.impressions,
                            Clicks: item.clicks,
                        }
                    )
                })
            )
        }
        else{
            return []
        }
    }

    /*************************************************
     * Bar Chart Data when no campaign is selected yet
     *************************************************/
    const getTopCampaigns = () => {
        if(statistics.campaign && campaigns){
            let campaignIds = campaigns.map(campaign => {return(parseInt(campaign.id))})
            let campaign_statistics = statistics.campaign.
            filter(item => campaignIds.indexOf(item._id)  !== -1).sortByNumeric("impressions").reverse().slice(0,5)
            return(
                campaign_statistics.map(campaign => {
                    return (
                        {
                            name: campaigns.filter(item => parseInt(item.id) == campaign._id)
                                .map(campaign => {return(`${campaign.name.substring(0,graphLabelMaxChar)} ${campaign.name.length > graphLabelMaxChar ? "..." : ""}`)}),
                            Impressions: campaign.impressions,
                            Clicks: campaign.clicks,
                        }
                    )
                })
            )
        }
        else{
            return []
        }
    }

    /*************************************************
     * Bar Chart Data when a campaign is selected
     *************************************************/
    const getTopAdgroupsByCampaignId = () => {
        if(statistics.adgroup && adgroups){
            let adgroupIds = adgroups.map(adgroup => {return(parseInt(adgroup.id))})
            let adgroup_statistics = statistics.adgroup.
            filter(item => adgroupIds.indexOf(item._id)  !== -1).sortByNumeric("impressions").reverse().slice(0,5)
            return(
                adgroup_statistics.map(adgroup => {
                    return (
                        {
                            name: adgroups.filter(item => parseInt(item.id) == adgroup._id)
                            .map(adgroup => {return(`${adgroup.name.substring(0,graphLabelMaxChar)} ${adgroup.name.length > graphLabelMaxChar ? "..." : ""}`)}),
                            Impressions: adgroup.impressions,
                            Clicks: adgroup.clicks,
                        }
                    )
                })
            )
        }
        else{
            return []
        }
    }

    /*************************************************
     * Bar Chart Data when an adgroup is selected 
     *************************************************/
    const getTopCreativesByAdgroupId = () => {
        if(statistics.creative && creatives && adgroupCreatives && adgroupCreatives.items){
            let creativeIds = adgroupCreatives.items.filter(adgroupCreative => adgroupCreative.adGroupId == entityId).map(creative => {return(parseInt(creative.creativeId))})
            let creative_statistics = statistics.creative.filter(item => creativeIds.indexOf(item._id)  !== -1).sortByNumeric("impressions").reverse().slice(0,10)
            return(
                creative_statistics.map(creative => {
                    return (
                        {
                            name: creatives.filter(item => parseInt(item.id) == creative._id).map(creative => {return(`CID: ${creative.id}`)}),
                            Impressions: creative.impressions,
                            Clicks: creative.clicks,
                        }
                    )
                })
            )
        }
        else{
            return []
        }
    }

    const chartDataLifetime = 
    (groupBy == "entity" && entityKey == null)          ? getTopCampaigns() :
    (groupBy == "entity" && entityKey == "campaignId")  ? getTopAdgroupsByCampaignId() :
    (groupBy == "entity" && entityKey == "adGroupId")   ? getTopCreativesByAdgroupId() :
    (groupBy == "publisher")                            ? getTopPublishers() :
    (groupBy == "environments")                         ? getTopStatisticsByKey("environments") :
    (groupBy == "devices")                              ? getTopStatisticsByKey("devices") :
    (groupBy == "os")                                   ? getTopStatisticsByKey("os") :
    (groupBy == "countries")                            ? getTopStatisticsByKey("countries") :
    (groupBy == "zone")                                 ? getTopStatisticsByKey("zone") :
    (groupBy == "state")                                ? getTopStatisticsByKey("state") :
    (groupBy == "dma")                                  ? getTopStatisticsByKey("dma") :
    (groupBy == "media_sources")                        ? getTopStatisticsByKey("media_sources") :
    (groupBy == "TOP5_DMA_BY_CTR")                      ? getTopStatisticsByKey("topDMAByCtr") :
    (groupBy == "TOP5_COUNTRIES_BY_CTR")                ? getTopStatisticsByKey("topCountriesByCtr") :
    (groupBy == "TOP5_STATE_BY_CTR")                    ? getTopStatisticsByKey("topStatesByCtr") : 
    (groupBy == "TOP10_DMA_BY_CTR")                      ? getTopStatisticsByKey("topDMAByCtr") :
    (groupBy == "TOP10_COUNTRIES_BY_CTR")                ? getTopStatisticsByKey("topCountriesByCtr") :
    (groupBy == "TOP10_STATE_BY_CTR")                    ? getTopStatisticsByKey("topStatesByCtr") : []



    groupBy == "environments" || 
    groupBy == "" || 
    groupBy == "" || 
    groupBy == "" || 
    groupBy == "" || 
    groupBy == "" || 
    groupBy == "" || 
    groupBy == "" || 
    groupBy == "" || 
    groupBy == ""

    /*************************************************
     * Calendar Chart Properties
     *************************************************/
    const [chartData, setChartData] = useStateWithLabel([], "chartData");
    const totalColorScale = 10
    useEffect(() => {
        if (statistics.daily !== undefined) {
            const arr = statistics.daily.map(item => { return item.impressions })
            const sorted = arr.slice().sort(function (a, b) { return b - a })
            const ranks = arr.map(function (v) { return sorted.indexOf(v) + 1 });
            setChartData(statistics.daily.map((item, index) => {
                return {
                    date: item._id,
                    impressions: item.impressions,
                    count: Math.round((ranks[index] / ranks.length) * totalColorScale)
                }
            }))
        }
    }, [statistics.daily]);

    const colors = [];
    colors.push({ count: 1, color: "#2CBE68" });
    colors.push({ count: 2, color: "#3CC466" });
    colors.push({ count: 3, color: "#4BCA66" });
    colors.push({ count: 4, color: "#5BD067" });
    colors.push({ count: 5, color: "#6BD66B" });
    colors.push({ count: 6, color: "#9CE18B" });
    colors.push({ count: 7, color: "#B2E69C" });
    colors.push({ count: 8, color: "#C5EBAD" });
    colors.push({ count: 9, color: "#D7EFBE" });
    colors.push({ count: 10, color: "#E5F4CF" });


    const onMouseLeave = (d, i) => {
        setToolTipOpacity(0);
    }
    const onMouseEnter = (d, i) => {
        setToolTipOpacity(1);
        setToolTipX(event.clientX - 62);
        setToolTipY(event.clientY - 180);
        let data = chartData.filter(item => item.date == moment(d.date).format("YYYY-MM-DD")).map(item => { return item.impressions })
        setToolTipImpressions(data.length > 0 ? data[0].toLocaleString() : 0)
        setToolTipDate(moment(d.date).format("dddd, MMMM D YYYY"));
    }

    return (
        <>
            <div className={`custom-panel half lifetime chart`}>
                <div className="header">
                    {
                        // visibleMap != "Calendar"
                        // ? 
                        // <button type="button" className="btn btn-primary btn-xs btn-chart-toggle" onClick={() => setVisibleMap("Calendar")}>
                        //     <i className="fa fa-calendar"></i> Show Heatmap Chart
                        // </button>
                        // :
                        // <button type="button" className="btn btn-primary btn-xs btn-chart-toggle" onClick={() => setVisibleMap("line")}>
                        //     <i className="fa fa-line-chart"></i> Show Line Graph
                        // </button>
                    }
                </div>
                <div className="custom-d3-tooltip" style={{ top: "0px", opacity: toolTipOpacity, top: toolTipY, left: toolTipX }}>
                    <div>
                        <label>Date: </label>
                        {toolTipDate}
                    </div>
                    <div>
                        <label>Impressions: </label>
                        {toolTipImpressions}
                    </div>
                </div>
                {
                    statistics.isDailyDone && chartData.length == 0 &&
                    <div className="badge no-result">
                        No Data
                    </div>
                }
                <div className={`chart-wrapper ${visibleMap}`}>
                    {
                        visibleMap != "Calendar" && 
                        (
                            groupBy == "entity" ||
                            groupBy == "publisher" || 
                            groupBy == "environments" || 
                            groupBy == "devices" || 
                            groupBy == "os" || 
                            groupBy == "countries" || 
                            groupBy == "zone" || 
                            groupBy == "state" || 
                            groupBy == "dma" || 
                            groupBy == "media_sources" || 
                            groupBy == "TOP5_DMA_BY_CTR" || 
                            groupBy == "TOP5_COUNTRIES_BY_CTR" || 
                            groupBy == "TOP5_STATE_BY_CTR" ||
                            groupBy == "TOP10_DMA_BY_CTR" || 
                            groupBy == "TOP10_COUNTRIES_BY_CTR" || 
                            groupBy == "TOP10_STATE_BY_CTR"

                        ) ?
                            <ResponsiveContainer>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={chartDataLifetime}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis tick={{ fontSize: 12 }} dataKey="name" />
                                    <YAxis stroke="#1f5b5e" yAxisId="left" orientation="left" tick={{ fontSize: 12 }} tickFormatter={tick => {return tick.toLocaleString();}}/>
                                    <YAxis stroke="#a3a3a3" yAxisId="right" orientation="right"  tick={{ fontSize: 12 }} tickFormatter={tick => {return tick.toLocaleString();}}/>
                                    <Tooltip formatter={(value) => value.toLocaleString()} />
                                    <CartesianGrid stroke="#ccc" />
                                        <Bar yAxisId="left" dataKey="Impressions" stackId="a" fill="#1f5b5e" />
                                        <Bar yAxisId="right" dataKey="Clicks" stackId="a" fill="#a3a3a3" />
                                </BarChart>
                            </ResponsiveContainer>

                        : visibleMap != "Calendar" && groupBy == "daily" ?
                            <ResponsiveContainer>
                                <LineChart width={"auto"} height={"auto"} data={statistics.daily}>
                                    <XAxis tick={{ fontSize: 12 }} dataKey="_id" />
                                    <YAxis stroke="#1f5b5e" yAxisId="left" orientation="left" tick={{ fontSize: 12 }} tickFormatter={tick => {return tick.toLocaleString();}}/>
                                    <YAxis stroke="#a3a3a3" yAxisId="right" orientation="right" tick={{ fontSize: 12 }} tickFormatter={tick => {return tick.toLocaleString();}}/>
                                    <Tooltip formatter={(value) => value.toLocaleString()} />
                                    <CartesianGrid stroke="#ccc" />
                                    <Line yAxisId="left" dot={<CustomizedDot />} isAnimationActive={false}  type="linear" stroke="#1f5b5e" strokeWidth="3" dataKey="impressions" />
                                    <Line yAxisId="right" dot={<CustomizedDot />} isAnimationActive={false}  type="linear" stroke="#a3a3a3" strokeWidth="3" dataKey="clicks" />
                                </LineChart>
                            </ResponsiveContainer>

                        :   <HeatMapDate
                                // startDate={new Date("2021/01/02")} //Need to fix the timezone
                                // endDate={new Date("2022/01/01")} //Need to fix the timezone
                                startDate={new Date(moment(props.startDate).add(1, 'd'))}
                                endDate={
                                    new Date(moment(props.startDate).add(1, 'y'))
                                }
                                data={chartData}
                                colors={colors}
                                defaultColor={"#cdcdcd"}
                                textDefaultColor={"0"}
                                rectWidth={10}
                                marginBottom={4}
                                marginRight={4}
                                displayLegend={false}
                                displayYear={false}
                                backgroundColor={"#fff"}
                                textColor={"#000"}
                                radius={0}
                                classnames={(value) => {
                                    if (!value) {
                                        return 'color-empty';
                                    }
                                    return `color-scale-${value.count}`;
                                }}
                                // onClick={onClick}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                                shouldStartMonday={true}
                                monthSpace={20}
                            // rangeDisplayData={rangeDisplayData}
                            />
                    }
                </div>
            </div>
        </>
    );
}

const mapState = state => ({
    campaigns           : state.campaigns.items,
    adgroups            : state.adgroups.items,
    creatives           : state.creatives.items,
    statistics          : state.statistics,
    publishers          : state.publishers,
    adgroupCreatives    : state.adgroupCreatives,
    topDMAByCtr         : state.topDMAByCtr, 
    topCountriesByCtr   : state.topCountriesByCtr, 
    topStatesByCtr      : state.topStatesByCtr
})

const actionCreators = {
    getDaily: statisticActions.getDaily,
    getDailyByEntity: statisticActions.getDailyByEntity,
}

const connectedLifetimeValue = connect(mapState, actionCreators)(LifetimeValue);
export { connectedLifetimeValue as LifetimeValue };

